<template>
  <div>
    <div class="loader" v-if="!isLoaded">
      <img src="/img/loader.svg" alt="" />
    </div>
    <img
      class="page-main__header-img"
      src="/img/23-1.jpg"
      srcset="/img/23-1@2x.jpg 2x, /img/23-1@3x.jpg 3x"
      alt=""
    />

    <section class="propos" v-if="isLoaded">
      <breadcrumbs :steps="getBreadcrumbs()" context="context" />
      <article class="propos__info">
        <div class="row">
          <div class="col col-50">
            <div class="sticky">
              <h1 class="propos__title">{{ artists.translation.name }}</h1>
              <div class="auction-block">
                <ul class="vente__info-list">
                  <li class="vente__info-item" v-if="futureProducts.length > 0">
                    <a
                      @click="scrollMeTo('futureProducts')"
                      class="link link--underline"
                    >
                      {{ $t('artists.future_auctions') }}
                    </a>
                  </li>
                  <li class="vente__info-item" v-if="pastProducts.length > 0">
                    <a
                      @click="scrollMeTo('pastProducts')"
                      class="link link--underline"
                    >
                      {{ $t('artists.results') }}
                    </a>
                  </li>
                  <li class="vente__info-item" v-if="artists.news.length > 0">
                    <a @click="scrollMeTo('news')" class="link link--underline">
                      {{ $t('artists.news') }}
                    </a>
                  </li>
                </ul>
              </div>
              <picture>
                <source
                  :srcset="getSrcSet(artists.media.link)"
                  media="(max-width: 998px)"
                />
                <img
                  class=""
                  :src="getResizedImageUrl(artists.media.link, 'original')"
                  :alt="artists.media.name"
                />
              </picture>
            </div>
          </div>
          <div class="col col-50">
            <p class="propos__info" v-html="artists.translation.bio"></p>
          </div>
        </div>
      </article>

      <div
        class="ventes__header ventes__long request__estimation"
        v-if="pastProducts.length > 0 || futureProducts.length > 0"
      >
        <router-link :to="`/${$i18n.locale}/estimation/request`">
          <h2 class="vente__title request__estimation-title">
            {{ $t('artists.estimation_request') }}
          </h2>
        </router-link>
      </div>

      <div
        class="specialites__header"
        v-if="futureProducts.length > 0"
        ref="futureProducts"
      >
        <h2 class="specialites__subtitle">
          {{ $t('artists.future_auctions') }}
        </h2>
        <router-link
          :to="`/${$i18n.locale}/products?query=${artists.slug
            .split('-')
            .join('%20')}`"
        >
          <span class="show_more">{{ $t('artists.show_more') }} </span>
        </router-link>
      </div>
      <div class="lots__list">
        <div class="row">
          <product-item
            v-for="product in futureProducts"
            :product="product"
            :key="product.id"
          >
          </product-item>
        </div>
      </div>

      <div
        class="specialites__header"
        v-if="pastProducts.length > 0"
        ref="pastProducts"
      >
        <h2 class="specialites__subtitle">{{ $t('artists.results') }}</h2>
        <router-link
          :to="`/${$i18n.locale}/products?query=${artists.slug
            .split('-')
            .join('%20')}`"
        >
          <span class="show_more">{{ $t('artists.show_more') }} </span>
        </router-link>
      </div>
      <div class="lots__list">
        <div class="row">
          <product-item
            v-for="product in pastProducts"
            :product="product"
            :key="product.id"
          >
          </product-item>
        </div>
      </div>

      <div
        class="specialites__header"
        v-if="artists.news.length > 0"
        ref="news"
      >
        <h2 class="specialites__subtitle">{{ $t('artists.news') }}</h2>
        <router-link :to="{ name: 'app.news.list' }">
          <span class="show_more">{{ $t('artists.show_more') }} </span>
        </router-link>
      </div>
      <div class="specialites__list">
        <div class="row">
          <news-item
            v-for="news in artists.news"
            :key="news.id"
            :news="news"
            class="col col-20"
          >
          </news-item>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Breadcrumbs from '../layout/Breadcrumbs'
import NewsItem from './common/NewsItem'
import ProductItem from './common/ProductItem'

export default {
  components: {
    Breadcrumbs,
    NewsItem,
    ProductItem,
  },
  name: 'artists-details',
  metaInfo() {
    return {
      name: this.artists.translation.name,
    }
  },
  data() {
    return {
      isLoaded: false,
      artists: {
        translation: {
          name: '',
          bio: '',
        },
        media: {
          link: '',
        },
        auction: null,
      },
    }
  },
  created: function () {
    const self = this
    self.isLoaded = false

    this.$api.getArtists(this.$route.params.slug).then((response) => {
      self.artists = response.data

      self.isLoaded = true
    })
  },
  computed: {
    pastProducts() {
      return this.artists.products.filter((product) => product.price != null)
    },
    futureProducts() {
      return this.artists.products.filter((product) => {
        const futureAuctions = product.auctions.filter((auction) => {
          return this.moment(auction.started_at).isBefore()
        })
        return product.buyable_online ? futureAuctions.length > 0 : false
      })
    },
  },
  methods: {
    getBreadcrumbs() {
      return [
        {
          to: {
            name: 'app.artists.list',
          },
          title: this.$t('navigation.artists'),
        },
        {
          to: {
            name: 'app.artists.details',
            params: {
              slug: this.$route.params.slug,
            },
          },
          title: this.artists.translation.name,
        },
      ]
    },
    scrollMeTo(refName) {
      const element = this.$refs[refName]
      const top = element.offsetTop

      window.scrollTo(0, top)
    },
  },
}
</script>

<style scoped>
.propos__title {
  margin-bottom: 2rem;
}

.request__estimation {
  text-align: center;
}

.request__estimation-title {
  padding: 65px 0;
}

.show_more {
  font-size: 10px;
  text-transform: uppercase;
}

.specialites__subtitle {
  text-transform: uppercase;
}

.sticky {
  position: sticky;
  top: 2rem;
}

.auction-block {
  margin: 20px 0 100px 0;
}

.loader {
  text-align: center;
  top: 200px;
  width: 50px;
  min-height: 500px;
  margin: auto;
  position: relative;
}
</style>

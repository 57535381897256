<template>
  <div>
    <app-header></app-header>

    <main
      class="page-main"
      :class="{ fullscreen: this.$store.state.fullscreen }"
    >
      <div
        :class="[
          $route.name !== 'app.home' && $route.name !== 'app.products.details'
            ? 'container'
            : '',
        ]"
        :style="
          this.$route.name === 'app.auctions.details' ||
          this.$route.name === 'app.future_auctions'
            ? 'max-width: none'
            : ''
        "
      >
        <router-view :key="$route.fullPath"></router-view>

        <cookies-message></cookies-message>
        <div :class="[$route.name === 'app.home' ? 'container' : '']">
          <back-to-top></back-to-top>
        </div>
      </div>
    </main>

    <adwords-modal />

    <app-footer></app-footer>
  </div>
</template>

<script>
import AppHeader from './AppHeader'
import AppFooter from './AppFooter'
import BackToTop from './BackToTop'
import AdwordsModal from './../adwords/Modal'
import CookiesMessage from './CookiesMessage'
import { getLocale } from '@/services/i18n.service'

export default {
  components: {
    AppFooter,
    AppHeader,
    BackToTop,
    CookiesMessage,
    AdwordsModal,
  },
  name: 'main-wrapper',
  created() {
    const locale = getLocale(this.$route.params.locale)

    if (locale !== this.$route.params.locale) {
      this.$router.push({
        params: {
          locale: 'en',
        },
      })
    }

    this.$http.setLocale(locale)
    this.$i18n.locale = locale
  },
}
</script>

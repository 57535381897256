<template>
  <div>
    <form
      class="form"
      id="bid"
      @submit.prevent="setBidLimit()"
      v-if="biddable && biddingActive"
    >
      <div class="row">
        <div class="col col-50">
          <select class="form__input" v-model="selected">
            <option disabled value="">€€€ (Votre offre)</option>
            <option v-for="(value, index) in values" :key="index">
              {{ value }}
            </option>
          </select>
        </div>
        <div class="col col-50">
          <button
            type="submit"
            form="bid"
            class="button product-button dark"
            :disabled="highestBidder"
          >
            {{ $t('auctions.bid') }}
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import BidsMixin from '@/components/products/mixins/bids.mixin'

export default {
  name: 'ProductBiddingForm',
  mixins: [BidsMixin],
  props: {
    product: {
      type: Object,
      required: true,
    },
    auction: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    values: {
      type: Array,
      required: true,
    },
    minimumRateIncrease: {
      type: Number,
      required: true,
    },
  },
  data: function () {
    return {
      error: null,
      selected: null,
    }
  },
  mounted() {
    this.selected = this.values[0]
  },
  watch: {
    values: function () {
      if (
        this.biddingLimit &&
        this.biddingLimit.bid_limit_amount > this.directBid
      ) {
        this.selected = this.biddingLimit.bid_limit_amount
      } else {
        this.selected = this.values[0]
      }
    },
  },
  methods: {
    makeBid(bid) {
      this.$api
        .createBid({
          auction_id: this.auction.id,
          product_id: this.product.id,
          lot_id: this.product.lots[0].id,
          price: bid,
          type: 'online_bid',
          rate_increase: this.minimumRateIncrease,
        })
        .catch((error) => {
          if (error.response) {
            this.error = error.response.data.error
          }
        })
    },
    setBidLimit() {
      this.$api
        .setBidLimit({
          product_id: this.product.id,
          user_id: this.user.id,
          bid_limit: true,
          bid_limit_amount: this.selected,
        })
        .then(() => {
          if (!this.highestBidder) {
            this.makeBid(this.directBid)
          }
        })
        .catch((error) => {
          if (error.response) {
            this.error = error.response.data.error
          }
        })
    },
  },
}
</script>

<style scoped>
form {
  margin: 2em 0;
}
</style>

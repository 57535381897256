<template>
  <div class="resume__container" v-if="lots.length">
    <div class="resume__title">{{ $t('live.auction_finished_title') }}</div>

    <div class="row resume__header resume__row">
      <div class="col col-20">Lot</div>
      <div class="col col-60">Product name</div>
      <div class="col col-20">Price</div>
    </div>

    <div v-for="lot in lots" class="row resume__row">
      <div class="col col-20">{{ lot.lot_number }}</div>
      <div class="col col-60">
        <router-link
          class="link link--underline"
          :to="{
            name: 'app.lots.details',
            params: { slug: auction.slug, number: lot.lot_number },
          }"
        >
          {{ lot.product.translation.name }}
        </router-link>
      </div>
      <div class="col col-20">{{ lot.product.price }} €</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'won-lots-resume',
  props: ['auction'],
  data() {
    return {
      lots: [],
    }
  },
  created() {
    const self = this

    this.$api.getWonLots(this.auction.id).then((response) => {
      self.lots = response.data
    })
  },
}
</script>

<template>
  <header class="page-header" :class="{ 'page-header--fixed': !isClosed }">
    <div class="container" v-if="!$store.state.hide_header">
      <router-link :to="{ name: 'app.home' }" class="logo__wrap">
        <picture>
          <source
            media="(max-width: 1024px)"
            srcset="
              /img/logo-piasa-mobile.png    1x,
              /img/logo-piasa-mobile@2x.png 2x
            "
          />
          <img
            class="logo"
            src="/img/logo-piasa.png"
            srcset="/img/logo-piasa@2x.png 2x"
            alt="PIASA logo"
          />
        </picture>
      </router-link>
      <button
        class="main-nav__toggle"
        type="button"
        v-if="$route.name !== 'app.auction_slide'"
        :class="{ 'main-nav__toggle--closed': isClosed }"
        @click="showMobileMenu()"
      >
        Menu
        <span class="main-nav__menu-icon"></span>
      </button>
      <nav class="main-nav" v-if="$route.name !== 'app.auction_slide'">
        <ul class="main-nav__list top-nav">
          <li class="top-nav__item" v-for="item in mainMenu">
            <router-link :to="item.to" class="top-nav__link">
              {{ item.title }}
            </router-link>
          </li>
          <li class="top-nav__item">
            <piasa-dropdown
              :items="piasaMenu"
              :value="'Piasa'"
              :value-class="'top-nav__link'"
            >
            </piasa-dropdown>
          </li>
        </ul>
        <ul class="main-nav__list user-bar">
          <li class="user-bar__item">
            <router-link
              :to="{ name: 'app.profile' }"
              class="user-bar__link"
              v-if="$http.isTokenExpired() || $store.state.token === undefined"
            >
              {{ $t('navigation.profile') }}
            </router-link>
            <div
              class="dropdown"
              :class="{ 'dropdown--opened': dropdownIsOpened }"
              v-else-if="!$http.isTokenExpired()"
            >
              <a @click="openDropdown()" class="user-bar__link">{{
                $t('navigation.profile')
              }}</a>
              <ul class="dropdown__list">
                <li class="dropdown__item" v-for="item in profileMenu">
                  <router-link :to="item.to" class="link"
                    >{{ item.title }}
                  </router-link>
                </li>
                <li class="dropdown__item">
                  <a @click="logout()" class="link"> Logout </a>
                </li>
              </ul>
            </div>
          </li>
          <li class="user-bar__item">
            <language-bar></language-bar>
          </li>
        </ul>
      </nav>
      <search-bar v-if="$route.name !== 'app.auction_slide'"> </search-bar>
    </div>
  </header>
</template>

<script>
import PiasaDropdown from '../Inputs/PiasaDropdown'
import LanguageBar from './common/LanguageBar'
import SearchBar from './common/SearchBar'

export default {
  components: {
    PiasaDropdown,
    LanguageBar,
    SearchBar,
  },
  name: 'app-header',
  data() {
    return {
      isClosed: true,
      dropdownIsOpened: false,
      mediaQueryMobile: 998,
      mainMenu: [
        {
          to: {
            name: 'app.future_auctions',
          },
          title: this.$t('navigation.future_auctions'),
        },
        {
          to: {
            name: 'app.past_auctions',
          },
          title: this.$t('navigation.past_auctions'),
        },
        {
          to: {
            name: 'app.news.list',
          },
          title: this.$t('navigation.news'),
        },
      ],
      piasaMenu: [
        {
          to: {
            name: 'app.contact_us',
          },
          title: this.$t('navigation.contact'),
        },
        {
          to: {
            name: 'app.static_page',
            params: {
              slug: 'about',
            },
          },
          title: this.$t('navigation.about'),
        },
        {
          to: {
            name: 'app.categories',
          },
          title: this.$t('navigation.categories'),
        },
        {
          to: {
            name: 'app.static_page',
            params: {
              slug: 'how-to-bid',
            },
          },
          title: this.$t('navigation.buy'),
        },
        {
          to: {
            name: 'app.static_page',
            params: {
              slug: 'how-to-sell',
            },
          },
          title: this.$t('navigation.sell'),
        },
        {
          to: {
            name: 'app.collections.details',
            params: {
              slug: 'inventaires-collections',
            },
          },
          title: this.$t('navigation.collections'),
        },
        {
          to: {
            name: 'app.artists.list',
          },
          title: this.$t('navigation.artists'),
        },
        {
          to: {
            name: 'app.static_page',
            params: {
              slug: 'edition',
            },
          },
          title: this.$t('navigation.edition'),
        },
        {
          to: {
            name: 'app.presses.list',
          },
          title: this.$t('navigation.press'),
        },
      ],
      profileMenu: [
        {
          to: {
            name: 'app.profile.account',
          },
          title: this.$t('navigation.profile'),
        },
        {
          to: {
            name: 'app.followed_products',
          },
          title: this.$t('navigation.lots_followed'),
        },
        {
          to: {
            name: 'app.estimation',
          },
          title: this.$t('navigation.my_estimates'),
        },
        // {
        //   to: {
        //     name: 'app.auctions',
        //   },
        //   title: this.$t('navigation.my_auctions'),
        // },
        {
          to: {
            name: 'app.future_auctions',
          },
          title: this.$t('navigation.bid_online'),
        },
      ],
    }
  },
  mounted: function () {
    this.isClosed = true
  },
  methods: {
    openDropdown() {
      if (window.innerWidth < this.mediaQueryMobile) {
        this.dropdownIsOpened = !this.dropdownIsOpened
      }
    },
    logout() {
      this.$store.commit('logout')

      if (this.$route.name !== 'app.home')
        this.$router.push({
          name: 'app.home',
        })
    },
    showMobileMenu() {
      this.isClosed = !this.isClosed
      this.isClosed
        ? (document.body.style.overflow = 'auto')
        : (document.body.style.overflow = 'hidden')
    },
  },
  created: function () {
    document.body.style.overflow = 'auto'
  },
}
</script>

<style scoped>
.container {
  max-width: none;
}
</style>

<template>
  <profile-item-block
    @save="save()"
    :title="$t('profile.address')"
    @cancel="$emit('cancel')"
    ref="profileBox"
  >
    <div slot="preview">
      <li class="account__item">
        {{ user.profile.address ? user.profile.address : '-' }}
      </li>
      <li class="account__item">
        {{ user.profile.post_code ? user.profile.post_code : '-' }}
      </li>
      <li class="account__item">
        {{ user.profile.city ? user.profile.city : '-' }}
      </li>
      <li class="account__item">
        {{ user.profile.country ? user.profile.country : '-' }}
      </li>
    </div>
    <li slot="edit" class="account__edit">
      <div class="account__item">
        <input
          class="form__input"
          v-model="user.profile.address"
          :placeholder="$t('profile.address')"
          @input="$v.user.profile.address.$touch()"
          :class="{ 'has-error': $v.user.profile.address.$error }"
        />
      </div>
      <div class="account__item">
        <input
          class="form__input"
          v-model="user.profile.post_code"
          :placeholder="$t('profile.post_code')"
          @input="$v.user.profile.post_code.$touch()"
          :class="{ 'has-error': $v.user.profile.post_code.$error }"
        />
      </div>
      <div class="account__item">
        <input
          class="form__input"
          v-model="user.profile.city"
          :placeholder="$t('profile.city')"
          @input="$v.user.profile.city.$touch()"
          :class="{ 'has-error': $v.user.profile.city.$error }"
        />
      </div>
      <div class="account__item">
        <select
          class="form__input"
          v-model="user.profile.country"
          :class="{ 'has-error': $v.user.profile.country.$error }"
        >
          <option v-for="country in countries" :key="country" :value="country">
            {{ country }}
          </option>
        </select>
      </div>
    </li>
  </profile-item-block>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import ProfileItemBlock from './ProfileItemBlock'
import { profileMixin } from '@/mixins/profile.mixin'
import axios from 'axios'

export default {
  components: { ProfileItemBlock },
  name: 'address-box',
  props: ['user'],
  mixins: [validationMixin, profileMixin],
  validations: {
    user: {
      profile: {
        address: { required },
        post_code: { required },
        city: { required },
        country: { required },
      },
    },
  },
  data: function () {
    return {
      countries: [],
    }
  },
  created: function () {
    axios
      .get('https://countriesnow.space/api/v0.1/countries')
      .then((response) => {
        const uniqueArray = response.data.data.filter(
          (country, index, self) =>
            index === self.findIndex((t) => t.country === country.country)
        )
        this.countries = uniqueArray.map((country) => country.country)
      })
  },
}
</script>

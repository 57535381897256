<template>
  <div>
    <router-link
      :to="{ name: 'app.news.details', params: { id: news.slug } }"
      class="card"
    >
      <div class="image-box image-box__link">
        <img
          :src="`${$http.getBackendUrl()}${news.media.link}`"
          :alt="news.media.name"
          v-if="news.media.link"
        />
      </div>
    </router-link>
    <h3 class="card__title">{{ news.translation.title }}</h3>
  </div>
</template>

<script>
export default {
  name: 'news-item',
  props: ['news'],
}
</script>

<style scoped>
.card__img {
  margin-bottom: 2rem;
}
</style>

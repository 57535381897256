<template>
  <profile-item-block
    @save="save()"
    :title="$t('profile.information')"
    @cancel="$emit('cancel')"
    ref="profileBox"
  >
    <div slot="preview">
      <li class="account__item">{{ getQualification() }}</li>
      <li class="account__item">{{ getGender() }}</li>
      <li class="account__item">{{ user.first_name }}</li>
      <li class="account__item">{{ user.last_name }}</li>
      <li class="account__item">
        {{ user.profile.area_code ? user.profile.area_code : '-' }}
        {{ user.profile.phone ? user.profile.phone : '-' }}
      </li>
      <li class="account__item">
        {{ user.profile.birthday ? getBirthday() : '-' }}
      </li>
    </div>
    <li slot="edit" class="account__edit">
      <radio-buttons-box
        :items="radioButtonItems.qualification"
        v-model="user.profile.qualification"
        name="qualification"
      >
      </radio-buttons-box>
      <radio-buttons-box
        :items="radioButtonItems.gender"
        v-model="user.profile.gender"
        name="gender"
      >
      </radio-buttons-box>
      <div class="account__item">
        <input
          class="form__input"
          v-model="user.first_name"
          :placeholder="$t('auth.first_name')"
          @input="$v.user.first_name.$touch()"
          :class="{ 'has-error': $v.user.first_name.$error }"
        />
      </div>
      <div class="account__item">
        <input
          class="form__input"
          v-model="user.last_name"
          :placeholder="$t('auth.last_name')"
          @input="$v.user.last_name.$touch()"
          :class="{ 'has-error': $v.user.last_name.$error }"
        />
      </div>
      <div class="account__item">
        <div class="row">
          <div class="col col-25">
            <input class="form__input" v-model="user.profile.area_code" />
          </div>
          <div class="col col-75">
            <input
              class="form__input"
              v-model="user.profile.phone"
              :placeholder="$t('profile.phone')"
            />
          </div>
        </div>
      </div>
      <div class="account__item">
        <datepicker
          input-class="form__input"
          v-model="user.profile.birthday"
          :placeholder="$t('profile.birthday')"
          format="yyyy-MM-dd"
          @input="$v.user.profile.birthday.$touch()"
          :class="{ 'has-error': $v.user.profile.birthday.$error }"
          :language="datepickerLang[$route.params.locale]"
        />
      </div>
    </li>
  </profile-item-block>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import ProfileItemBlock from './ProfileItemBlock'
import RadioButtonsBox from '../../Inputs/RadioButtonsBox'
import { profileMixin } from '@/mixins/profile.mixin'
import Datepicker from 'vuejs-datepicker'
import { en, fr } from 'vuejs-datepicker/dist/locale'

export default {
  components: {
    Datepicker,
    RadioButtonsBox,
    ProfileItemBlock,
  },
  name: 'information',
  props: ['user'],
  mixins: [validationMixin, profileMixin],
  validations: {
    user: {
      first_name: { required },
      last_name: { required },
      profile: {
        birthday: { required },
      },
    },
  },
  data() {
    return {
      radioButtonItems: {
        qualification: [
          {
            value: 'particular',
            title: this.$t('auth.particular'),
          },
          {
            value: 'professional',
            title: this.$t('auth.professional'),
          },
        ],
        gender: [
          {
            value: 'male',
            title: this.$t('auth.male'),
          },
          {
            value: 'female',
            title: this.$t('auth.female'),
          },
        ],
      },
      datepickerLang: {
        en: en,
        fr: fr,
      },
    }
  },
  methods: {
    getQualification() {
      return this.user.profile.qualification === 'professional'
        ? this.$t('auth.professional')
        : this.$t('auth.particular')
    },
    getGender() {
      return this.user.profile.gender === 'male'
        ? this.$t('auth.male')
        : this.$t('auth.female')
    },
    getBirthday() {
      return this.moment(this.user.profile.birthday).format('YYYY-MM-DD')
    },
  },
}
</script>

<style scoped>
.has-error {
  border-color: #ce081b;
  color: #ce081b;
}
</style>

<template>
  <article class="auction__lot" :class="{ 'auction__lot--active': isActive }">
    <a class="image-box__link" @click="onClick()">
      <div class="image-box">
        <img
          :src="getThumbnail(imageLink)"
          :srcset="getSrcSet(imageLink)"
          alt=""
        />
        <live-streaming-lot-item-status
          :is-sold="!lot.product.count"
          :is-live="isActive"
          @reopenned="$emit('reopenned')"
        />
      </div>
      <div
        class="auction-status auction-status--buyer"
        v-if="!lot.product.count && lot.best_bid"
      >
        <div v-if="lot.best_bid.user_id">
          Buyer: W{{ lot.best_bid.user_id }}
        </div>
        <div v-if="!lot.best_bid.user_id">Room</div>
        <div>Price: {{ lot.best_bid.price }}</div>
      </div>
    </a>
    <div class="lots__content">
      <span class="lots__number">{{ lot.lot_number }}</span>
      <div class="lots__descr">
        <h3 class="lots__title">{{ lot.product.translation.name }}</h3>
        <span class="lots__cost" v-if="estimationNotNull()">
          Estimation : {{ lot.product.min_estimation }} /
          {{ lot.product.max_estimation }} €
        </span>
      </div>
    </div>
  </article>
</template>

<script>
import { get } from 'lodash'
import LiveStreamingLotItemStatus from './LiveStreamingLotItemStatus'

export default {
  name: 'live-streaming-lots-item',
  components: { LiveStreamingLotItemStatus },
  props: ['lot', 'auction'],
  data() {
    return {
      lotLive: false,
      lotActive: false,
    }
  },
  methods: {
    estimationNotNull() {
      return (
        !this.lot.product.buyable_online &&
        (this.lot.product.min_estimation || this.lot.product.max_estimation)
      )
    },
    onClick() {
      if (this.lot.product.count) {
        this.$emit('clicked')
      }
    },
  },
  computed: {
    isActive() {
      return (
        this.lot.lot_number === this.auction.data.live_streaming.current_lot
      )
    },
    imageLink() {
      return get(this.lot, 'product.media.0.link', '')
    },
  },
}
</script>

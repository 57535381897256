<template>
  <div>
    <div class="image-box">
      <label for="file_upload" class="rating__add-image">
        <svg class="rating__add-image-icon" width="128" height="90">
          <use xlink:href="#icon-camera"></use>
        </svg>
        <span>{{ $t('estimation.add') }}</span>
      </label>
      <input
        type="file"
        id="file_upload"
        name=""
        @change="onFileChange"
        accept="image/*"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'image-upload',
  props: ['value', 'accept', 'title'],
  data() {
    return {
      filename: '',
    }
  },
  methods: {
    onFileChange(e) {
      const self = this
      const file = e.target.files[0]

      this.$api.uploadMedia(file.name, file).then((response) => {
        self.filename = file.name

        self.$emit('input', response.data)
      })
    },
    clear() {
      this.filename = null

      this.$emit('input', {})
    },
  },
}
</script>

<style scoped>
input {
  display: none;
}

a {
  float: none;
}

.rating__add-image {
  cursor: pointer;
}
</style>

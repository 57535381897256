<template>
  <div class="row">
    <div
      v-for="item in mainPageItems"
      :class="{
        'col-50': mainPageItems.length === 2,
        'col-33': mainPageItems.length === 3,
      }"
      class="col"
    >
      <article
        v-if="isLoaded"
        class="article"
        @click="redirectToInformationPage(item)"
      >
        <time :datetime="item.date" class="article__date">
          <span class="article__date-number">{{
            moment(item.date).format('DD')
          }}</span>
          <span class="article__date-month">{{
            moment(item.date).format('MMM')
          }}</span>
        </time>
        <img :alt="item.alt" :src="item.image" class="article__img" />
        <div class="article__content">
          <h2 class="article__title">
            <router-link :to="item.redirect_to">
              {{ item.title || '' | truncate(50) }}
            </router-link>
          </h2>
          <p class="article__descr">
            {{ item.description || '' | truncate(150) }}
          </p>
        </div>
        <ul class="article__info">
          <li v-if="item.lots_redirect" class="article__info-item">
            <a :href="item.lots_redirect" class="article__info-link">
              {{ $t('auctions.show_lots') }}
            </a>
          </li>
        </ul>
      </article>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PromotedAuctions',
  props: {
    isLoaded: {
      type: Boolean,
      required: true,
    },
    mainPageItems: {
      type: Array,
      required: true,
    },
  },
  methods: {
    redirectToInformationPage: function (item) {
      if (item.drouot_auction_url) {
        window.location.href = item.drouot_auction_url
      } else {
        this.$router.push(item.redirect_to)
      }
    },
  },
}
</script>

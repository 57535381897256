<template>
  <div>
    <a
      @click="selectLanguage(toggleLanguage())"
      class="user-bar__language"
      v-if="isMobile()"
      >{{ toggleLanguage() }}</a
    >
    <div class="dropdown" v-if="!isMobile()">
      <span class="user-bar__language">{{ $route.params.locale }}</span>
      <ul class="dropdown__list">
        <li class="dropdown__item" v-for="item in languages">
          <a
            :class="[$route.params.locale === item.value ? 'active' : '']"
            class="link"
            @click="selectLanguage(item.value)"
            >{{ item.title }}</a
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'language-bar',
  data() {
    return {
      mediaQueryMobile: 998,
      languages: [
        {
          title: 'English',
          value: 'en',
        },
        {
          title: 'Français',
          value: 'fr',
        },
      ],
    }
  },
  methods: {
    isMobile() {
      return window.innerWidth < this.mediaQueryMobile
    },
    toggleLanguage() {
      if (this.$route.params.locale === 'en') {
        return 'fr'
      } else {
        return 'en'
      }
    },
    selectLanguage(lang) {
      this.$http.setLocale(lang)

      this.$router.push({
        params: {
          locale: lang,
        },
      })
    },
  },
}
</script>

<template>
  <div>
    <section class="auction__section">
      <div class="auction__bid auction__bid--control">
        <lot-price-input
          v-if="bestBidAmount || auction.data.live_streaming.current_price"
          :value="bestBidAmount || auction.data.live_streaming.current_price"
          :increase-amount="auction.data.live_streaming.minimum_rate_increase"
          @input="setPrice($event, true)"
          :auction="auction"
        />
        <div class="auction__bid-col">
          <div
            class="auction__bid-current auction__bid-current--outline"
            :class="{ 'approve-max-bid': hasNotApprovedBid }"
            @click="setPrice(maxInternetBid, false)"
          >
            <div class="auction__bid-current-text">
              {{ $t('live.internet_auction') }}
            </div>
            <div class="auction__bid-current-value">
              {{ maxInternetBid }} {{ currency }}
            </div>
          </div>
        </div>
      </div>
      <div class="auction__info-admin-wrap">
        <a
          v-if="$parent.auction.data.live_streaming.current_lot !== '1'"
          class="link link--underline link-navigation"
          @click="
            $emit(
              'reopenned',
              $parent.auction.lots[
                parseInt($parent.auction.data.live_streaming.current_lot) - 2
              ]
            )
          "
        >
          {{ $t('live.return_to_last_auction') }}
        </a>
        <router-link
          :to="{ name: 'app.online_video', params: { slug: auction.slug } }"
          class="link link--underline link-navigation"
        >
          {{ $t('live.show_live_stream') }}
        </router-link>
        <a class="link link--fair-warning" @click="fairWarning()">{{
          $t('live.fair_warning')
        }}</a>
        <ul class="auction__info-admin-list">
          <li class="auction__info-admin-item">
            <div class="auction__bid-placer-row">
              <span class="auction__info-title">
                <span
                  class="auction__info-icon auction__info-icon--large auction__info-icon--level"
                ></span>
                {{ $t('live.minimum_amount_for_next_bid') }}:
              </span>
              <rate-increase-input
                v-model="auction.data.live_streaming.minimum_rate_increase"
                @input="setMinimumRateIncrease()"
                :auction="auction"
              />
            </div>
          </li>
          <li class="auction__info-admin-item">
            <div class="auction__bid-placer-row">
              <span class="auction__info-title">
                <span
                  class="auction__info-icon auction__info-icon--large auction__info-icon--bidders"
                ></span>
                {{ $t('live.bidders') }}:
              </span>
              <span class="auction__info-value">{{ biddersCount }}</span>
            </div>
          </li>
          <li class="auction__info-admin-item">
            <div class="auction__bid-placer-row">
              <span class="auction__info-title">
                <span
                  class="auction__info-icon auction__info-icon--large auction__info-icon--visiters"
                ></span>
                {{ $t('live.watchers') }}:
              </span>
              <span class="auction__info-value">{{
                auction.data.live_streaming.watchers
              }}</span>
            </div>
          </li>
        </ul>
      </div>
      <div class="auction__admin-controller">
        <button
          class="button auction__admin-controller-button"
          @click="start()"
          v-if="!isAuctionStarted"
        >
          {{ $t('live.start') }}
        </button>
        <div class="row" v-if="!isLastLot && isAuctionStarted">
          <div class="col col-50">
            <button
              class="button auction__admin-controller-button"
              @click="next()"
            >
              {{ $t('live.skip') }}
            </button>
          </div>
          <div class="col col-50">
            <button
              id="buttonSold"
              class="button auction__admin-controller-button"
              @click="sold()"
            >
              {{ $t('live.sold') }}
            </button>
          </div>
        </div>
        <div class="row" v-if="isLastLot && isAuctionStarted">
          <div class="col col-50">
            <button
              class="button auction__admin-controller-button col-50"
              @click="stop()"
            >
              {{ $t('live.stop') }}
            </button>
          </div>
          <div class="col col-50">
            <button
              class="button auction__admin-controller-button"
              @click="soldAndStop()"
            >
              {{ $t('live.sold_and_stop') }}
            </button>
          </div>
        </div>
        <label class="has-error" v-if="error">{{ error }}</label>
      </div>
    </section>
  </div>
</template>

<script>
import { findIndex, sortBy, last, cloneDeep } from 'lodash'
import LotPriceInput from './LotPriceInput'
import RateIncreaseInput from './RateIncreaseInput'
import BidsMixin from '../mixins/bids.mixin'

export default {
  name: 'AdminOnlineAuctionManaging',
  components: { RateIncreaseInput, LotPriceInput },
  props: ['auction'],
  mixins: [BidsMixin],
  data() {
    return {
      error: null,
      declinedBid: 0,
    }
  },
  methods: {
    setPrice(value, isRoomBid) {
      this.auction.data.live_streaming.current_price = value

      if (isRoomBid) {
        this.$api
          .createBid({
            lot_id: this.auction.data.live_streaming.current_lot_id,
            price: value,
            type: 'online_bid',
            room_bid: true,
          })
          .then((response) => {
            this.$api.setPrice(
              this.auction.id,
              this.auction.data.live_streaming.current_lot_id,
              value,
              response.data
            )
          })
      } else {
        this.$api.setPrice(
          this.auction.id,
          this.auction.data.live_streaming.current_lot_id,
          value
        )
      }
    },
    setMinimumRateIncrease() {
      this.$api.setMinimumRateIncrease(
        this.auction.id,
        this.auction.data.live_streaming.minimum_rate_increase
      )
    },
    sold() {
      document.getElementById('buttonSold').blur()
      this.$api.lotSold(this.auction).then((response) => {
        const updatedAuction = cloneDeep(this.auction)

        updatedAuction.data.live_streaming = {
          ...updatedAuction.data.live_streaming,
          ...response.data.live_streaming,
        }

        updatedAuction.online_bids = response.data.online_bids

        const lotIndex = findIndex(this.auction.lots, {
          id: response.data.lot.id,
        })
        updatedAuction.lots[lotIndex] = {
          ...updatedAuction.lots[lotIndex],
          ...response.data.lot,
        }

        this.$emit('changed', updatedAuction)
      })
    },
    stop() {
      const self = this

      return this.$api
        .stopAuction(this.auction.id)
        .then((response) => {
          self.$emit('changed', response.data)
        })
        .catch((error) => {
          if (error.response.status === 401) {
            self.error = self.$t('live.youtube_stopping_error')
          }

          self.auction.data.live_streaming.on_air = false
        })
    },
    soldAndStop() {
      const self = this

      this.$api.lotSold(this.auction).then(() => self.stop())
    },
    start() {
      const self = this

      this.$api
        .startAuction(this.auction.id)
        .then((response) => {
          self.$emit('changed', response.data)
        })
        .catch((error) => {
          if (error.response) {
            self.error =
              error.response.data.error || error.response.data.message
          } else {
            self.error = 'Something went wrong. Please contact your developers'
          }
        })
    },
    next() {
      const nextLot = this.auction.lots[this.lotIndex + 1]

      return this.skipTo(nextLot)
    },
    previous() {
      const nextLot = this.auction.lots[this.lotIndex - 1]

      return this.skipTo(nextLot)
    },
    skipTo(lot) {
      this.auction.data.live_streaming.current_lot = lot.lot_number
      this.auction.data.live_streaming.current_lot_id = lot.id
      this.auction.data.live_streaming.current_price =
        lot.product.min_estimation

      this.$api.skipToLot(this.auction.id, lot.lot_number).then((response) => {
        this.$emit('changed', response.data)
      })
    },
    fairWarning() {
      this.$api.fairWarning(this.auction.id)
    },
  },
  computed: {
    lotIndex() {
      return this.auction.lots.findIndex(
        (lot) => lot.id === this.auction.data.live_streaming.current_lot_id
      )
    },
    isLastLot() {
      const currentLotIndex = this.lotIndex

      return currentLotIndex === this.auction.lots.length - 1
    },
    isAuctionStarted() {
      return this.auction.data.live_streaming.on_air
    },
    maxInternetBid() {
      const filteredBids = this.auction.online_bids.filter(
        (bid) =>
          bid.lot_id === this.auction.data.live_streaming.current_lot_id &&
          bid.user_id
      )
      const orderedBids = sortBy(filteredBids, 'created_at')
      const highestBid = last(orderedBids)

      return highestBid ? highestBid.price : 0
    },
    biddersCount() {
      return new Set(this.auction.online_bids.map((bid) => bid.user_id)).size
    },
    hasNotApprovedBid() {
      return (
        this.maxInternetBid > this.auction.data.live_streaming.current_price &&
        this.maxInternetBid > this.declinedBid &&
        this.auction.data.live_streaming.current_price !== null
      )
    },
    currency() {
      if (this.$route.name === 'app.admin_auction') {
        return this.auction.id === 330 ? 'ZAR' : '€'
      }
      return '€'
    },
  },
}
</script>

<style scoped lang="scss">
.next_lot_button {
  text-align: right;
}

.approve-max-bid {
  color: green;
  cursor: pointer;
}

.link--fair-warning {
  font-weight: bold;
  color: black;

  &:hover {
    color: red;
  }
}
</style>

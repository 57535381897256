import HttpService from '../services/http.service'
import ApiService from '../services/api.service'
import moment from 'moment'

export default {
  props: {
    $http: {
      type: Object,
      default: () => {
        return HttpService
      },
    },
    $api: {
      type: Object,
      default: () => {
        return ApiService
      },
    },
  },
  methods: {
    moment: moment,
  },
}

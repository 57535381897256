<template>
  <ul
    class="breadcrumb"
    :class="{
      'breadcrumb--context': context === 'context',
      'breadcrumb--context-auto': context === 'auto',
      'breadcrumb--align-center': align === 'center',
      'breadcrumb--align-right': align === 'right',
      'breadcrumb--align-left': align === 'left',
    }"
  >
    <li class="breadcrumb__item">
      <router-link :to="{ name: 'app.home' }" class="breadcrumb__link link">
        {{ $t('home') }}
      </router-link>
    </li>
    <li class="breadcrumb__item" v-for="step in steps">
      <router-link :to="step.to" class="breadcrumb__link link">
        {{ getPreparedTitle(step) || '' | truncate(40) }}
      </router-link>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'breadcrumbs',
  props: {
    steps: {
      type: Array,
    },
    align: {
      type: String,
      default: 'left',
    },
    context: {
      type: String,
      default: 'auto',
    },
  },
  methods: {
    getPreparedTitle(step) {
      return step.title.replace(/<(?:.|\\n)*?>/gm, '')
    },
  },
}
</script>

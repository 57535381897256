<template>
  <section class="sign-in">
    <div v-if="isTokenValid === true">
      <h1>{{ $t('auth.password_recovery') }}</h1>
      <form class="form">
        <div class="form__row">
          <input
            class="form__input"
            type="password"
            :placeholder="$t('auth.password')"
            v-model="params.password"
            :class="{ 'has-error': $v.params.password.$error }"
          />
        </div>
        <div class="form__row">
          <input
            class="form__input"
            type="password"
            :placeholder="$t('auth.confirm_password')"
            v-model="params.confirm"
            :class="{ 'has-error': $v.params.confirm.$error }"
          />
        </div>
        <div class="form__row" @click="restore()">
          <a class="button">{{ $t('auth.reset_password') }}</a>
          <div v-if="error" class="has-error">{{ error }}</div>
        </div>
      </form>
    </div>
    <div v-if="isTokenValid === false">
      <p>{{ $t('auth.invalid_restore_token') }}</p>
      <router-link
        :to="{ name: 'app.forgot_password' }"
        class="link link--underline"
        >{{ $t('auth.try_again') }}</router-link
      >
    </div>
  </section>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, sameAs } from 'vuelidate/lib/validators'

export default {
  name: 'restore-password',
  mixins: [validationMixin],
  metaInfo() {
    return {
      title: 'Restore password',
    }
  },
  data() {
    return {
      isTokenValid: null,
      params: {
        password: '',
        confirm: '',
      },
      token: '',
      error: '',
    }
  },
  validations: {
    params: {
      password: { required },
      confirm: { sameAsPassword: sameAs('password') },
    },
  },
  created: function () {
    const self = this
    this.token = this.$route.query.token

    this.$api
      .checkRestoreToken(this.token)
      .then(() => {
        self.isTokenValid = true
      })
      .catch(() => {
        self.isTokenValid = false
      })
  },
  methods: {
    restore() {
      this.$v.$touch()

      if (this.$v.$error) {
        return
      }

      const self = this

      this.$api
        .restorePassword(this.token, this.params.password)
        .then(() => {
          self.$router.push({
            name: 'app.sign_in',
          })
        })
        .catch((error) => {
          self.error = error.response.data.error
        })
    },
  },
}
</script>

<style scoped>
.sign-in {
  min-height: 370px;
}
</style>

<template>
  <div class="col">
    <ul class="account__list">
      <li class="account__item">
        <h2>{{ title }}</h2>
      </li>
      <slot name="preview" v-if="mode === 'preview'"></slot>
      <slot name="edit" v-if="mode === 'edit'"></slot>
      <li class="account__item">
        <a class="link link--underline" v-if="mode === 'edit'" @click="save()">
          {{ $t('profile.save') }}
        </a>

        <a
          class="link link--underline"
          v-if="mode === 'edit'"
          @click="cancel()"
        >
          {{ $t('profile.cancel') }}
        </a>

        <a
          class="link link--underline"
          v-if="mode === 'preview'"
          @click="mode = 'edit'"
        >
          {{ $t('profile.update') }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'profile-item-block',
  props: ['title'],
  data() {
    return {
      mode: 'preview',
    }
  },
  methods: {
    save() {
      this.$emit('save')
    },
    cancel() {
      this.mode = 'preview'
      this.$emit('cancel')
    },
    close() {
      this.mode = 'preview'
    },
  },
}
</script>

<style scoped>
a {
  margin-right: 10px;
}
</style>

<template>
  <div class="row">
    <div class="col col-25" v-for="estimation in estimations">
      <div class="rating__product">
        <div class="image-box">
          <img
            :src="getThumbnail(estimation.media[0].link)"
            :srcset="getSrcSet(estimation.media[0].link)"
            alt=""
          />
          <span
            class="vente__label vente__label--green"
            v-if="estimation.min_estimation"
          >
            {{ $t('estimation.estimated') }}
          </span>
          <span
            class="vente__label vente__label--red"
            v-if="estimation.is_rejected"
          >
            {{ $t('estimation.rejected') }}
          </span>
        </div>
        <h3 class="rating__product-title">{{ estimation.title }}</h3>
        <span>Estimation:</span>
        <span class="rating__product-cost" v-if="estimation.min_estimation">
          {{ estimation.min_estimation }}-{{ estimation.max_estimation }}€
        </span>
        <span class="rating__product-cost" v-if="estimation.is_rejected">
          {{ $t('estimation.rejected') }}
        </span>
        <span
          class="rating__product-cost"
          v-if="!estimation.min_estimation && !estimation.is_rejected"
        >
          {{ $t('estimation.not_estimated') }}
        </span>
      </div>
    </div>
    <div>
      <label v-if="!estimations.length">{{ $t('estimation.empty') }}</label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'estimation-results',
  metaInfo() {
    return {
      title: 'Estimation results',
    }
  },
  data() {
    return {
      estimations: [],
    }
  },
  created: function () {
    const self = this

    this.$api.searchEstimations({ all: 1 }).then((response) => {
      self.estimations = response.data
    })
  },
}
</script>

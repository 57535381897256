<template>
  <section class="blog">
    <header class="blog__header">
      <h1 class="blog__title">{{ $t('news.title') }}</h1>
    </header>
    <div class="blog__list">
      <div class="row">
        <div
          class="col"
          v-for="(news, index) in newsList"
          :class="{
            'col-50': index < 2,
            'col-33': index >= 2,
          }"
        >
          <news-list-item :news="news" />
        </div>
      </div>
      <infinite-loading
        ref="loader"
        @infinite="loadMore(arguments[0])"
        spinner="waveDots"
        :distance="1000"
        v-if="hasMore"
      >
        <span slot="no-more"></span>
      </infinite-loading>
    </div>
  </section>
</template>

<script>
import NewsListItem from './NewsListItem'
import InfiniteLoading from 'vue-infinite-loading'
import { clone, isEmpty } from 'lodash'

export default {
  components: {
    NewsListItem,
    InfiniteLoading,
  },
  name: 'news-list',
  metaInfo() {
    return {
      title: this.$t('titles.news'),
      meta: [{ name: 'description', content: this.$t('meta.news') }],
    }
  },
  data() {
    return {
      newsList: [],
      searchFilters: {
        page: 1,
        per_page: 14,
        'where-draft': false,
      },
      hasMore: true,
    }
  },
  methods: {
    loadMore($state) {
      const self = this

      this.$api.searchNews(clone(this.searchFilters)).then((response) => {
        const now = new Date()
        const news = response.data.data.filter((n) => {
          const nDate = new Date(n.publication_date)
          return nDate <= now
        })
        self.addItems(news, $state)
      })

      this.searchFilters.page++
    },
    addItems(items, $state) {
      this.newsList = this.newsList.concat(items)

      if (isEmpty(items)) {
        $state.complete()
      } else {
        $state.loaded()
      }
    },
  },
}
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auction"},[_c('online-auction-header',{attrs:{"auction":_vm.auction,"user":_vm.user}}),(
      (_vm.auction.data.live_streaming &&
        (!_vm.isSubscribed || !_vm.user.profile.is_confirmed)) ||
      (_vm.auction.data.live_streaming &&
        !_vm.auction.data.live_streaming.on_air &&
        _vm.user.profile.is_confirmed &&
        _vm.isSubscribed)
    )?_c('div',[_c('auction-notification',{attrs:{"auction":_vm.auction,"user":_vm.user,"is-loaded":_vm.isLoaded},on:{"subscribed":function($event){return _vm.loadAuction()}}})],1):_vm._e(),(
      _vm.auction.data.live_streaming &&
      _vm.auction.data.live_streaming.on_air &&
      _vm.isLoaded &&
      _vm.isSubscribed &&
      _vm.user.profile.is_confirmed
    )?_c('div',[_c('section',{staticClass:"auction__section"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-50"},[_c('youtube',{attrs:{"video-id":_vm.auction.data.live_streaming.youtube_stream_id,"player-vars":{ autoplay: 1 },"player-width":"100%"}})],1),_c('div',{staticClass:"col col-50"},[(_vm.auction.lots.length)?_c('current-lot',{attrs:{"auction":_vm.auction,"currentLot":0}}):_vm._e()],1)])]),_c('section',{staticClass:"auction__section"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-50"},[_c('client-bid-managing',{attrs:{"auction":_vm.auction,"is-bid-button-blocked":_vm.isBidButtonBlocked,"quick-bid-amount":_vm.quickBidAmount,"lot-id":_vm.lotId}}),_c('client-auction-information',{attrs:{"auction":_vm.auction,"user":_vm.user,"bestBidderId":_vm.bestBidderId,"isBestBidder":_vm.isBestBidder,"isRoomBid":_vm.isRoomBid}})],1),_c('div',{staticClass:"col col-50"},[_c('bids-list',{attrs:{"auction":_vm.auction,"user":_vm.user}})],1)])]),_c('live-streaming-lots-list',{attrs:{"auction":_vm.auction},on:{"lot-selected":function($event){_vm.$router.push(_vm.lotRedirect(arguments[0]))}}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <section class="verification">
    <h1>{{ $t('not_found.title') }}</h1>
    <p>{{ $t('not_found.text') }}</p>
    <router-link :to="{ name: 'app.home' }" class="link link--underline">{{
      $t('not_found.go_home')
    }}</router-link>
  </section>
</template>

<script>
export default {
  name: 'not-found',
  metaInfo() {
    return {
      title: 'Not found',
    }
  },
}
</script>

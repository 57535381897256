<template>
  <div class="col col-25">
    <h2
      class="vente__type"
      :class="[
        auction.type === 'AUCTION' ? 'vente__type--auction' : '',
        auction.type === 'ONLINE ONLY' ? 'vente__type--online' : '',
        auction.type === 'CHARITY AUCTION' ? 'vente__type--charity' : '',
        auction.type === 'ONLIVE' ? 'vente__type--onlive' : '',
      ]"
    >
      {{ auction.type }}
    </h2>
    <div ref="filters_offset"></div>
    <div :class="filtersHaveFixedPosition ? `filters_sticky` : ''">
      <div class="input__wrap">
        <input
          class="input"
          type="text"
          :placeholder="$t('auctions.lots_search')"
          @input="filterResults"
          v-model="filter.query"
        />
        <div class="input__inner">
          <svg
            v-svg
            symbol="icon-search"
            size="15 15"
            class="search__button-icon"
          ></svg>
        </div>
      </div>
      <div class="filters">
        <h4>{{ $t('auctions.sidebar.filters') }}</h4>
        <ul class="filters-list">
          <li :class="[filter.active === 'artists' ? 'active' : '']">
            <a @click="selectFilter('artists')">
              <svg
                width="10"
                height="12"
                viewBox="0 0 10 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.10938 4.99414L1.54688 0.525391C0.923828 0.160156 0 0.525391 0 1.40625V10.3438C0 11.1602 0.859375 11.6543 1.54688 11.2461L9.10938 6.77734C9.77539 6.36914 9.77539 5.40234 9.10938 4.99414Z"
                  fill="#8B8B8B"
                />
              </svg>
              {{ $t('auctions.sidebar.artists') }}
            </a>
            <div class="filters-list__body">
              <div class="filters-list__input-wrap">
                <input
                  class="filters-list__input"
                  v-model="filter.artist"
                  @keyup="filterArtists"
                />
                <svg
                  class="filters-list__input-icon"
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="6.5" cy="6.5" r="5.5" stroke="#363636" />
                  <path
                    d="M10.5 10.5L15.5 15.5"
                    stroke="#363636"
                    stroke-linecap="square"
                  />
                </svg>
              </div>
            </div>
          </li>
          <li :class="[filter.active === 'estimation' ? 'active' : '']">
            <a @click="selectFilter('estimation')">
              <svg
                width="10"
                height="12"
                viewBox="0 0 10 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.10938 4.99414L1.54688 0.525391C0.923828 0.160156 0 0.525391 0 1.40625V10.3438C0 11.1602 0.859375 11.6543 1.54688 11.2461L9.10938 6.77734C9.77539 6.36914 9.77539 5.40234 9.10938 4.99414Z"
                  fill="#8B8B8B"
                />
              </svg>
              {{ $t('auctions.sidebar.estimations') }}
            </a>
            <div class="filters-list__body">
              <vue-slider
                @change="filterEstimations"
                v-model="filter.estimations"
                :enable-cross="false"
                :tooltip-placement="['bottom', 'bottom']"
                :min="filter.min"
                :max="filter.max"
                :tooltip="'always'"
              />
            </div>
          </li>
          <li
            v-if="auction.biddable_online && !auction.finished"
            :class="[filter.active === 'currentAuction' ? 'active' : '']"
          >
            <button
              @click="selectFilter('currentAuction')"
              :class="[!isCurrent ? 'disabled' : '']"
              :disabled="!isCurrent"
            >
              <svg
                width="10"
                height="12"
                viewBox="0 0 10 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.10938 4.99414L1.54688 0.525391C0.923828 0.160156 0 0.525391 0 1.40625V10.3438C0 11.1602 0.859375 11.6543 1.54688 11.2461L9.10938 6.77734C9.77539 6.36914 9.77539 5.40234 9.10938 4.99414Z"
                  fill="#8B8B8B"
                />
              </svg>
              {{ $t('auctions.sidebar.bids') }}
            </button>
            <div class="filters-list__body">
              <vue-slider
                @change="filterCurrentAuction"
                v-model="filter.currentEstimations"
                :enable-cross="false"
                :tooltip-placement="['bottom', 'bottom']"
                :min="filter.currentMin"
                :max="filter.currentMax"
                :tooltip="'always'"
              />
            </div>
          </li>
        </ul>
      </div>
      <div v-if="auction.free_catalog || auction.paid_catalog" class="filters">
        <h4>{{ $t('auctions.sidebar.catalog') }}</h4>
        <ul class="filters-list">
          <li v-if="auction.free_catalog">
            <a
              :href="this.$http.getBackendUrl() + auction.free_catalog.link"
              target="_blank"
            >
              {{ $t('auctions.sidebar.catalog_see') }}
            </a>
          </li>
          <li v-if="auction.paid_catalog">
            <button
              @click.prevent="getBoughtCatalog"
              :class="[auction.is_paid_catalog_bought ? 'disabled' : '']"
              :disabled="auction.is_paid_catalog_bought"
            >
              {{ $t('auctions.sidebar.catalog_buy') }}
            </button>
          </li>
        </ul>
      </div>
      <div class="auction__info">
        <h4>{{ $t('auctions.sidebar.information') }}</h4>
        <h6>{{ $t('auctions.sidebar.date_and_place') }}</h6>
        <div v-if="biddable">
          <p>
            {{ $t('auctions.sidebar.from') }}
            {{ moment(auction.started_at).format('LLLL') }}
          </p>
          <p>
            {{ $t('auctions.sidebar.to') }}
            {{ moment(auction.finished_at).format('LLLL') }}
          </p>
        </div>
        <div v-else>
          <p>{{ moment(auction.started_at).format('LLLL') }}</p>
        </div>
        <p>{{ auction.location }}</p>
        <div
          v-if="
            auction.data.publications.length &&
            auction.data.publications[0].date &&
            auction.data.publications[0].from &&
            auction.data.publications[0].to
          "
          class="mt-2"
        >
          <h6>{{ $t('auctions.sidebar.public_exhibitions') }}</h6>
          <div v-for="publication in auction.data.publications">
            <p>
              {{ moment(publication.date).format('LL') }}
              {{ $t('auctions.sidebar.from') }} {{ publication.from }}
              {{ $t('auctions.sidebar.to') }} {{ publication.to }}
            </p>
          </div>
        </div>
        <div class="mt-2" v-if="auction.employees.length">
          <h6>{{ $t('auctions.sidebar.contacts') }}</h6>
          <div v-for="employee in auction.employees" :key="employee.id">
            <p>{{ employee.first_name }} {{ employee.last_name }}</p>
            <p>T. {{ employee.profile.phone }}</p>
            <p>{{ employee.email }}</p>
          </div>
        </div>
      </div>
      <div class="filters" v-if="auction.translation.information">
        <h3>{{ $t('auctions.sidebar.description') }}</h3>
        <p>{{ auction.translation.information }}</p>
      </div>
      <div
        class="filters"
        v-if="auction.data.conditions && auction.data.conditions.length"
      >
        <h3>{{ $t('auctions.sidebar.conditions') }}</h3>
        <a @click="showConditions">
          <svg
            width="10"
            height="12"
            viewBox="0 0 10 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.10938 4.99414L1.54688 0.525391C0.923828 0.160156 0 0.525391 0 1.40625V10.3438C0 11.1602 0.859375 11.6543 1.54688 11.2461L9.10938 6.77734C9.77539 6.36914 9.77539 5.40234 9.10938 4.99414Z"
              fill="#8B8B8B"
            />
          </svg>
          {{ $t('auctions.sidebar.conditions_see') }}
        </a>
      </div>
      <div class="filters">
        <router-link :to="{ name: 'app.subscribe' }" class="newsletter">
          <h4>{{ $t('subscribe.title') }}</h4></router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component'
import ProductBiddingMixin from '@/components/auctions/mixins/productBidding.mixin'
export default {
  name: 'AuctionSidebar',
  components: {
    VueSlider,
  },
  props: {
    auction: {
      type: Object,
      required: true,
    },
    isCurrent: {
      type: Boolean,
      required: true,
    },
    auctionFilter: {
      type: Object,
      required: true,
    },
  },
  mixins: [ProductBiddingMixin],
  data() {
    return {
      filter: this.auctionFilter,
      lots: this.auction.lots,
      filtersHaveFixedPosition: false,
      alreadyFilteredLots: this.auction.lots,
    }
  },
  created() {
    window.addEventListener('scroll', this.IsFiltersOnTop)
  },
  methods: {
    selectFilter(filter) {
      if (this.filter.active === filter) {
        this.filter.active = ''
      } else {
        this.filter.active = filter
      }
    },

    IsFiltersOnTop() {
      if (this.$refs.filters_offset) {
        const distance = this.$refs.filters_offset.offsetTop
        this.filtersHaveFixedPosition = window.scrollY >= distance
      }
    },

    filterArtists() {
      if (this.filter.artist.length) {
        this.filter.lots = this.lots.filter((item) => {
          if (item.product.artist) {
            return item.product.artist.slug.includes(
              this.filter.artist.replace(' ', '-').toLowerCase()
            )
          } else {
            return (
              item.product.translation.name
                .toLowerCase()
                .indexOf(this.filter.artist.toLowerCase()) !== -1
            )
          }
        })
      } else {
        this.filter.lots = this.lots
      }

      this.alreadyFilteredLots = this.filter.lots
      this.$emit('filteredLots', this.filter.lots)
    },

    filterEstimations() {
      this.filter.lots = this.lots.filter(
        (item) =>
          item.product.min_estimation >= this.filter.estimations[0] &&
          item.product.max_estimation <= this.filter.estimations[1]
      )

      this.alreadyFilteredLots = this.filter.lots
      return this.$emit('filteredLots', this.filter.lots)
    },

    filterCurrentAuction() {
      this.filter.lots = this.lots.filter(
        (item) =>
          item.product.price >= this.filter.currentEstimations[0] &&
          item.product.price <= this.filter.currentEstimations[1]
      )

      this.alreadyFilteredLots = this.filter.lots
      return this.$emit('filteredLots', this.filter.lots)
    },

    filterResults() {
      if (this.filter.query.length) {
        this.filter.lots = this.auction.lots.filter(
          (lot) =>
            lot.product.translation.name
              .toLowerCase()
              .indexOf(this.filter.query.toLowerCase()) !== -1
        )
      } else {
        this.filter.lots = this.alreadyFilteredLots
      }

      this.$emit('filteredLots', this.filter.lots)
    },

    getBoughtCatalog() {
      if (this.auction.is_paid_catalog_bought) {
        return
      }

      const self = this
      self.$api.createCatalogOrder(self.auction.id).then((response) => {
        self.$router.push({
          name: 'app.orders.payment',
          params: {
            orderId: response.data.id,
          },
        })
      })
    },
    showConditions() {
      this.$emit('showConditions')
    },
  },
}
</script>

<style>
@media only screen and (min-width: 1025px) {
  .filters_sticky {
    position: fixed;
    top: 0;
    width: 20%;
  }
}
h2.vente__type.auction {
  width: fit-content;
  padding: 0.5em 2em;
  background: #fef6e9;
  color: #f5a623;
  font-size: 11px;
  line-height: 14px;
}

.mt-2 {
  margin-top: 2em !important;
}

.filters,
.auction__info {
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 10px;
}

.auction__info h6 {
  margin: 2px 0;
}

.auction__info h4 {
  margin-bottom: 16px;
}

.filters h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 29px;
  color: #000000;
  margin-top: 12px;
  margin-bottom: 5px;
}

.filters h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 29px;
  color: #000000;
  margin: 12px 0 0 0;
}

.filters p {
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  margin-top: 12px;
  margin-bottom: 5px;
}

.filters a {
  text-transform: uppercase;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #8b8b8b;
  align-items: center;
  margin-bottom: 3px;
}

.filters .newsletter {
  border-bottom: 2px solid #363636;
  display: table;
  text-transform: capitalize;
}

.filters .newsletter h4 {
  line-height: 14px;
  margin-top: 20px;
}

.filters-list {
  list-style: none;
}

.filters-list li {
  text-transform: uppercase;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #8b8b8b;
  align-items: center;
  margin-bottom: 3px;
}

.filters-list li button {
  text-transform: uppercase;
  background: transparent;
  border: none;
  padding: 0;
  font-family: 'Futura', sans-serif;
  color: #8b8b8b;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  cursor: pointer;
  margin: 0;
}

.filters-list li button:focus {
  outline: none;
}

.filters-list li button:disabled {
  opacity: 0.8;
}

.filters-list li button.disabled {
  cursor: default;
}

.filters-list__body {
  display: none;
  width: calc(100% - 30px);
  margin-left: 20px;
  padding: 4px 0;
}

.filters-list__input-wrap {
  position: relative;
}

.filters-list__input-icon {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%) !important;
}

.filters-list__input {
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #8b8b8b;
  border: none;
  border-bottom: 1px solid #979797;
  padding: 5px;
  margin-bottom: 4px;
}

.filters-list__input:focus {
  outline: none;
}

.filters-list li svg {
  margin-right: 2px;
  transition: transform 0.2s;
}

.filters-list .active svg {
  transform: rotateZ(90deg);
}

.filters-list .active .filters-list__body {
  display: block;
}

.input__wrap {
  width: fit-content;
  margin-bottom: 1em;
}

.input__wrap input {
  border: none;
  padding-left: 0;
  padding-right: 5em;
}

.vue-slider {
  margin: 5px 0 30px 0;
}

.vue-slider-rail {
  height: 2px;
  background: #979797;
  cursor: pointer;
  margin: 5px 0 30px 0;
}

.vue-slider-dot {
  width: 10px;
  height: 10px;
  background: #ffffff;
  border: 1px solid #979797;
  border-radius: 50%;
}

.vue-slider-dot-tooltip-text {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #8b8b8b;
  visibility: visible;
}

.auction__info p {
  font-weight: normal;
  margin: 6px 0;
  font-size: 12px;
  line-height: 16px;
}
</style>

<template>
  <article class="blog__item">
    <router-link
      :to="{ name: 'app.news.details', params: { id: news.slug } }"
      class="image-box__link"
    >
      <div class="image-box image-box--large">
        <img
          :src="getResizedImageUrl(news.media.link, 'original')"
          :alt="news.media.name"
        />
      </div>
    </router-link>
    <span class="blog__item-date">{{
      moment(news.publication_date).format('LL')
    }}</span>
    <h3 class="blog__item-title">{{ news.translation.title }}</h3>
  </article>
</template>

<script>
export default {
  name: 'news-item',
  props: ['news'],
}
</script>

<template>
  <footer class="page-footer">
    <div class="container" v-if="$route.name !== 'app.auction_slide'">
      <div class="row">
        <div class="col col-25">
          <div class="page-footer__title">PIASA</div>
          <hr class="page-footer__break" />
          <ul class="page-footer__list">
            <li class="page-footer__item">118 rue du Faubourg Saint-Honoré</li>
            <li class="page-footer__item">75008 Paris France</li>
            <li class="page-footer__item">
              <a href="tel:+330153341010" class="link">+33 (0)1 53 34 10 10</a>
            </li>
            <li class="page-footer__item">
              <a href="mailto:contact@piasa.fr" class="link"
                >contact@piasa.fr</a
              >
            </li>
          </ul>
        </div>
        <div class="col col-25">
          <div class="page-footer__title">
            <router-link
              :to="{ name: 'app.static_page', params: { slug: 'about' } }"
              class="link"
            >
              {{ $t('footer.about') }}
            </router-link>
          </div>
          <hr class="page-footer__break" />
          <ul class="page-footer__list">
            <li class="page-footer__item">
              <router-link
                :to="{
                  name: 'app.static_page',
                  params: { slug: 'how-to-bid' },
                }"
                class="link"
              >
                {{ $t('footer.sell') }}
              </router-link>
            </li>
            <li class="page-footer__item">
              <router-link :to="{ name: 'app.presses.list' }" class="link">
                {{ $t('footer.press') }}
              </router-link>
            </li>
            <li class="page-footer__item">
              <router-link :to="{ name: 'app.categories' }" class="link">
                {{ $t('footer.categories') }}
              </router-link>
            </li>
            <li class="page-footer__item">
              <router-link
                :to="{
                  name: 'app.static_page',
                  params: { slug: 'legal-notice' },
                }"
                class="link"
              >
                {{ $t('footer.legal_notice') }}
              </router-link>
            </li>
            <li class="page-footer__item">
              <router-link
                :to="{ name: 'app.static_page', params: { slug: 'cgv' } }"
                class="link"
              >
                CGV
              </router-link>
            </li>
          </ul>
        </div>
        <div class="col col-25">
          <div class="page-footer__title">{{ $t('footer.calendar') }}</div>
          <hr class="page-footer__break" />
          <ul class="page-footer__list">
            <li class="page-footer__item">
              <router-link :to="{ name: 'app.past_auctions' }" class="link">
                {{ $t('navigation.past_auctions') }}
              </router-link>
            </li>
            <li class="page-footer__item">
              <router-link :to="{ name: 'app.future_auctions' }" class="link">
                {{ $t('navigation.future_auctions') }}
              </router-link>
            </li>
            <li class="page-footer__item">
              <router-link :to="{ name: 'app.news.list' }" class="link">
                {{ $t('navigation.news') }}
              </router-link>
            </li>
          </ul>
        </div>
        <div class="col col-25">
          <div class="page-footer__title">{{ $t('contact_us.follow') }}</div>
          <hr class="page-footer__break" />
          <ul class="page-footer__list">
            <li class="page-footer__item">
              <router-link :to="{ name: 'app.subscribe' }" class="link">
                Newsletter
              </router-link>
            </li>
            <li class="page-footer__item">
              <ul class="page-footer__social-list social__list">
                <li class="social__item">
                  <a
                    href="https://www.facebook.com/PiasaAuction/"
                    class="social__link"
                    target="_blank"
                  >
                    <svg
                      v-svg
                      symbol="icon-facebook-f"
                      size="20 20"
                      role="presentation"
                      class="social__link-icon"
                    ></svg>
                    <span class="visually-hidden">Facebook</span>
                  </a>
                </li>
                <li class="social__item">
                  <a
                    href="https://www.instagram.com/piasa_auction/"
                    class="social__link"
                    target="_blank"
                  >
                    <svg
                      v-svg
                      symbol="icon-instagram"
                      size="20 20"
                      role="presentation"
                      class="social__link-icon"
                    ></svg>
                    <span class="visually-hidden">Instagram</span>
                  </a>
                </li>
                <li class="social__item">
                  <a
                    href="https://ru.pinterest.com/piasa2014/"
                    class="social__link"
                    target="_blank"
                  >
                    <svg
                      v-svg
                      symbol="icon-pinterest-p"
                      size="20 20"
                      role="presentation"
                      class="social__link-icon"
                    ></svg>
                    <span class="visually-hidden">Pinterest</span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'app-footer',
}
</script>

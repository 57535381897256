<template>
  <div>
    <router-link
      :to="getLink()"
      class="controller__item"
      v-if="lot"
      :class="{
        'controller__item--prev': direction === 'previous',
        'controller__item--next': direction === 'next',
      }"
    >
      <h3 class="controller__name" v-if="direction === 'previous'">
        {{ $t('products.previous') }}
      </h3>
      <h3 class="controller__name" v-if="direction === 'next'">
        {{ $t('products.next') }}
      </h3>
      <!--      <p class="controller__descr">{{ lot.product.translation.name }}</p>-->
    </router-link>
    <div class="mock" v-if="!lot"></div>
  </div>
</template>

<script>
export default {
  name: 'lot-navigation',
  props: ['lot', 'direction'],
  methods: {
    getLink() {
      return {
        name: 'app.products.details',
        params: {
          slug: this.lot.product.slug,
          number: this.lot.lot_number,
        },
      }
    },
  },
}
</script>

<style scoped>
.mock {
  width: 160px;
}

.controller__name {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 13px;
  text-align: right;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #8b8b8b;
}
</style>

<template>
  <div>
    <router-link
      :to="{ name: 'app.auctions.details', params: { slug: auction.slug } }"
      class="card"
    >
      <div class="card__img image-box">
        <img
          :src="getThumbnail(auction.media[0].link)"
          :srcset="getSrcSet(auction.media[0].link)"
          :alt="auction.media[0].name"
        />
      </div>
      <div class="card__content">
        <time class="card__date" :datetime="auction.started_at">
          <span class="card__date-day">{{
            moment(this.auction.started_at).format('ddd')
          }}</span>
          <span class="card__date-number">{{
            moment(this.auction.started_at).format('DD')
          }}</span>
          <span class="card__date-month">{{
            moment(this.auction.started_at).format('MMM')
          }}</span>
        </time>
        <h3 class="card__title" v-html="auction.translation.title" />
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'auction-item',
  props: ['auction'],
}
</script>

import axios from 'axios/index'
import * as _ from 'lodash'
import * as moment from 'moment/moment'
import { dictionary, getLocale } from './i18n.service'
import store from './store.service'

export default new (class HttpService {
  init() {
    axios.interceptors.request.use(
      (axiosConfig) => {
        axiosConfig.headers['X-Requested-With'] = 'XMLHttpRequest'

        return axiosConfig
      },
      (error) => {
        return Promise.reject(error)
      }
    )
  }

  request(
    method,
    url,
    params = null,
    data = null,
    protection,
    headers = {},
    responseType
  ) {
    store.state.loading++

    return this.refreshToken(protection)
      .then(() => {
        const authHeaders = {
          authorization: store.state.token,
          lang: getLocale(),
        }

        const config = {
          method: method,
          url: process.env.VUE_APP_API_ENDPOINT + '/api' + url,
          params: params,
          data: data,
          headers: _.merge(headers, authHeaders),
        }

        if (typeof responseType !== 'undefined') {
          config.responseType = responseType
        }
        return axios(config)
      })
      .then((response) => {
        store.state.loading--

        return response
      })
      .catch((response) => {
        store.state.loading--

        return Promise.reject(response)
      })
  }

  get(url, data = {}, protection = 'auth', responseType) {
    return this.request('get', url, data, {}, protection, {}, responseType)
  }

  post(url, data, protection = 'auth') {
    return this.request('post', url, null, data, protection)
  }

  put(url, data, protection = 'auth') {
    return this.request('put', url, null, data, protection)
  }

  delete(url, data = null, protection = 'auth') {
    return this.request('delete', url, data, protection)
  }

  upload(url, blob) {
    const data = new FormData()

    data.append('file', blob)

    return this.request('post', url, null, data, false, {
      'content-type': 'multipart/form-data',
    })
  }

  refreshToken(protection) {
    return new Promise((resolve, reject) => {
      if (protection === 'guest') {
        resolve()

        return
      }

      if (!store.state.token && protection === 'auth') {
        const url = location.pathname.split('/').slice(0, 2)

        url.push('sign-in')

        window.location = url.join('/')
      }

      if (!store.state.token && protection === 'undemanding') {
        return resolve()
      }

      if (this.isTokenExpired()) {
        axios({
          method: 'get',
          url: process.env.VUE_APP_API_ENDPOINT + '/api/auth/refresh',
          headers: {
            authorization: store.state.token,
          },
        })
          .then((response) => {
            store.commit('token', response.headers.authorization)

            resolve()
          })
          .catch(() => {
            store.commit('logout')

            location.reload()

            reject(new Error('cannot refresh token'))
          })
      } else {
        resolve()
      }
    })
  }

  isTokenExpired() {
    if (store.state.last_logged_at === null) {
      return true
    }

    const momentForRefreshing =
      parseInt(store.state.last_logged_at) + 55 * 60 * 1000
    const now = moment.now().valueOf()

    return now > momentForRefreshing
  }

  setLocale(locale) {
    if (_.isEmpty(dictionary[locale])) {
      locale = 'en'
    }

    window.localStorage.setItem('locale', locale)

    moment.locale(locale)
  }

  getBackendUrl() {
    return process.env.VUE_APP_API_ENDPOINT
  }
})()

<template>
  <div class="dropdown" :class="{ 'dropdown--opened': dropdownIsOpened }">
    <a @click="openDropdown()" :class="valueClass">{{ value }}</a>
    <ul class="dropdown__list">
      <li class="dropdown__item" v-for="item in items" v-if="items">
        <router-link :to="item.to" class="link">
          {{ item.title }}
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'piasa-dropdown',
  props: {
    value: String,
    valueClass: String,
    items: Array,
  },
  data() {
    return {
      dropdownIsOpened: false,
      mediaQueryMobile: 998,
    }
  },
  methods: {
    openDropdown() {
      if (window.innerWidth < this.mediaQueryMobile) {
        this.dropdownIsOpened = !this.dropdownIsOpened
      }
    },
  },
}
</script>

<template>
  <div>
    <contacts></contacts>
    <section class="feedback">
      <form class="form" v-if="!isSuccessful">
        <h3 class="form__title">{{ $t('contact_us.title') }}:</h3>
        <div class="row">
          <div class="col col-50">
            <div class="form__row">
              <input
                class="form__input"
                :placeholder="$t('auth.last_name') + '*'"
                v-model="data.last_name"
                :class="{ 'has-error': $v.data.last_name.$error }"
              />
            </div>
          </div>
          <div class="col col-50">
            <div class="form__row">
              <input
                class="form__input"
                :placeholder="$t('auth.first_name') + '*'"
                v-model="data.first_name"
                :class="{ 'has-error': $v.data.first_name.$error }"
              />
            </div>
          </div>
        </div>
        <div class="form__row">
          <input
            class="form__input"
            :placeholder="$t('auth.email') + '*'"
            v-model="data.email"
            :class="{ 'has-error': $v.data.email.$error }"
          />
        </div>
        <div class="form__row">
          <input
            class="form__input"
            v-model="data.phone"
            :placeholder="$t('profile.phone') + '*'"
          />
        </div>
        <div class="form__row">
          <input
            class="form__input"
            :placeholder="$t('contact_us.subject') + '*'"
            v-model="data.topic"
            :class="{ 'has-error': $v.data.topic.$error }"
          />
        </div>
        <div class="form__row">
          <editor-content
            class="editor__content"
            :class="{ 'has-error': $v.data.text.$error }"
            :editor="editor"
          ></editor-content>
        </div>
        <a class="form__button button" @click="send()">{{
          $t('contact_us.send')
        }}</a>
      </form>
      <label v-if="isSuccessful" class="form__success"
        >Message successfully sent.<br />
        Please wait for answer</label
      >
      <p class="feedback__descr">{{ $t('contact_us.subscribe') }}</p>
      <router-link
        :to="{ name: 'app.subscribe' }"
        class="feedback__link link link--underline"
        >{{ $t('contact_us.subscribe_button') }}</router-link
      >
    </section>
  </div>
</template>

<script>
import Contacts from './Contacts'

import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
import { Editor, EditorContent } from 'tiptap'

export default {
  components: { Contacts, EditorContent },
  name: 'contact-us',
  mixins: [validationMixin],
  metaInfo() {
    return {
      title: 'Contact Us',
    }
  },
  data() {
    return {
      editor: new Editor({
        content: '',
        onUpdate: ({ getHTML }) => {
          this.data.text = getHTML()
        },
      }),
      data: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        topic: '',
        text: '',
      },
      isSuccessful: false,
    }
  },
  validations: {
    data: {
      first_name: { required },
      last_name: { required },
      email: { required, email },
      topic: { required },
      text: { required },
    },
  },
  methods: {
    send() {
      this.$v.$touch()

      if (this.$v.$error) {
        return
      }

      const self = this

      this.$api.contactUs(this.data).then(() => {
        self.isSuccessful = true
      })
    },
  },
}
</script>

<style lang="scss">
/*TODO: need to move it to scss files*/
.feedback {
  .editor__content {
    height: 200px;
    padding: 15px;
    box-sizing: border-box;
    border: 2px solid #8b8b8b;
    color: #363636;
    font-size: 18px;
    resize: none;
    outline: none;
    transition: all 0.2s;
    p {
      margin: 0;
    }
    &:focus,
    &:hover {
      border-color: #363636;
    }
    &.has-error {
      border-color: #ce081b !important;
      color: #ce081b !important;
    }
    .ProseMirror {
      text-align: left;
      height: 100%;
      &:focus {
        outline: none;
      }
    }
  }
}
.has-error {
  border-color: #ce081b;
  color: #ce081b;
}
</style>

<template>
  <div class="ib-wraper">
    <div class="row">
      <div class="col col-33">
        <h2 class="ib-h2">{{ $t('ib.passport') }}</h2>
        <div class="ib-paddle-div">
          <image-upload
            v-if="!user.passport"
            v-model="media.passport"
            button-class="purchase__add-image purchase__add-image--passport"
            :button-text="$t('auction_purchase.RIB_label2')"
            accept="images/*"
            @input="addPassport"
            @clear="clear"
          />
          <div
            class="image-box"
            v-else-if="user.passport && !user.profile.is_confirmed"
          >
            <div class="purchase__add-image purchase__add-image--verified">
              <div class="ib-paddle-text">{{ $t('ib.validation') }}</div>
            </div>
          </div>
          <div class="image-box" v-else>
            <div class="purchase__add-image purchase__add-image--verified">
              <div class="ib-paddle-text">{{ $t('ib.valid') }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col col-33">
        <h2 class="ib-h2">{{ $t('ib.iban') }}</h2>
        <div class="ib-paddle-div">
          <image-upload
            v-if="!user.rib"
            v-model="media.rib"
            button-class="purchase__add-image purchase__add-image--order"
            :button-text="$t('auction_purchase.RIB_label2')"
            accept="images/*"
            @input="addRib"
            @clear="clear"
          />
          <div
            class="image-box"
            v-else-if="user.rib && !user.profile.is_confirmed"
          >
            <div class="purchase__add-image purchase__add-image--verified">
              <div class="ib-paddle-text">{{ $t('ib.validation') }}</div>
            </div>
          </div>
          <div class="image-box" v-else>
            <div class="purchase__add-image purchase__add-image--verified">
              <div class="ib-paddle-text">{{ $t('ib.valid') }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col col-33">
        <h2 class="ib-h2">{{ $t('ib.paddle') }}</h2>
        <div class="ib-paddle-div">
          <div class="image-box">
            <div class="purchase__add-image purchase__add-image--paddle">
              <div class="ib-paddle-text">W{{ user.id }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="ib-down">
      <h2 class="ib-h2">
        {{ $t('ib.limit') }} {{ user.profile.bid_limit }} &#x20AC;
      </h2>

      <button
        class="button ib-button-limit-demand"
        @click="conntactAskBid()"
        :disabled="request_sent"
      >
        {{ $t('ib.ask') }}
      </button>
      <div>
        <p class="success-message" v-if="request_sent">
          {{ message }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
// When user click on image-upload component, it send that image or file to the server. Server returns bunch of things in response, inlcluding id of that file in media table.
// Than that media id we are sending back to server to cennect that id to the id of user in user_media table.
import ImageUpload from '../../Inputs/ImageUpload'

export default {
  name: 'IDBanPaddle',
  components: { ImageUpload },
  data() {
    return {
      isSuccess: false,
      pass_rec: false,
      rib_rec: false,
      message: '',
      passport_new_id: '',
      rib_new_id: '',
      request_sent: false,
      isPassword: false,
      isRib: false,
      formData: {},
      media: {
        passport: {},
        rib: {},
      },
      user: {
        login: '-',
        password: '*********',
        confirm: '*********',
        first_name: '-',
        last_name: '-',
        id: '',
        profile: {
          qualification: 'professional',
          gender: 'male',
          address: '-',
          post_code: '-',
          city: '-',
          country: '-',
          phone: '-',
          bid_limit: 20000,
          is_confirmed: false,
        },
        ceiling: '-',
        passport: {
          media: {},
        },
        rib: {
          media: {},
        },
        passport_media: '',
        rib_media: '',
      },
      originalData: {},
    }
  },
  created() {
    this.getMedia()
  },
  methods: {
    getMedia() {
      this.$api.getProfile().then((response) => {
        const user = response.data
        this.user = user

        this.media.passport = user.passport ? user.passport.media : {}
        this.media.rib = user.rib ? user.rib.media : {}
      })
    },
    clear(response) {
      this.$api.deleteMedia(response).then(() => {
        this.getMedia()
      })
    },
    saveMedia() {
      if (this.pass_rec || this.rib_rec) {
        const self = this

        this.$api.updateUser(this.formData).then(() => {
          self.isSuccess = true
          self.$emit('success')

          this.getMedia()
        })
      }
    },
    conntactAskBid() {
      if (this.request_sent) {
        this.message = 'You have already requested higher bid limit!'
      } else {
        this.request_sent = true
        this.$api
          .askHigherBid({
            text: 'Please contact me, as I want to bid higher than 20000 euros',
          })
          .then(() => {
            this.message = this.$t('ib.increase_success')
          })
      }
    },
    addPassport(response) {
      this.passport_new_id = response.id
      this.pass_rec = true
      this.activateSend()
    },
    addRib(response) {
      this.rib_new_id = response.id
      this.rib_rec = true
      this.activateSend()
    },
    activateSend() {
      if (this.pass_rec === true) {
        this.formData.passport_id = this.passport_new_id
      }

      if (this.rib_rec === true) {
        this.formData.rib_id = this.rib_new_id
      }

      if (this.pass_rec === true || this.rib_rec === true) {
        this.saveMedia()
      }
    },
  },
}
</script>

<style scoped>
.success-message {
  margin-top: 1em;
  color: green;
}

.ib-wraper {
  width: 100%;
  margin: 0 auto;
}

@media screen and (max-width: 1265px) {
  .ib-h2 {
    font-size: 2.5rem;
  }
}

.ib-down {
  width: 100%;
  margin-top: 70px;
}

.ib-button-limit-demand {
  width: auto;
  border: 2px solid black;
  background-color: white;
  font-size: 18px;
  transition: all 0.2s;
}

.ib-button-limit-demand:hover {
  color: white;
  background-color: black;
  cursor: pointer;
}

.ib-button-limit-demand:disabled {
  background-color: #737373;
  border-color: #737373;
  color: rgba(255, 255, 255, 0.6);
  cursor: auto;
}

.ib-button-small {
  width: 100px;
  border: 2px solid black;
  background-color: white;
  font-size: 18px;
  transition: all 0.2s;
}

.ib-button-small:hover {
  color: white;
  background-color: black;
  cursor: pointer;
}

.ib-row {
  display: flex;
}

.purchase__add-image--paddle .ib-paddle-text {
  margin-top: -90px;
  color: #fff;
}

.ib-paddle-text {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 46px;
  text-align: center;
  color: #000000;
  margin-top: 7em;
}

.ib-center {
  display: flex;
  justify-content: center;
}

.ib-isPresent {
  border: 2px solid green;
}
</style>

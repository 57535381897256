<template>
  <article class="vente">
    <div class="row">
      <div class="col col-25">
        <div class="image-box">
          <img
            :src="getResizedImageUrl(bid.lot.product.media[0].link, '625x500')"
            alt=""
          />
          <span class="vente__label vente__label--valid" v-if="false">{{
            $t('purchase.label.valid')
          }}</span>
        </div>
      </div>
      <div class="col col-75">
        <div class="vente__wrap">
          <p class="vente__title">{{ lotNumber(bid.lot.lot_number) }}</p>
          <p class="vente__subtitle">
            {{
              bid.lot.product.translation.name
                ? bid.lot.product.translation.name
                : $t('purchase.no.info')
            }}
          </p>
          <div
            class="vente__description"
            v-html="
              bid.lot.product.translation.description
                ? bid.lot.product.translation.description
                : $t('purchase.no.description')
            "
          ></div>
          <div class="vente__bottom">
            <div>
              <p class="vente__numbers vente__numbers--gray">
                {{ $t('my_purchase.estimate') }} :
                {{ bid.lot.product.min_estimation }} /
                {{ bid.lot.product.max_estimation }} €
              </p>
              <p class="vente__numbers">
                {{ $t('my_purchase.adjudication') }} : {{ bid.price }} €
              </p>
            </div>
            <a class="vente__button">
              <svg
                width="16"
                height="13"
                viewBox="0 0 16 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.3825 8.75H15.0075V6.21875C15.0075 5.9375 14.8668 5.63281 14.6559 5.42188L12.3356 3.10156C12.1247 2.89062 11.82 2.75 11.5387 2.75H10.5075V1.625C10.5075 1.01562 9.99184 0.5 9.38247 0.5H3.38247C2.74965 0.5 2.25747 1.01562 2.25747 1.625V2.75H0.944967C0.82778 2.75 0.757467 2.84375 0.757467 2.9375V3.3125C0.757467 3.42969 0.82778 3.5 0.944967 3.5H7.31997C7.41372 3.5 7.50747 3.59375 7.50747 3.6875V4.0625C7.50747 4.17969 7.41372 4.25 7.31997 4.25H1.69497C1.57778 4.25 1.50747 4.34375 1.50747 4.4375V4.8125C1.50747 4.92969 1.57778 5 1.69497 5H6.56997C6.66372 5 6.75747 5.09375 6.75747 5.1875V5.5625C6.75747 5.67969 6.66372 5.75 6.56997 5.75H0.944967C0.82778 5.75 0.757467 5.84375 0.757467 5.9375V6.3125C0.757467 6.42969 0.82778 6.5 0.944967 6.5H5.81997C5.91372 6.5 6.00747 6.59375 6.00747 6.6875V7.0625C6.00747 7.17969 5.91372 7.25 5.81997 7.25H2.25747V10.25C2.25747 11.4922 3.26528 12.5 4.50747 12.5C5.74965 12.5 6.75747 11.4922 6.75747 10.25H9.75747C9.75747 11.4922 10.7653 12.5 12.0075 12.5C13.2497 12.5 14.2575 11.4922 14.2575 10.25H15.3825C15.57 10.25 15.7575 10.0859 15.7575 9.875V9.125C15.7575 8.9375 15.57 8.75 15.3825 8.75ZM4.50747 11.375C3.87465 11.375 3.38247 10.8828 3.38247 10.25C3.38247 9.64062 3.87465 9.125 4.50747 9.125C5.11684 9.125 5.63247 9.64062 5.63247 10.25C5.63247 10.8828 5.11684 11.375 4.50747 11.375ZM12.0075 11.375C11.3747 11.375 10.8825 10.8828 10.8825 10.25C10.8825 9.64062 11.3747 9.125 12.0075 9.125C12.6168 9.125 13.1325 9.64062 13.1325 10.25C13.1325 10.8828 12.6168 11.375 12.0075 11.375ZM13.8825 6.5H10.5075V3.875H11.5387L13.8825 6.21875V6.5Z"
                  fill="#363636"
                />
              </svg>
              {{ $t('my_purchase.transport') }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import { get, trim } from 'lodash'

export default {
  name: 'purchase-item',
  props: ['bid'],
  methods: {
    getEmployeeName() {
      const firstName = get(
        this.bid,
        'product.lots.0.auction.employees.0.first_name',
        ''
      )
      const lastName = get(
        this.bid,
        'product.lots.0.auction.employees.0.last_name',
        ''
      )

      const fullName = firstName + ' ' + lastName
      return trim(fullName) || this.$t('purchase.no.info')
    },
    lotNumber(number) {
      return number < 10 ? '0' + number : number
    },
  },
}
</script>

<style lang="scss" scoped>
.vente {
  background: rgba(245, 245, 254, 0.25);
  padding: 36px 20px 25px 30px;

  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    color: #8b8b8b;
    margin: 0;
  }

  &__subtitle {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #000000;
    margin-bottom: 4px;
  }

  &__description {
    font-size: 18px;
    line-height: 26px;
    color: #000000;
    margin-bottom: 8px;
  }

  &__numbers {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #000000;

    &--gray {
      color: #8b8b8b;
      margin-bottom: 7px;
    }
  }

  &__bottom {
    display: grid;
    grid-template-columns: calc(100% - 134px) 134px;

    @media (max-width: 1024px) {
      grid-template-columns: 100%;
    }
  }

  &__button {
    width: 134px;
    height: 31px;
    border: 2px solid #8b8b8b;
    box-sizing: border-box;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #363636;
    transition: all 0.2s;

    svg {
      margin-right: 4px;

      path {
        transition: all 0.2s;
      }
    }

    &:hover {
      color: #fff;
      background: #363636;
      border-color: #363636;

      & svg path {
        fill: #fff;
      }
    }
  }
}
</style>

import { last, get, sortBy } from 'lodash'

export default {
  methods: {
    submitBid(bidId) {
      const bid = this.bids.find((bid) => bid.id === bidId)
      if (bid) {
        bid.is_submitted = true
      }
    },
  },
  computed: {
    bids() {
      return this.auction.online_bids.filter(
        (bid) => bid.lot_id === this.auction.data.live_streaming.current_lot_id
      )
    },
    bestBid() {
      const orderedUserBids = sortBy(this.bids, 'created_at')

      return last(orderedUserBids)
    },
    bestBidAmount() {
      return get(this.bestBid, 'price', 0)
    },
    bestUserBid() {
      const filteredUserBids = this.bids.filter(
        (bid) => bid.user_id && bid.is_submitted && !bid.mockBid
      )
      const orderedUserBids = sortBy(filteredUserBids, 'created_at')

      return last(orderedUserBids)
    },
    bestUserBidId() {
      const bid = this.bestUserBid

      return bid ? bid.id : undefined
    },
    bestUserBidAmount() {
      return get(this.bestUserBid, 'price', 0)
    },
    bestFloorBid() {
      const filteredUserBids = this.bids.filter(
        (bid) => bid.is_submitted && !bid.mockBid && !bid.user_id
      )
      const orderedUserBids = sortBy(filteredUserBids, 'created_at')

      return last(orderedUserBids)
    },
    bestFloorBidAmount() {
      return get(this.bestFloorBid, 'price', 0)
    },
    isRoomBid() {
      return (
        this.bestFloorBid &&
        this.bestFloorBid.price ===
          this.auction.data.live_streaming.current_price
      )
    },
  },
}

<template>
  <div>
    <div class="container">
      <div v-if="!isLoaded" class="loader">
        <img alt="Loader" src="/img/loader.svg" />
      </div>

      <promoted-auctions
        :is-loaded="isLoaded"
        :main-page-items="promotedAuctions"
      ></promoted-auctions>

      <a class="scroll-down" @click="scrollTo">
        <img src="~@/assets/img/icon-4.svg" alt="scrollTo" />
      </a>
    </div>

    <promoted-products
      :is-loaded="isLoaded"
      :products="promotedProducts"
      @update="reloadMainPageItems"
    ></promoted-products>

    <announced-products
      :is-loaded="isLoaded"
      :products="announcedProducts"
    ></announced-products>

    <home-page-icons></home-page-icons>

    <promoted-news :is-loaded="isLoaded" :news="promotedNews"></promoted-news>
  </div>
</template>

<script>
import { orderBy, isEmpty } from 'lodash'
import moment from 'moment'
import PromotedAuctions from '@/components/auctions/common/PromotedAuctions'
import PromotedProducts from '@/components/auctions/common/PromotedProducts'
import AnnouncedProducts from '@/components/auctions/common/AnnouncedProducts'
import HomePageIcons from '@/components/auctions/common/HomePageIcons'
import PromotedNews from '@/components/auctions/common/PromotedNews'

export default {
  name: 'home-page',
  components: {
    PromotedNews,
    HomePageIcons,
    AnnouncedProducts,
    PromotedProducts,
    PromotedAuctions,
  },
  metaInfo() {
    return {
      title: this.$t('titles.home'),
      meta: [{ name: 'description', content: this.$t('meta.home') }],
    }
  },
  data() {
    return {
      isLoaded: false,
      promotedAuctions: [],
      promotedProducts: [],
      announcedProducts: [],
      promotedNews: [],
    }
  },
  created: function () {
    this.loadMainPageItems()
  },
  methods: {
    loadMainPageItems() {
      this.$api.getMainPageItems().then((response) => {
        response.data.auctions.map((auction) => {
          const lotsRedirect =
            '/' + this.$i18n.locale + '/auctions/' + auction.slug + '#lots-list'
          const drouotAuctionUrl =
            auction.drouot_auction && !isEmpty(auction.drouot_auction_url)
              ? auction.drouot_auction_url
              : null

          const mainPageItem = {
            redirect_to: {
              name: 'app.auctions.details',
              params: {
                slug: auction.slug,
              },
            },
            drouot_auction_url: drouotAuctionUrl,
            date: auction.started_at,
            image: this.getResizedImageUrl(
              this.getCoverImage(auction)[0].link,
              'original'
            ),
            alt: auction.media[0].name,
            title: auction.translation.short_title,
            description: auction.translation.description,
            lots_redirect: auction.lots_count ? lotsRedirect : null,
          }

          this.promotedAuctions.push(mainPageItem)
        })
        response.data.news.map((news) => {
          const newsItem = {
            redirect_to: {
              name: 'app.news.details',
              params: {
                id: news.slug,
              },
            },
            date: news.publication_date,
            image: this.getResizedImageUrl(news.media.link, 'original'),
            alt: news.media.name,
            title: news.translation.title,
            description: news.description,
          }

          this.promotedNews.push(newsItem)
        })
        response.data.products.map((product) => {
          if (!product.auctions[0]) return
          if (
            moment(product.auctions[0].started_at).isBefore() &&
            !product.auctions[0].finished
          ) {
            if (product.media.length) this.promotedProducts.push(product)
          } else if (moment(product.auctions[0].started_at).isAfter()) {
            this.announcedProducts.push(product)
          }
        })

        if (this.promotedAuctions.length === 3) {
          this.$store.state.fullscreen = true
        }

        this.isLoaded = true

        this.promotedNews = orderBy(this.promotedNews, 'date', 'desc')
      })
    },
    reloadMainPageItems() {
      this.promotedProducts = []
      this.announcedProducts = []
      this.promotedNews = []
      this.promotedAuctions = []
      this.loadMainPageItems()
    },
    scrollTo() {
      if (document.getElementById('coming')) {
        const el = document.getElementById('coming')
        el.scrollIntoView({
          behavior: 'smooth',
        })
      } else {
        const el = document.getElementById('promoted')
        el.scrollIntoView({
          behavior: 'smooth',
        })
      }
    },
    getCoverImage(auction) {
      const coverImages = auction.media.filter(
        (mediaItem) => mediaItem.type === 'cover'
      )

      if (coverImages.length) {
        return coverImages
      }

      return auction.media
    },
  },
}
</script>

<style scoped>
.loader {
  text-align: center;
  top: 200px;
  width: 50px;
  min-height: 500px;
  margin: auto;
  position: relative;
}

article.lot__item {
  margin-bottom: 0;
}

.lot__item:focus {
  outline: none;
}

.scroll-down {
  height: 25px;
  padding-top: 10px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  transition: transform 0.5s;
}

.scroll-down img {
  transition: transform 0.2s;
}

.scroll-down:hover img {
  transform: translateY(4px);
}
</style>

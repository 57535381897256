<template>
  <div>
    <div v-if="isLoaded && !biddingInPast" class="subscribe">
      <div class="subscribe__wrap">
        <p
          v-if="subscription && !isConfirmed"
          class="subscribe__text subscribe__text--warning"
        >
          {{ $t('live.product_confirm_warning') }}
        </p>

        <div v-if="!isSubscribed">
          <h2 class="subscribe__subtitle" v-if="auction.bid_online">
            {{ $t('live.auction_must_registered_title') }}
          </h2>
          <h2 class="subscribe__subtitle" v-if="auction.biddable_online">
            {{ $t('live.online_auction_must_registered_title') }}
          </h2>
          <p class="subscribe__text" v-if="auction.bid_online">
            {{ $t('live.auction_must_registered') }}
          </p>
          <p class="subscribe__text" v-if="auction.biddable_online">
            {{ $t('live.online_auction_must_registered') }}
          </p>

          <form class="form">
            <div class="form__row">
              <input
                type="checkbox"
                id="checkbox"
                class="form__checkbox"
                v-model="agreement"
              />
              <label for="checkbox" class="form__label">{{
                $t('validation.subscription_agreement')
              }}</label>
            </div>
          </form>

          <div class="subscribe-container">
            <button
              class="button subscribe__button"
              @click="subscribe()"
              v-if="auction.bid_online"
              :disabled="!agreement"
            >
              {{ $t('live.subscribe') }}
            </button>
            <button
              class="button subscribe__button"
              @click="subscribe()"
              v-if="auction.biddable_online"
              :disabled="!agreement"
            >
              {{ $t('live.subscribe_online') }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="loader" v-if="!isLoaded">
      <img src="/img/loader.svg" alt="" />
    </div>
  </div>
</template>

<script>
import BidsMixin from '@/components/products/mixins/bids.mixin'
import PusherMixin from '@/mixins/pusher.mixin'

export default {
  name: 'product-notification',
  props: ['product', 'auction', 'user', 'isLoaded'],
  mixins: [BidsMixin, PusherMixin],
  data() {
    return {
      hasError: false,
      agreement: false,
    }
  },
  methods: {
    subscribe() {
      const self = this

      this.$api.subscribeOnLiveStream(this.auction.id).then(() => {
        self.$emit('subscribed')
      })
    },
  },
}
</script>

<style scoped>
.subscribe-container {
  margin-top: 30px;
}

.form__label {
  font-size: 0.8em;
  font-weight: bold;
}
.form {
  margin-top: 2em;
}
</style>

<template>
  <div class="rating__form-section">
    <h2>{{ $t('estimation.images') }}</h2>
    <div class="row">
      <div class="col col-25 relative" v-for="(media, index) in mediaList">
        <div class="image-box">
          <img
            :src="getThumbnail(media.link)"
            :srcset="getSrcSet(media.link)"
            alt=""
          />
        </div>
        <img
          class="close-button"
          src="/img/delete.png"
          @click="onDelete(index)"
          alt=""
        />
      </div>
      <image-upload class="col col-25" @input="add(arguments[0])" />
    </div>
    <div class="has-error">
      <label v-if="hasError">
        {{ $t('estimation.need_to_add_media') }}
      </label>
    </div>
  </div>
</template>

<script>
import ImageUpload from './ImageUpload'
import { clone } from 'lodash'

export default {
  components: { ImageUpload },
  name: 'images-list',
  props: ['value', 'hasError'],
  data() {
    return {
      mediaList: [],
    }
  },
  methods: {
    add(media) {
      this.mediaList.push(media)

      const mediasIds = this.mediaList.map((media) => {
        return media.id
      })

      this.$emit('input', clone(mediasIds))
    },
    onDelete(index) {
      const self = this

      this.$api.deleteMedia(this.mediaList[index].id).then(() => {
        self.mediaList.splice(index, 1)
      })
    },
  },
}
</script>

<style scoped>
.relative {
  position: relative;
}

.close-button {
  width: 20px;
  height: 20px;
  top: 5px;
  right: 30px;
  position: absolute;
  display: none;
  opacity: 0.5;
  cursor: pointer;
  z-index: 1;
}

.close-button:hover {
  opacity: 1;
}

.close-button:active {
  opacity: 0.8;
}

.relative:hover > .close-button {
  display: block;
}

div.has-error > label {
  display: block;
  text-align: center;
}
</style>

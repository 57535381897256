<template>
  <div>
    <div
      class="toggle-header-button toggle-header-button--show"
      v-if="$store.state.hide_header"
      @click="$store.commit('toggleHeader')"
    >
      Show header
    </div>
    <div
      class="toggle-header-button toggle-header-button--hide"
      v-if="!$store.state.hide_header"
      @click="$store.commit('toggleHeader')"
    >
      Hide header
    </div>
    <div class="mock-container" v-if="authError">
      <label>{{ $t('live.no_permission') }}</label>
    </div>
    <div class="auction" v-if="!authError">
      <online-auction-header :auction="auction" :user="user" />
      <div class="row">
        <div class="col col-50">
          <admin-online-auction-managing
            :auction="auction"
            ref="auctionManaging"
            @changed="auction = arguments[0]"
            @reopenned="reopen(arguments[0])"
          />
        </div>
        <div class="col col-50">
          <section class="auction__section">
            <bids-list :auction="auction" :user="user" />
          </section>
          <section
            class="auction__section"
            v-if="auction.data.live_streaming.current_lot"
          >
            <current-lot
              class="current-lot-container--admin"
              :auction="auction"
            />
          </section>
        </div>
      </div>
      <live-streaming-lots-list
        :auction="auction"
        v-if="auction.lots.length"
        :enable-search="false"
        @lot-selected="$refs.auctionManaging.skipTo(arguments[0])"
        @reopenned="reopen(arguments[0])"
      />
    </div>
  </div>
</template>

<script>
import { isEmpty } from 'lodash'

import BidsMixin from './mixins/bids.mixin'
import AdminOnlineAuctionManaging from './common/AdminOnlineAuctionManaging'
import BidsList from './common/BidsList'
import LiveStreamingLotsList from './common/LiveStreamingLotsList'
import CurrentLot from './common/CurrentLot'
import OnlineAuctionHeader from './common/OnlineAuctionHeader'
import PusherMixin from './../../mixins/pusher.mixin'

export default {
  components: {
    AdminOnlineAuctionManaging,
    BidsList,
    CurrentLot,
    LiveStreamingLotsList,
    OnlineAuctionHeader,
  },
  name: 'admin-auction',
  mixins: [BidsMixin, PusherMixin],
  metaInfo() {
    return {
      title: 'Live Auction',
    }
  },
  data() {
    return {
      authError: null,
      user: {
        id: null,
        role_id: null,
      },
      auction: {
        id: null,
        translation: {
          title: '',
        },
        lots: [],
        online_bids: [],
        data: {
          live_streaming: {
            current_lot: null,
            current_lot_id: null,
            on_air: false,
            current_price: null,
            minimum_rate_increase: null,
            watchers: 0,
          },
        },
      },
      currentLot: null,
    }
  },
  created() {
    const self = this

    this.$api.getProfile().then((response) => {
      self.user = response.data
      self.authError = self.user.role_id !== 1
    })

    this.loadAuction().then(() => {
      self.$pusher
        .subscribe('auctions.' + self.auction.id)
        .bind('bids.new', () => {
          self.loadAuction()
        })
        .bind('watchers.update', (data) => {
          self.auction.data.live_streaming.watchers = data.count
        })
        .bind('changed_price', (data) => {
          if (data.submittedBid) {
            self.submitBid(data.submittedBid.id)
          }
          self.loadAuction()
        })
        .bind('bids.cancel', () => {
          self.loadAuction()
        })
        .bind('fair_warning', () => {
          self.loadAuction()
        })
    })
  },
  methods: {
    loadAuction() {
      const self = this

      return this.$api.getAuction(this.$route.params.slug).then((response) => {
        self.auction = response.data

        if (isEmpty(self.auction)) {
          self.auction = {
            lots: [],
          }
        }

        self.isLoaded = true
      })
    },
    reopen(lot) {
      const self = this

      this.$api
        .reopenLot(lot.id)
        .then((response) => {
          self.auction = response.data
        })
        .catch((error) => {
          alert(error.response.data.error)
        })
    },
  },
}
</script>

<style scoped>
.current-lot-container--admin {
  margin-top: -1rem;
}
</style>

<template>
  <div>
    <section class="ventes" v-if="isLoaded">
      <h1 class="ventes__title">{{ $t('calendar.title') }}</h1>
      <div class="calendar__descr">
        {{ $t('calendar.description') }}
        <br />5 Boulevard Ney, 75018 Paris
      </div>
      <div class="calendar__wrap">
        <date-picker
          :inline="true"
          :disabled="{ customPredictor: disabledDays }"
          :language="$i18n.locale"
          :wrapper-class="'calendar__body'"
          :calendar-class="'calendar'"
          @input="saveDate(arguments[0])"
        >
        </date-picker>
        <div class="calendar__info">
          <h3 class="calendar__info-title">{{ $t('calendar.interval') }}</h3>
          <button
            class="calendar__info-box"
            :class="{
              'calendar__info-box--active': params.from === '08:00:00',
            }"
            @click="selectTime('08:00:00', '13:00:00')"
          >
            <div class="calendar__info-box-title">
              {{ $t('calendar.between') }}
            </div>
            <div class="calendar__info-box-time">
              {{ $t('calendar.before_dinner') }}
            </div>
          </button>
          <span class="calendar__info-text">{{ $t('calendar.or') }}</span>
          <button
            class="calendar__info-box"
            :class="{
              'calendar__info-box--active': params.from === '14:00:00',
            }"
            @click="selectTime('14:00:00', '18:00:00')"
          >
            <div class="calendar__info-box-title">
              {{ $t('calendar.between') }}
            </div>
            <div class="calendar__info-box-time">
              {{ $t('calendar.after_dinner') }}
            </div>
          </button>
        </div>
        <div class="calendar__footer">
          <button class="button calendar__button" @click="submit()">
            {{ $t('calendar.validate_button') }}
          </button>
        </div>
        <div class="calendar__footer" v-if="$v.$error">
          <label class="has-error">{{ $t('calendar.error') }}</label>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import DatePicker from 'vuejs-datepicker'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'calendar',
  components: { DatePicker },
  mixins: [validationMixin],
  metaInfo() {
    return {
      title: this.$t('titles.calendar'),
      meta: [{ name: 'description', content: this.$t('meta.calendar') }],
    }
  },
  data() {
    return {
      isLoaded: false,
      params: {
        products: [
          {
            id: this.$route.query.product_id,
            count: 1,
          },
        ],
        date: null,
        from: '08:00:00',
        to: '13:00:00',
      },
    }
  },
  validations: {
    params: {
      date: { required },
    },
  },
  created: function () {
    const self = this

    this.$api
      .checkProductInCart(this.$route.query.product_id)
      .then(() => {
        self.isLoaded = true
      })
      .catch(() => {
        self.$router.push({
          name: 'app.home',
        })
      })
  },
  methods: {
    disabledDays: function (date) {
      return (
        this.moment(date).isoWeekday() >= 6 ||
        this.moment(date).isBefore(this.moment())
      )
    },
    submit() {
      this.$v.$touch()

      if (this.$v.$error) {
        return
      }

      this.$api.createOrder(this.params).then((response) => {
        this.$router.push({
          name: 'app.orders.payment',
          params: {
            orderId: response.data.id,
          },
        })
      })
    },
    saveDate(date) {
      this.params.date = this.moment(date).format('YYYY-MM-DD')

      return date
    },
    selectTime(from, to) {
      this.params.from = from
      this.params.to = to
    },
  },
}
</script>

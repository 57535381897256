<template>
  <section class="specialites">
    <img
      class="page-main__header-img"
      src="/img/23-1.jpg"
      srcset="/img/23-1@2x.jpg 2x, /img/23-1@3x.jpg 3x"
      alt=""
    />
    <breadcrumbs :steps="getBreadcrumbs()" context="context"></breadcrumbs>
    <article class="specialites__info">
      <div class="row">
        <div class="col col-50">
          <h1 class="specialites__title">{{ category.translation.title }}</h1>
        </div>
        <div class="col col-50">
          <p
            class="propos__info-text"
            v-html="category.translation.description"
          ></p>
        </div>
      </div>
    </article>
    <div class="specialites__header">
      <h2 class="specialites__subtitle">
        {{ $t('navigation.future_auctions') }}
      </h2>
      <router-link
        :to="{ name: 'app.future_auctions' }"
        class="specialites__more-link link"
        >{{ $t('show_more') }}</router-link
      >
    </div>
    <div class="specialites__list">
      <div class="row">
        <auction-item
          v-for="auction in category.future_auctions"
          :auction="auction"
          :key="auction.id"
          class="col col-25"
        >
        </auction-item>
      </div>
    </div>
    <div class="specialites__header">
      <h2 class="specialites__subtitle">{{ $t('categories.specialists') }}</h2>
    </div>
    <div class="specialites__list">
      <div class="row">
        <specialist-item
          v-for="specialist in users"
          :key="specialist.id"
          :specialist="specialist"
          class="col col-20"
        >
        </specialist-item>
      </div>
    </div>
  </section>
</template>

<script>
import AuctionItem from './Auction'
import Breadcrumbs from '../layout/Breadcrumbs'
import SpecialistItem from './common/SpecialistItem'
import _ from 'lodash'

export default {
  components: {
    SpecialistItem,
    Breadcrumbs,
    AuctionItem,
  },
  name: 'category-details',
  metaInfo() {
    return {
      title: this.category.translation.title,
    }
  },
  data() {
    return {
      category: {
        translation: {
          title: '',
          description: '',
        },
        users: [],
      },
    }
  },
  computed: {
    users() {
      return _.orderBy(this.category.users, ['profile.position'], ['asc'])
    },
  },
  created: function () {
    const self = this

    this.$api.getCategory(this.$route.params.id).then((response) => {
      self.category = response.data
      const onlineAuctions = self.category.future_auctions.filter(
        (auction) =>
          auction.biddable_online &&
          this.moment(auction.started_at).isBefore() &&
          this.moment(auction.finished_at).isAfter()
      )
      const liveAuctions = self.category.future_auctions.filter(
        (auction) => auction.bid_online
      )
      self.category.future_auctions = _.orderBy(
        [...liveAuctions, ...onlineAuctions],
        'started_at',
        'asc'
      )
    })
  },
  methods: {
    getBreadcrumbs() {
      return [
        {
          to: {
            name: 'app.categories',
          },
          title: this.$t('navigation.categories'),
        },
        {
          to: {
            name: 'app.categories.details',
            params: {
              slug: this.category.id,
            },
          },
          title: this.category.translation.title,
        },
      ]
    },
  },
}
</script>

<template>
  <div
    class="back-to-top"
    :class="{ 'back-to-top--visible': visible }"
    @click="backToTop"
  >
    Back to top
  </div>
</template>

<script>
export default {
  name: 'back-to-top',
  props: {
    visibleOffset: {
      type: [String, Number],
      default: 500,
    },
  },
  data() {
    return {
      visible: false,
    }
  },
  created: function () {
    window.onscroll = () => {
      this.visible = window.pageYOffset > parseInt(this.visibleOffset)
    }
    window.smoothscroll = () => {
      const currentScroll =
        document.documentElement.scrollTop || document.body.scrollTop
      if (currentScroll > 0) {
        window.requestAnimationFrame(window.smoothscroll)
        window.scrollTo(0, Math.floor(currentScroll - currentScroll / 5))
      }
    }
  },
  methods: {
    backToTop() {
      window.smoothscroll()
    },
  },
}
</script>

<template>
  <div class="screen-wrapper">
    <div class="image-outer-wrap">
      <div class="image-inner-wrap">
        <img
          :src="getThumbnail(currentLot.product.media[0].link)"
          width="100%"
          class="lot-image"
          alt=""
        />
      </div>
    </div>

    <vue-fixer
      class="currencies-outer-wrap"
      ref="estimateRates"
      :api-key="apiKey"
      :params="`&base=${baseCurrency}&symbols=${currencies.join(',')}`"
      @input="(value) => saveExchangeRates(value)"
      @error="getExchangeRates()"
    >
      <div slot-scope="props">
        <div v-show="props.fixer.success" class="currencies-inner-wrap">
          <p class="currency-wrap">
            <span class="currency">LOT: {{ currentLot.lot_number }}</span>
          </p>
          <p class="currency-wrap">
            <span class="currency">EUR: </span>
            <span class="value">{{ currentPrice | moneyFormat }}</span>
          </p>
          <p
            class="currency-wrap"
            v-for="(rate, label) in props.fixer.rates"
            :key="rate"
          >
            <span class="currency">{{ label }}: </span>
            <span class="value">{{ (rate * currentPrice) | moneyFormat }}</span>
          </p>
        </div>
        <p class="estimate">Conversion Estimate</p>
      </div>
    </vue-fixer>
  </div>
</template>

<script>
import { isEmpty } from 'lodash'
import BidsMixin from './mixins/bids.mixin'
import PusherMixin from './../../mixins/pusher.mixin'
import vueFixer from 'vue-fixer'
import moment from 'moment'

export default {
  name: 'AuctionSlide',
  mixins: [BidsMixin, PusherMixin],
  components: {
    vueFixer,
  },
  data() {
    return {
      apiKey: '73aea512a77233e6eefc44051bed27b3',
      baseCurrency: 'EUR',
      currencies: ['USD', 'GBP', 'CHF', 'JPY', 'HKD', 'RUN', 'CNY'],
      currentLot: {
        product: {
          media: [
            {
              link: '',
            },
          ],
        },
      },
      auction: {
        translation: {
          title: '',
        },
        data: {
          live_streaming: {
            on_air: false,
            youtube_stream_id: null,
            current_lot: null,
            minimum_rate_increase: null,
            current_price: null,
          },
        },
        lots: [],
        online_bids: [],
      },
    }
  },
  mounted() {
    const self = this

    this.$api.getProfile().then((response) => {
      self.user = response.data
      self.authError = self.user.role_id !== 1
    })
    this.loadAuction().then(() => {
      self.$pusher
        .subscribe('auctions.' + self.auction.id)
        .bind('start', () => {
          self.loadAuction()
        })
        .bind('stop', () => {
          self.loadAuction()
        })
        .bind('bids.new', (bid) => self.auction.online_bids.unshift(bid))
        .bind('bids.cancel', () => {
          self.loadAuction()
        })
        .bind('changed_price', (data) => {
          self.auction.data.live_streaming.current_price = data.price

          if (data.submittedBid) {
            self.submitBid(data.submittedBid.id)
          }
        })
        .bind('changed_minimum_rate_increase', (data) => {
          self.auction.data.live_streaming.minimum_rate_increase = data.value
        })
        .bind('changed_youtube_stream', (data) => {
          this.auction.data.live_streaming.youtube_stream_id = data.stream_id
        })
        .bind('lots.sold', (data) => {
          if (data.next_lot) {
            self.loadAuction()
          } else {
            self.stopAuction()
          }
        })
        .bind('lots.skip', () => {
          self.loadAuction()
        })
        .bind('fair_warning', () => {
          self.loadAuction()
        })
    })
  },
  methods: {
    saveExchangeRates(value) {
      localStorage.setItem('exchangeRates', JSON.stringify(value))
    },
    getExchangeRates() {
      this.$refs.estimateRates.fixerData = JSON.parse(
        localStorage.getItem('exchangeRates')
      )
    },
    loadAuction() {
      const self = this

      self.isBidButtonBlocked = true
      return this.$api.getAuction(this.$route.params.slug).then((response) => {
        self.auction = response.data
        this.currentLot = this.auction.lots.find(
          (lot) => lot.id === this.auction.data.live_streaming.current_lot_id
        )
        if (isEmpty(self.auction)) {
          self.auction = {
            lots: [],
          }
        }
        self.isLoaded = true
        self.isBidButtonBlocked = false
      })
    },
    loadUser() {
      const self = this

      return this.$api.getProfile().then((response) => {
        self.user = response.data
        self.user.rib = self.user.rib === null ? { media: null } : self.user.rib
        self.user.passport =
          self.user.passport === null ? { media: null } : self.user.passport
      })
    },
    getAuction(startedAt) {
      return this.$t('auctions.auction_date', {
        date: this.moment(startedAt).format('dddd DD MMMM YYYY'),
        to: this.moment(startedAt).format('LT'),
      })
    },
    stopAuction() {
      this.auction.finished_at = moment.now()
    },
    lotRedirect(lot) {
      return {
        name: 'app.products.details',
        params: {
          slug: lot.product.slug,
        },
      }
    },
  },
  computed: {
    quickBidAmount() {
      const bestBidAmount = this.bestBidAmount
      const actualCurrentBid =
        bestBidAmount > this.currentPrice ? bestBidAmount : this.currentPrice

      return actualCurrentBid + this.minimumRateIncrease
    },
    lotId() {
      const lotNumber = this.auction.data.live_streaming.current_lot

      return this.auction.lots.find((lot) => lot.lot_number === lotNumber).id
    },
    bestBidderId() {
      const bestUserBid = this.bestUserBid
      const currentPrice = this.auction.data.live_streaming.current_price

      return bestUserBid && bestUserBid.price === currentPrice
        ? bestUserBid.user_id
        : undefined
    },
    isBestBidder() {
      return this.bestBidderId === this.user.id
    },
    currentPrice() {
      return this.auction.data.live_streaming.current_price || 0
    },
    minimumRateIncrease() {
      const minimumRateIncrease = parseInt(
        this.auction.data.live_streaming.minimum_rate_increase
      )

      return minimumRateIncrease || 0
    },
  },
  filters: {
    moneyFormat(amount) {
      const negativeSign = amount < 0 ? '-' : ''
      const i = parseInt(
        (amount = Math.abs(Number(amount) || 0).toFixed(2))
      ).toString()
      const j = i.length > 3 ? i.length % 3 : 0
      return (
        negativeSign +
        (j ? i.substr(0, j) + '.' : '') +
        i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + '.') +
        (',' +
          Math.abs(amount - i)
            .toFixed(2)
            .slice(2))
      )
    },
  },
}
</script>

<style scoped lang="scss">
.screen-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
  margin: 0 auto;

  .image-outer-wrap {
    width: 50%;
    height: 100%;
    font-size: 34px;

    .image-inner-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }

  .currencies-outer-wrap {
    width: 50%;
    height: 80vh;
    .estimate {
      padding: 0 10px;
    }
    .currencies-inner-wrap {
      height: 80vh;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
    }

    .currency-wrap {
      width: 100%;
      font-size: 34px;
      margin-bottom: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .currency {
        margin-left: 20px;
      }

      .value {
        margin-right: 20px;
      }
    }
  }
}
</style>

import { render, staticRenderFns } from "./AdminAuctionVideo.vue?vue&type=template&id=28b2994b&scoped=true&"
import script from "./AdminAuctionVideo.vue?vue&type=script&lang=js&"
export * from "./AdminAuctionVideo.vue?vue&type=script&lang=js&"
import style0 from "./AdminAuctionVideo.vue?vue&type=style&index=0&id=28b2994b&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28b2994b",
  null
  
)

export default component.exports
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"ventes"},[_c('div',{staticClass:"ventes__links"},[_c('h1',{staticClass:"ventes__link active"},[_vm._v(_vm._s(_vm.$t('my_bidding.title')))]),_c('router-link',{staticClass:"ventes__link",attrs:{"to":{ name: 'app.orders.list' }}},[_vm._v(_vm._s(_vm.$t('navigation.my_purchases'))+" ")])],1),(!_vm.isLoaded)?_c('div',{staticClass:"loader placeholder"},[_c('img',{attrs:{"src":"/img/loader.svg","alt":"Loader"}})]):_vm._e(),_vm._l((_vm.auctionSubscriptions),function(auction,index){return _c('div',[(_vm.needToAddMonth(index))?_c('div',{staticClass:"ventes__monthly"},[_vm._v(" "+_vm._s(_vm.moment(_vm.item.started_at).format('MMMM YYYY'))+" ")]):_vm._e(),_c('auction-item',{attrs:{"auction":auction,"border":false}}),_vm._l((_vm.getLatestBids(auction.my_bids)),function(bid,bidIndex){return _c('div',{key:bidIndex,staticClass:"bid",attrs:{"bid":bid.lot.product}},[_c('img',{staticClass:"bid__image",attrs:{"src":_vm.getResizedImageUrl(bid.lot.product.media[0].link, '625x500'),"alt":"Piasa"}}),_c('div',{staticClass:"bid__right"},[_c('div',{staticClass:"bid__top"},[_c('p',{staticClass:"bid__id"},[_vm._v(_vm._s(_vm.lotNumber(bid.lot.lot_number)))]),(auction.finished_at && !_vm.isInPast(auction.finished_at))?_c('p',{staticClass:"bid__counter"},[_vm._v(" "+_vm._s(_vm.$t('my_bidding.time_left'))+" : "),_c('countdown',{attrs:{"time":new Date(auction.finished_at).getTime() - new Date().getTime()},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(_vm._s(props.days)+" j : "+_vm._s(props.hours)+" h : "+_vm._s(props.minutes)+" min : "+_vm._s(props.seconds)+" sec ")]}}],null,true)})],1):_vm._e()]),_c('p',{staticClass:"bid__title"},[_vm._v(" "+_vm._s(bid.lot.product.translation.name ? bid.lot.product.translation.name : _vm.$t('purchase.no.info'))+" ")]),_c('div',{staticClass:"bid__description",domProps:{"innerHTML":_vm._s(
            bid.lot.product.translation.description
              ? bid.lot.product.translation.description
              : _vm.$t('purchase.no.description')
          )}}),_c('div',{staticClass:"bid__bottom"},[_c('div',{staticClass:"bid__bottom-left"},[_c('p',{staticClass:"bid__est"},[_vm._v(" "+_vm._s(_vm.$t('my_bidding.estimate'))+" : "+_vm._s(bid.lot.product.min_estimation)+" / "+_vm._s(bid.lot.product.max_estimation)+" € ")]),(bid.lot.product.bids.length && !auction.finished)?_c('p',{staticClass:"bid__current"},[_vm._v(" "+_vm._s(_vm.$t('my_bidding.current_bid'))+" : "+_vm._s(_vm.bidPrice(bid))+" € "),(_vm.isBestBid(bid))?_c('span',{staticClass:"bid__best"},[_vm._v(_vm._s(_vm.$t('my_bidding.best_bid')))]):_vm._e()]):_vm._e(),(bid.lot.product.bids.length && auction.finished)?_c('p',{staticClass:"bid__current"},[_vm._v(" "+_vm._s(_vm.$t('products.result'))+" : "+_vm._s(_vm.bidPrice(bid))+" € "),(_vm.isBestBid(bid))?_c('span',{staticClass:"bid__best"},[_vm._v(_vm._s(_vm.$t('my_bidding.best_bid')))]):_vm._e()]):_vm._e()]),(!_vm.isInPast(auction.finished_at))?_c('div',{staticClass:"bid__bottom-right"},[_c('router-link',{staticClass:"bid__button button",attrs:{"to":{
                name: 'app.products.details',
                params: { slug: bid.lot.product.slug },
              }}},[_vm._v(_vm._s(_vm.$t('my_bidding.button_bid'))+" ")])],1):_vm._e()])])])})],2)}),(_vm.isLoaded && !_vm.user.auction_subscriptions.length)?_c('div',{staticClass:"mock-container"},[_vm._v(" "+_vm._s(_vm.$t('auctions.not_found'))+" ")]):_vm._e(),(_vm.isLoaded)?_c('a',{staticClass:"ventes__refresh",on:{"click":_vm.getProfile}},[_c('img',{attrs:{"src":require("@/assets/img/refresh.png"),"alt":"Refresh"}})]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }
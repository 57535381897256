<template>
  <article class="blog__item">
    <router-link
      :to="{ name: 'app.artists.details', params: { slug: artists.slug } }"
      class="image-box__link"
    >
      <div class="image-box image-box--large">
        <img
          :src="getResizedImageUrl(artists.media.link, 'original')"
          :alt="artists.media.name"
        />
      </div>
    </router-link>
    <h3 class="blog__item-title">{{ artists.translation.name }}</h3>
  </article>
</template>

<script>
export default {
  name: 'artists-item',
  props: ['artists'],
}
</script>

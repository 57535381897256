var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"icons"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"icons__grid"},[_c('div',{staticClass:"icons__wrap"},[_c('router-link',{staticClass:"icons__item",attrs:{"to":{ name: 'app.estimation' }}},[_c('img',{attrs:{"src":require("@/assets/img/icon-1.svg"),"alt":"estimate"}})]),_c('p',{staticClass:"icons__title"},[_c('router-link',{attrs:{"to":{ name: 'app.estimation' }}},[_vm._v(" "+_vm._s(_vm.$t('index.estimate'))+" ")])],1)],1),_c('div',{staticClass:"icons__wrap"},[_c('router-link',{staticClass:"icons__item",attrs:{"to":{
            name: 'app.static_page',
            params: {
              slug: 'how-to-bid',
            },
          }}},[_c('img',{attrs:{"src":require("@/assets/img/icon-2.svg"),"alt":"buying-guide"}})]),_c('p',{staticClass:"icons__title"},[_c('router-link',{attrs:{"to":{
              name: 'app.static_page',
              params: {
                slug: 'how-to-bid',
              },
            }}},[_vm._v(" "+_vm._s(_vm.$t('index.buying_guide'))+" ")])],1)],1),_c('div',{staticClass:"icons__wrap"},[_c('router-link',{staticClass:"icons__item",attrs:{"to":{
            name: 'app.static_page',
            params: {
              slug: 'how-to-sell',
            },
          }}},[_c('img',{attrs:{"src":require("@/assets/img/icon-3.svg"),"alt":"selling-guide"}})]),_c('p',{staticClass:"icons__title"},[_c('router-link',{attrs:{"to":{
              name: 'app.static_page',
              params: {
                slug: 'how-to-sell',
              },
            }}},[_vm._v(" "+_vm._s(_vm.$t('index.selling_guide'))+" ")])],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <section>
    <article v-html="page.translation.content"></article>
  </section>
</template>

<script>
import { isEmpty } from 'lodash'

export default {
  name: 'static-page',
  metaInfo() {
    return {
      title: this.page.translation.title,
    }
  },
  data() {
    return {
      page: {
        translation: {
          title: '',
          description: '',
        },
      },
    }
  },
  created: function () {
    const self = this

    this.$api
      .getPage(this.$route.fullPath)
      .then((response) => {
        if (!isEmpty(response.data.to)) {
          self.$router.push(response.data.to)

          return
        }

        self.page = response.data
      })
      .catch(() => {
        self.$router.push({
          name: 'app.not_found',
        })
      })
  },
}
</script>

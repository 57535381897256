<template>
  <section class="sign-in">
    <h1>{{ $t('auth.forgot_password') }}</h1>
    <form class="form">
      <div class="form__row">
        <input
          class="form__input"
          v-model="email"
          type="email"
          :class="{ 'has-error': $v.email.$error }"
          :placeholder="$t('auth.email')"
        />
      </div>
      <div class="form__row" @click="submit()">
        <a class="button">{{ $t('auth.reset_password') }}</a>
        <div v-if="error" class="has-error">Email is invalid</div>
      </div>
    </form>
  </section>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'

export default {
  name: 'restore-password',
  mixins: [validationMixin],
  metaInfo() {
    return {
      title: 'Forgot password?',
    }
  },
  data() {
    return {
      email: '',
      error: '',
    }
  },
  validations: {
    email: { required, email },
  },
  methods: {
    submit() {
      this.$v.$touch()

      if (this.$v.$error) {
        return
      }

      const self = this

      this.$api
        .forgotPassword(this.email)
        .then(() => {
          self.$router.push({
            name: 'app.home',
          })
        })
        .catch((error) => {
          const errors = []

          for (const i in error.response.data) {
            if (error.response.data[i].constructor === Array) {
              errors.push(error.response.data[i].join(', '))
            } else {
              errors.push(error.response.data[i])
            }
          }

          self.error = errors.join(', ')
        })
    },
  },
}
</script>

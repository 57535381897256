<template>
  <article class="blog__item">
    <router-link
      :to="{ name: 'app.presses.details', params: { id: press.slug } }"
      class="image-box__link"
    >
      <div class="image-box image-box--large">
        <img
          :src="getResizedImageUrl(press.media.link, 'original')"
          :alt="press.media.name"
        />
      </div>
    </router-link>
    <span class="blog__item-date">{{
      press.publication_date ? moment(press.publication_date).format('LL') : ''
    }}</span>
    <h3 class="blog__item-title">{{ press.translation.title }}</h3>
  </article>
</template>

<script>
export default {
  name: 'press-item',
  props: ['press'],
}
</script>

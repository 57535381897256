export default {
  methods: {
    getThumbnail(url) {
      return this.getResizedImageUrl(url, '625x500')
    },
    getSrcSet(url) {
      const medium = this.getResizedImageUrl(url, '625x500')
      const large = this.getResizedImageUrl(url, '1000x800')

      return medium + ' 2x, ' + large + ' 3x'
    },
    getResizedImageUrl(url, size) {
      const backendUrl = this.$http.getBackendUrl()
      return backendUrl + '/cache/' + size + url.replace(backendUrl, '')
    },
  },
}

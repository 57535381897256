<template>
  <div class="row">
    <div class="col col-50">
      <div class="signup-form">
        <h1>{{ $t('auth.create_account') }}</h1>
        <p class="intro">
          {{ $t('auth.register_intro') }}
        </p>
        <form class="form">
          <radio-buttons-box
            name="gender"
            :items="radioButtonItems.gender"
            v-model="params.profile.gender"
          >
          </radio-buttons-box>
          <div class="row">
            <div class="col col-50">
              <input
                class="form__input"
                type="text"
                :placeholder="$t('auth.first_name')"
                v-model="params.first_name"
                :class="{ 'has-error': $v.params.first_name.$error }"
              />
            </div>
            <div class="col col-50">
              <input
                class="form__input"
                type="text"
                :placeholder="$t('auth.last_name')"
                v-model="params.last_name"
                :class="{ 'has-error': $v.params.last_name.$error }"
              />
            </div>
          </div>
          <div class="form__row">
            <input
              class="form__input"
              type="email"
              :placeholder="$t('auth.email')"
              v-model="params.email"
              :class="{ 'has-error': $v.params.email.$error }"
            />
          </div>
          <div class="row">
            <div class="col col-50">
              <input
                class="form__input"
                type="password"
                :placeholder="$t('auth.password')"
                v-model="params.password"
                :class="{ 'has-error': $v.params.password.$error }"
              />
            </div>
            <div class="col col-50">
              <input
                class="form__input"
                type="password"
                :placeholder="$t('auth.confirm_password')"
                v-model="params.confirm"
                :class="{ 'has-error': $v.params.confirm.$error }"
              />
            </div>
          </div>
          <div class="form__row">
            <div class="form__label-wrap">
              <input
                class="form__checkbox"
                type="checkbox"
                id="subscription"
                v-model="params.subscribe"
              />
              <label class="form__label" for="subscription">{{
                $t('auth.subscribe')
              }}</label>
            </div>
            <div class="form__label-wrap">
              <input
                class="form__checkbox"
                type="checkbox"
                id="license"
                v-model="params.agree_license"
              />
              <label
                class="form__label"
                :class="{ 'has-error': $v.params.agree_license.$error }"
                for="license"
              >
                {{ $t('auth.agree_first') }}
                <router-link
                  :to="{ name: 'app.static_page', params: { slug: 'cgv' } }"
                  class="link link--underline"
                >
                  {{ $t('auth.agree_link') }}
                </router-link>
              </label>
            </div>
          </div>
          <div v-if="error" class="field__error form__row">
            {{ error }}
          </div>
          <div class="row">
            <div class="col col-50">
              <router-link
                :to="{ name: 'app.sign_in' }"
                class="link link--underline"
              >
                {{ $t('auth.already_registered') }}
              </router-link>
            </div>
            <div class="col col-50">
              <a id="register-btn" class="button" @click="signup()">{{
                $t('auth.register')
              }}</a>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="col col-50">
      <div class="signup-apparel">
        <img src="/img/signup.jpg" alt="Piasa" />
      </div>
    </div>
  </div>
</template>

<script>
import { email, required, sameAs } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import RadioButtonsBox from '../Inputs/RadioButtonsBox'

export default {
  components: { RadioButtonsBox },
  name: 'sign-up',
  mixins: [validationMixin],
  metaInfo() {
    return {
      title: 'Sign Up',
    }
  },
  data() {
    return {
      error: '',
      params: {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        confirm: '',
        subscribe: false,
        agree_license: false,
        profile: {
          gender: 'male',
        },
      },
      radioButtonItems: {
        gender: [
          {
            value: 'female',
            title: this.$t('auth.female'),
          },
          {
            value: 'male',
            title: this.$t('auth.male'),
          },
        ],
      },
    }
  },
  validations: {
    params: {
      first_name: { required },
      last_name: { required },
      email: { required, email },
      password: { required },
      agree_license: { required, checked: (value) => value === true },
      confirm: { sameAsPassword: sameAs('password') },
    },
  },
  methods: {
    signup() {
      this.$v.$touch()

      this.error = ''

      if (this.$v.$error && !this.agree_license) {
        return
      }

      this.params.login = this.params.email

      const self = this

      this.$api
        .signup(this.params)
        .then((response) => {
          window.dataLayer.push({
            event: 'signupEvent',
          })

          const url = { name: 'app.validation' }

          self.$store.commit('token', response.data.token)
          self.$store.commit('setRedirectBackUrl', null)

          self.$router.push(url)
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.error = this.$t('auth.the_login_has_already_been_taken')
          } else {
            this.error = error.response.data.message
          }
        })
    },
  },
}
</script>

<style scoped>
/*TODO: need to move it to scss files*/
.field__error {
  color: #ce081b;
}

div.has-error {
  margin-top: 5px;
  top: 100%;
  left: 0;
  right: 0;
  text-align: center;
  position: absolute;
}

.form__row {
  position: relative;
  padding: 30px 0;
  margin-bottom: 0;
}

.signup-form .form .row {
  padding: 30px 0;
}

p.intro {
  font-size: 2.5rem;
}

@media (min-width: 768px) {
  .signup-form {
    margin-top: 180px;
    margin-right: 130px;
  }
}

@media (max-width: 767.98px) {
  .form .col {
    padding: 20px 0;
  }

  .form__row {
    margin: 0;
    padding: 20px 0;
  }

  .signup-form .form .row {
    padding: 0;
    margin: 0;
  }
}
</style>

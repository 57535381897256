<template>
  <article class="lots">
    <router-link
      :to="{ name: 'app.products.details', params: { slug: product.slug } }"
      class="image-box__link"
    >
      <div class="">
        <img
          :src="`${$http.getBackendUrl()}${product.media[0].link}`"
          :srcset="`${$http.getBackendUrl()}${product.media[0].link}`"
          :alt="product.media[0].name"
        />
      </div>
    </router-link>
    <div v-if="firstAuction" class="lots__content">
      <div class="lots__auction-date">
        <p class="lots__auction-date-item" v-text="firstAuction.format('ddd')">
          Jeu
        </p>
        <p class="lots__auction-date-item" v-text="firstAuction.format('DD')">
          20
        </p>
        <p class="lots__auction-date-item" v-text="firstAuction.format('MMM')">
          Fev
        </p>
      </div>
      <div class="lots__descr">
        <div class="lots__title" v-html="getProductName()"></div>
        <span class="lots__cost result" v-if="isInPast()"
          >{{ $t('products.result') }}: {{ product.price }} {{ currency }}</span
        >
        <span class="lots__cost result" v-if="isShowingPrice()"
          >{{ $t('products.price') }} : {{ product.price }} {{ currency }}</span
        >
        <span class="lots__cost" v-if="estimationNotNull()">
          Estimation : {{ product.min_estimation }}
          <span v-if="product.max_estimation"
            >/ {{ product.max_estimation }}</span
          >
          {{ currency }}
        </span>
        <span class="lots__cost" v-if="isSold()">{{
          $t('products.sold')
        }}</span>
      </div>
    </div>
  </article>
</template>

<script>
import { isNull } from 'lodash'

export default {
  name: 'product-item',
  props: ['product'],
  computed: {
    currency() {
      return '€'
    },
    firstAuction() {
      if (this.product.auctions.length === 0) {
        return null
      }
      let result = this.moment().add(5, 'Y')
      this.product.auctions.forEach((auction) => {
        if (this.moment(auction.started_at).isBefore(result)) {
          result = this.moment(auction.started_at)
        }
      })
      return result
    },
  },
  methods: {
    isShowingPrice() {
      return (
        !this.isInPast() &&
        this.product.buyable_online &&
        this.product.price &&
        this.product.count
      )
    },
    isInPast() {
      return !isNull(this.product.price)
    },
    estimationNotNull() {
      return (
        !this.product.buyable_online &&
        (this.product.min_estimation || this.product.max_estimation)
      )
    },
    isSold() {
      return !this.isInPast() && !this.product.count
    },
    getProductName() {
      const nameLinesArray = this.product.translation.name.split(
        /(?:\r\n|\r|\n)/g
      )
      if (nameLinesArray.length > 3) {
        nameLinesArray.splice(3)
        nameLinesArray.push('…')
        return nameLinesArray.join('<br />')
      } else {
        return this.product.translation.name.replace(
          /(?:\r\n|\r|\n)/g,
          '<br />'
        )
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.result {
  display: block;
  margin-bottom: 0.5rem;
  color: #a41d23;
  line-height: 16px;
}

.lots__title {
  font-weight: bold;
  line-height: 16px;
  margin-bottom: 8px;
}

.lots__auction-date {
  margin-right: 20px;
  &-item {
    margin-bottom: 0;
    text-transform: uppercase;
    font-size: 18px;
    line-height: 19px;
  }
  &:nth-child(2) {
    margin-top: 10px;
    font-size: 19px;
  }
}

@media (min-width: 768px) {
  .lots {
    padding-right: 5.28%;
    padding-left: 5.28%;
  }
}
</style>

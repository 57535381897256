<template>
  <header
    class="vente__header"
    :style="{
      backgroundImage:
        'url(' + this.$http.getBackendUrl() + headerImage[0].link + ')',
    }"
  >
    <h1
      class="ventes__title ventes__long"
      v-html="auction.translation.title"
    ></h1>
  </header>
</template>

<script>
export default {
  name: 'AuctionHeader',
  props: {
    auction: {
      type: Object,
      required: true,
    },
  },
  computed: {
    headerImage() {
      const headerImages = this.auction.media.filter(
        (mediaItem) => mediaItem.type === 'header'
      )

      if (headerImages.length) {
        return headerImages
      }

      return this.auction.media
    },
  },
}
</script>

<style scoped>
.vente__header {
  background-size: cover;
  background-position: center;
  height: 257px;
  display: flex;
  justify-content: center;
  align-items: center;
}

h1.ventes__title {
  line-height: 50px;
}
</style>

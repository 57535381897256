export default {
  methods: {
    needToShowLiveStreamingButton() {
      return (
        !this.moment(this.auction.finished_at).isBefore() &&
        !this.auction.drouot_auction &&
        this.auction.bid_online &&
        this.auction.slug !== 'Grafic-Africa-Abou-Traore-Raymond-Tsham'
      )
    },
    needToShowOnlineBiddingButton() {
      return (
        !this.moment(this.auction.finished_at).isBefore() &&
        !this.auction.drouot_auction &&
        this.auction.biddable_online &&
        this.auction.slug !== 'Grafic-Africa-Abou-Traore-Raymond-Tsham'
      )
    },
    getLiveAuctionRedirectData() {
      if (this.$store.state.is_admin) {
        return {
          path: '/live/auctions/' + this.auction.id + '/live',
          params: {
            id: this.auction.id,
          },
          meta: { requiresAuth: true },
        }
      }

      return {
        path: '/live/auctions/' + this.auction.id + '/live',
        params: {
          id: this.auction.id,
        },
        meta: { requiresAuth: true },
      }
    },
  },
}

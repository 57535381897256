<template>
  <div>
    <div v-if="isLoaded">
      <div class="subscribe__wrap">
        <div>
          <h2 class="subscribe__subtitle">
            {{ $t('auth.not_registered') }}
          </h2>
          <p class="subscribe__text">
            {{ $t('auth.product_bidding') }}
          </p>

          <div class="subscribe-container">
            <router-link
              :to="{
                name: 'app.sign_in',
                query: { redirect: this.$route.path },
              }"
              class="button subscribe__button"
              >{{ $t('auth.login_title') }}</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductAuthNotification',
  props: {
    isLoaded: {
      type: Boolean,
      required: true,
    },
  },
}
</script>

<style scoped>
.subscribe-container {
  margin-top: 30px;
}
</style>

<template>
  <div>
    <section class="verification">
      <h1>{{ $t('payment.complete') }}</h1>
      <p>{{ $t('payment.success_text') }}</p>
      <router-link :to="{ name: 'app.home' }" class="link link--underline">{{
        $t('not_found.go_home')
      }}</router-link>
    </section>
  </div>
</template>

<script>
export default {}
</script>

import VueI18n from 'vue-i18n'
import Vue from 'vue'
import * as moment from 'moment'
import * as _ from 'lodash'
import en from '../i18n/en'
import fr from '../i18n/fr'

Vue.use(VueI18n)

export const dictionary = {
  en: en,
  fr: fr,
}

export const getLocale = (preferredValue) => {
  const locale =
    preferredValue ||
    window.localStorage.getItem('locale') ||
    navigator.language ||
    navigator.userLanguage
  let preparedLocale = locale.split('-')[0].toLowerCase()

  if (_.isEmpty(dictionary[preparedLocale])) {
    preparedLocale = 'en'
  }

  moment.locale(preparedLocale)

  return preparedLocale
}

export default new VueI18n({
  locale: getLocale(),
  messages: dictionary,
})

import Vue from 'vue'
import VueRouter from 'vue-router'
import App from '@/App'
import MainWrapper from '@/components/layout/MainWrapper'
import HomePage from '@/components/auctions/HomePage'
import PastAuctions from '@/components/auctions/PastAuctions'
import PastAuctionsList from '@/components/auctions/PastAuctionsList'
import MyAuctions from '@/components/auctions/MyAuctions'
import FollowedProducts from '@/components/products/FollowedProducts'
import Payment from '@/components/orders/Payment'
import FutureAuctions from '@/components/auctions/FutureAuctions'
import AuctionDetails from '@/components/auctions/AuctionDetails'
import AuctionPurchase from '@/components/auctions/AuctionPurchase'
import ClientAuction from '@/components/live_streaming/ClientAuction'
import AdminAuctionVideo from '@/components/live_streaming/AdminAuctionVideo'
import AdminAuction from '@/components/live_streaming/AdminAuction'
import AuctionSlide from '@/components/live_streaming/AuctionSlide'
import NewsList from '@/components/news/NewsList'
import NewsDetails from '@/components/news/NewsDetails'
import ArtistsList from '@/components/artists/ArtistsList'
import ArtistsDetails from '@/components/artists/ArtistsDetails'
import CollectionsDetails from '@/components/collections/CollectionsDetails'
import PressesList from '@/components/press/PressesList'
import PressesDetails from '@/components/press/PressDetails'
import ContactUs from '@/components/contact_us/ContactUs'
import ProductDetails from '@/components/products/ProductDetails'
import SignIn from '@/components/auth/SignIn'
import SignUp from '@/components/auth/SignUp'
import Validation from '@/components/auth/Validation'
import Account from '@/components/auth/Account'
import RestorePassword from '@/components/auth/RestorePassword'
import ForgotPassword from '@/components/auth/ForgotPassword'
import Profile from '@/components/profile/Profile'
import ProfileAccount from '@/components/profile/ProfileAccount'
import Cart from '@/components/orders/Cart'
import OrdersList from '@/components/orders/OrdersList'
import Calendar from '@/components/orders/Calendar'
import CategoriesList from '@/components/categories/CategoriesList'
import CategoryDetails from '@/components/categories/CategoryDetails'
import Purchase from '@/components/purchase/Purchase'
import Estimation from '@/components/estimation/Estimation'
import EstimationResults from '@/components/estimation/EstimationResults'
import EstimationRequest from '@/components/estimation/EstimationRequest'
import StaticPage from '@/components/static_pages/StaticPage'
import NotFound from '@/components/layout/NotFound'
import ProductSearch from '@/components/products/ProductSearch'
import BidOnline from '@/components/products/BidOnline'
import Subscribe from '@/components/subscribe/Subscribe'
import YoutubeRedirectPage from '@/components/live_streaming/YoutubeRedirectPage'
import Success from '@/components/orders/Success'
import Cancel from '@/components/orders/Cancel'
import store from '@/services/store.service'
import { getLocale } from '@/services/i18n.service'
import moment from 'moment'
import ClientAuctionBidding from '@/components/live_streaming/ClientAuctionBidding'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '',
    component: App,
    meta: { requiresAuth: false },
    children: [
      {
        path: 'youtube_redirect',
        name: 'youtube_redirect',
        component: YoutubeRedirectPage,
        meta: { requiresAuth: false },
      },
      {
        path: ':locale',
        name: 'app',
        component: MainWrapper,
        meta: { requiresAuth: false },
        children: [
          {
            path: 'home',
            name: 'app.home',
            component: HomePage,
            meta: { requiresAuth: false },
          },
          {
            path: 'past-auctions',
            name: 'app.past_auctions',
            component: PastAuctions,
            meta: { requiresAuth: false },
            children: [
              {
                path: ':year',
                name: 'app.past_auctions.list',
                component: PastAuctionsList,
                meta: { requiresAuth: false },
              },
            ],
          },
          {
            path: 'auctions',
            name: 'app.auctions',
            component: MyAuctions,
            meta: { requiresAuth: true },
          },
          {
            path: 'lots-followed',
            name: 'app.followed_products',
            component: FollowedProducts,
            meta: { requiresAuth: true },
          },
          {
            path: 'future-auctions',
            name: 'app.future_auctions',
            component: FutureAuctions,
            meta: { requiresAuth: false },
          },
          {
            path: 'products',
            name: 'app.products.search',
            component: ProductSearch,
            meta: { requiresAuth: false },
          },
          {
            path: 'products/:slug',
            name: 'app.products.details',
            component: ProductDetails,
            meta: { requiresAuth: false },
          },
          {
            path: 'auctions/:slug',
            name: 'app.auctions.details',
            component: AuctionDetails,
            meta: { requiresAuth: false },
          },
          {
            path: 'auctions/:slug/lots/:number/purchase/phone',
            name: 'app.auction.purchase.phone',
            component: AuctionPurchase,
            meta: { requiresAuth: true },
          },
          {
            path: 'auctions/:slug/lots/:number/purchase/price',
            name: 'app.auction.purchase.price',
            component: AuctionPurchase,
            meta: { requiresAuth: true },
          },
          {
            path: 'news',
            name: 'app.news.list',
            component: NewsList,
            meta: { requiresAuth: false },
          },
          {
            path: 'news/:id',
            name: 'app.news.details',
            component: NewsDetails,
            meta: { requiresAuth: false },
          },
          {
            path: 'collections/:slug',
            name: 'app.collections.details',
            component: CollectionsDetails,
            meta: { requiresAuth: false },
          },
          {
            path: 'artists',
            name: 'app.artists.list',
            component: ArtistsList,
            meta: { requiresAuth: false },
          },
          {
            path: 'artists/:slug',
            name: 'app.artists.details',
            component: ArtistsDetails,
            meta: { requiresAuth: false },
          },
          {
            path: 'presses',
            name: 'app.presses.list',
            component: PressesList,
            meta: { requiresAuth: false },
          },
          {
            path: 'presses/:id',
            name: 'app.presses.details',
            component: PressesDetails,
            meta: { requiresAuth: false },
          },
          {
            path: 'contact-us',
            name: 'app.contact_us',
            component: ContactUs,
            meta: { requiresAuth: false },
          },
          {
            path: 'sign-in',
            name: 'app.sign_in',
            component: SignIn,
            meta: { requiresAuth: false },
          },
          {
            path: 'sign-up',
            name: 'app.sign_up',
            component: SignUp,
            meta: { requiresAuth: false },
          },
          {
            path: 'validation',
            name: 'app.validation',
            component: Validation,
            meta: { requiresAuth: true },
          },
          {
            path: 'account',
            name: 'app.account',
            component: Account,
            meta: { requiresAuth: true },
          },
          {
            path: 'forgot-password',
            name: 'app.forgot_password',
            component: ForgotPassword,
            meta: { requiresAuth: false },
          },
          {
            path: 'restore-password',
            name: 'app.restore_password',
            component: RestorePassword,
            meta: { requiresAuth: false },
          },
          {
            path: 'subscribe',
            name: 'app.subscribe',
            component: Subscribe,
            meta: { requiresAuth: false },
          },
          {
            path: 'orders/new',
            name: 'app.orders.new',
            component: Calendar,
            meta: { requiresAuth: true },
          },
          {
            path: 'orders/:orderId/pay',
            name: 'app.orders.payment',
            component: Payment,
            meta: { requiresAuth: true },
          },
          {
            path: '/success/:orderId',
            name: 'app.orders.success',
            component: Success,
            meta: { requiresAuth: true },
          },
          {
            path: '/cancel',
            name: 'app.orders.cancel',
            component: Cancel,
            meta: { requiresAuth: true },
          },
          {
            path: 'orders/list',
            name: 'app.orders.list',
            component: OrdersList,
            meta: { requiresAuth: true },
          },
          {
            path: 'orders/cart',
            name: 'app.orders.cart',
            component: Cart,
            meta: { requiresAuth: true },
          },
          {
            path: 'categories',
            name: 'app.categories',
            component: CategoriesList,
            meta: { requiresAuth: false },
          },
          {
            path: 'categories/:id',
            name: 'app.categories.details',
            component: CategoryDetails,
            meta: { requiresAuth: false },
          },
          {
            path: 'bid-online',
            name: 'app.bid_online',
            component: BidOnline,
            meta: { requiresAuth: false },
          },
          {
            path: 'auctions/:slug/live',
            name: 'app.client_auction',
            component: ClientAuction,
            meta: { requiresAuth: true },
          },
          {
            path: 'auctions/:slug/bidding',
            name: 'app.client_auction_bidding',
            component: ClientAuctionBidding,
            meta: { requiresAuth: true },
          },
          {
            path: 'auctions/:slug/video',
            name: 'app.online_video',
            component: AdminAuctionVideo,
            meta: { requiresAuth: true },
          },
          {
            path: 'auctions/:slug/admin',
            name: 'app.admin_auction',
            component: AdminAuction,
            meta: { requiresAuth: true },
          },
          {
            path: 'auctions/:slug/slide',
            name: 'app.auction_slide',
            component: AuctionSlide,
            meta: { requiresAuth: true },
          },
          {
            path: 'profile',
            name: 'app.profile',
            component: Profile,
            meta: { requiresAuth: true },
            children: [
              {
                path: 'account',
                name: 'app.profile.account',
                component: ProfileAccount,
                meta: { requiresAuth: true },
              },
            ],
          },
          {
            path: 'estimation',
            name: 'app.estimation',
            component: Estimation,
            meta: { requiresAuth: true },
            children: [
              {
                path: 'results',
                name: 'app.estimation.results',
                component: EstimationResults,
                meta: { requiresAuth: true },
              },
              {
                path: 'request',
                name: 'app.estimation.request',
                component: EstimationRequest,
                meta: { requiresAuth: true },
              },
            ],
          },
          {
            path: 'purchase',
            name: 'app.purchase',
            component: Purchase,
            meta: { requiresAuth: true },
          },
          {
            path: 'not-found',
            name: 'app.not_found',
            component: NotFound,
            meta: { requiresAuth: false },
          },
          {
            path: ':slug',
            name: 'app.static_page',
            component: StaticPage,
            meta: { requiresAuth: false },
          },
          {
            path: ':slug/:slug1',
            name: 'app.static_page1',
            component: StaticPage,
            meta: { requiresAuth: false },
          },
          {
            path: ':slug/:slug1/:slug2',
            name: 'app.static_page2',
            component: StaticPage,
            meta: { requiresAuth: false },
          },
          {
            path: ':slug/:slug1/:slug2/:slug3',
            name: 'app.static_page3',
            component: StaticPage,
            meta: { requiresAuth: false },
          },
          {
            path: ':slug/:slug1/:slug2/:slug3/:slug4',
            name: 'app.static_page4',
            component: StaticPage,
            meta: { requiresAuth: false },
          },
        ],
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  saveScrollPosition: true,
  scrollBehavior: function (to, from, savedPosition) {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (savedPosition) {
          resolve(savedPosition)
        }

        resolve({ x: 0, y: 0 })
      }, 1000)
    })
  },
  routes,
})

router.beforeEach((to, from, next) => {
  store.commit('showHeader')

  if (to.name === 'app' || to.name === '') {
    return next({
      name: 'app.home',
      params: {
        locale: getLocale(),
      },
    })
  }

  if (to.name === 'app.profile') {
    return next({ name: 'app.profile.account' })
  }

  if (to.name === 'app.estimation') {
    return next({ name: 'app.estimation.request' })
  }

  if (to.name === 'app.past_auctions') {
    return next({
      name: 'app.past_auctions.list',
      params: {
        year: moment().year(),
      },
    })
  }

  store.state.fullscreen = false

  if (to.meta.requiresAuth) {
    if (store.state.token) {
      return next()
    } else {
      store.commit('setRedirectBackUrl', to.path)

      return next({ name: 'app.sign_in' })
    }
  }

  next()
})

export default router

import PusherService from 'pusher-js'

function VuePusher(apiKey, options) {
  this.pusher = new PusherService(apiKey, options)
  this.channels = []
}

VuePusher.prototype.subscribe = function (channelName) {
  const channel = this.pusher.subscribe(channelName)

  if (!this.channels.includes(channel)) {
    this.channels.push(channelName)
  }

  return this.pusher
}

VuePusher.prototype.unsubscribe = function (channel) {
  this.pusher.unsubscribe(channel)
}

VuePusher.prototype.disconnect = function () {
  this.pusher.disconnect()
}

VuePusher.prototype.getChannels = function () {
  return this.channels
}

export default VuePusher

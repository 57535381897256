<template>
  <section class="next-auction-section">
    <div class="next-auction-section__preview-area">
      <button class="button next-auction-section__button" @click="redirect()">
        Vente de la Bibliothèque de François Mitterrand<br />
        Seconde partie - Lots 335 à 683
      </button>
    </div>
  </section>
</template>

<script>
export default {
  components: {},
  name: 'next-auction-section',
  props: [],
  methods: {
    redirect() {
      this.$router.push({
        name: 'app.auctions.details',
        params: {
          slug: 'bibliotheque-francois-mitterrand-2-2018',
        },
      })
    },
  },
  computed: {},
}
</script>

<style scoped lang="scss">
.next-auction-section {
  padding: 50px 0;

  &__preview-area {
    position: relative;

    margin: 0 auto;

    padding: 50px 0;
    max-width: 1050px;

    background: url('https://api.piasa.fr/cache/1000x800/catalog/images/2761/397_1.jpg')
      50% 35%;
    background-size: cover;

    text-align: center;

    &:before {
      content: '';

      position: absolute;

      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      background: black;
      opacity: 0.5;

      transition: opacity 0.3s;
    }

    &:hover:before {
      opacity: 0.3;
    }
  }

  &__button {
    position: relative;

    display: inline-block;
    margin: 0 auto;
    width: auto;
    height: auto;
    padding: 1.5rem;
    line-height: 150%;

    color: white;

    border-width: 4px;
    border-color: transparent;

    &:after {
      display: block;
      position: relative;
      top: 19px;

      content: '';

      width: 20px;
      border-top: 4px solid white;

      margin: 0 auto;
    }

    &:hover {
      border-width: 4px;
      border-style: solid;
      border-color: white;

      background: none;
    }
  }
}
</style>

<template>
  <div v-if="products.length && isLoaded">
    <div id="coming" class="home-headline">
      <h3>{{ $t('index.coming') }}</h3>
    </div>
    <div class="container">
      <div class="carousel">
        <a
          class="carousel__button carousel__button--left"
          @click="$refs.current.prev()"
        >
          <svg
            width="12"
            height="21"
            viewBox="0 0 12 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M11 20L2 10.5L11 1" stroke="#363636" stroke-width="2" />
          </svg>
        </a>
        <a
          class="carousel__button carousel__button--right"
          @click="$refs.current.next()"
        >
          <svg
            width="12"
            height="21"
            viewBox="0 0 12 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1 1L10 10.5L1 20" stroke="#363636" stroke-width="2" />
          </svg>
        </a>
        <VueSlickCarousel ref="current" v-bind="settings">
          <biddable-lot-item
            v-for="product in products.slice(0, 8)"
            :lot="product"
            :auction="product.auctions[0]"
            :product="product"
            :key="product.id"
            class="col col-25"
          >
          </biddable-lot-item>
        </VueSlickCarousel>
      </div>
    </div>
  </div>
</template>

<script>
import BiddableLotItem from '@/components/auctions/common/BiddableLotItem'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
export default {
  name: 'AnnouncedProducts',
  components: {
    BiddableLotItem,
    VueSlickCarousel,
  },
  data() {
    return {
      settings: {
        dots: false,
        dotsClass: 'slick-dots',
        arrows: false,
        infinite: true,
        initialSlide: 1,
        slidesToShow: 4,
        slidesToScroll: 1,
        draggable: false,
        swipe: false,
        touchMove: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              vertical: true,
              slidesToShow: 2,
            },
          },
        ],
      },
    }
  },
  props: {
    isLoaded: {
      type: Boolean,
      required: true,
    },
    products: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style scoped>
.home-headline {
  margin: 47px 0;
  padding: 29px 0;
  width: 100%;
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  text-align: center;
}

.home-headline > h3 {
  margin-bottom: 0;
  text-transform: uppercase;
}

.carousel {
  position: relative;
}

.carousel__button {
  width: 25px;
  justify-content: center;
  position: absolute;
  top: 20%;
  cursor: pointer;
}

.carousel__button--left {
  justify-content: flex-end;
  left: -20px;
}

.carousel__button--right {
  right: -20px;
}

@media (max-width: 1024px) {
  .carousel__button--left {
    left: 50%;
    transform: translateX(-50%) rotateZ(90deg);
    top: -30px;
  }

  .carousel__button--right {
    display: none;
  }
}
</style>

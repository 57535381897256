import ApiService from './../services/api.service'

export const profileMixin = {
  methods: {
    save() {
      this.$v.$touch()

      if (this.$v.$error) {
        return
      }

      const self = this

      ApiService.updateUser(this.user)
        .then(() => {
          self.close()
        })
        .catch((error) => {
          self.error = error.response.data.error
        })
    },
    close() {
      this.$refs.profileBox.close()
    },
  },
}

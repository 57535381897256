<template>
  <div></div>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js'
export default {
  async mounted() {
    const stripe = await loadStripe(process.env.VUE_APP_STRIPE_KEY, {})
    this.$api
      .getCheckoutSession(this.$route.params.orderId)
      .then((response) => {
        stripe.redirectToCheckout({
          sessionId: response.data.id,
        })
      })
  },
}
</script>

<template>
  <section class="ventes">
    <h1 class="ventes__title">{{ $t('navigation.past_auctions') }}</h1>
    <ul class="ventes__years-list">
      <li class="vantes__years-item" v-for="year in years">
        <router-link
          class="link"
          :class="{ 'link--active': year === $route.params.year }"
          :to="{ name: 'app.past_auctions.list', params: { year: year } }"
        >
          {{ year }}
        </router-link>
      </li>
    </ul>
    <router-view :key="$route.fullPath"></router-view>
  </section>
</template>

<script>
export default {
  name: 'past-auctions',
  data() {
    return {
      years: this.getYears(),
    }
  },
  methods: {
    getYears() {
      const years = []
      let year = this.moment().year()
      const lastYear = 2014

      while (year >= lastYear) {
        years.push(year--)
      }

      return years
    },
  },
}
</script>

<style scoped>
.link {
  cursor: pointer;
}
</style>

<template>
  <div>
    <div class="mock-container" v-if="authError">
      <label>{{ $t('live.no_permission') }}</label>
    </div>
    <div class="auction" v-if="!authError">
      <div class="auction__header">
        <h1 class="vente__title" v-html="auction.translation.title" />
        <ul class="vente__descr-list">
          <li class="vente__descr-item">
            <svg
              v-svg
              symbol="icon-calendar"
              size="18 18"
              class="vente__descr-icon"
            ></svg>
            {{ getAuction(auction.started_at) }}
          </li>
          <li class="vente__descr-item">
            <svg
              v-svg
              symbol="icon-map-marker"
              size="18 18"
              class="vente__descr-icon"
            ></svg>
            {{ auction.location }}
          </li>
        </ul>
        <div v-if="auction.data.live_streaming.on_air" class="auction-status">
          Live
        </div>
      </div>
      <div class="auction__section">
        <div class="auction__video-wrap">
          <div class="auction__video">
            <youtube
              :video-id="auction.data.live_streaming.youtube_stream_id"
              :player-width="playerWidth"
              :player-height="playerHeight"
              :player-vars="{ autoplay: 1 }"
              @ready="ready"
            />
          </div>
          <div class="auction__video-controller">
            <div class="row" v-if="youtubeConfigs.access_token">
              <div class="col col-25">
                <router-link
                  :to="{ name: 'app.admin_auction' }"
                  class="auction__video-controller-button"
                >
                  {{ $t('live.back_to_auction') }}
                </router-link>
              </div>
              <div class="col col-25">
                <button
                  class="button"
                  v-if="!auction.data.live_streaming.on_air"
                  @click="start()"
                >
                  Start
                  <img v-if="isLoading" src="/img/loader.svg" alt="" />
                </button>
                <button
                  class="button"
                  v-if="auction.data.live_streaming.on_air"
                  @click="stop()"
                >
                  Stop
                  <img v-if="isLoading" src="/img/loader.svg" alt="" />
                </button>
              </div>
            </div>
            <div v-if="!youtubeConfigs.access_token">
              <a :href="youtubeAuthUrl" class="button">Authorize in Youtube</a>
            </div>
          </div>
          <label class="has-error">{{ error }}</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isEmpty } from 'lodash'

export default {
  name: 'admin-auction-video',
  data() {
    return {
      error: null,
      authError: null,
      auction: {
        translation: {
          title: '',
        },
        data: {
          live_streaming: {
            on_air: false,
            youtube_stream_id: null,
            audio_on: false,
            video_on: false,
          },
        },
      },
      youtubeConfigs: {
        access_token: null,
      },
      youtubeAuthUrl: null,
      isLoading: false,
    }
  },
  created() {
    const self = this

    this.$api.getProfile().then((response) => {
      self.authError = response.data.role_id !== 1
    })

    this.$api
      .getOption('youtube')
      .then((value) => {
        self.youtubeConfigs = value

        if (!self.youtubeConfigs.access_token) {
          self.$store.commit('setRedirectBackUrl', location.pathname)

          return self.$api.getYoutubeAuthUrl()
        }
      })
      .then((response) => {
        if (response) {
          self.youtubeAuthUrl = response.data.url
        }
      })

    this.$api.getAuction(this.$route.params.slug).then((response) => {
      self.auction = response.data

      if (isEmpty(self.auction)) {
        self.auction = {
          lots: [],
        }
      }
      self.isLoaded = true
    })
  },
  methods: {
    ready(player) {
      this.player = player
    },
    getAuction(startedAt) {
      return this.$t('auctions.auction_date', {
        date: this.moment(startedAt).format('dddd DD MMMM YYYY'),
        to: this.moment(startedAt).format('LT'),
      })
    },
    start() {
      const self = this
      this.isLoading = true

      this.$api
        .startAuction(this.auction.id)
        .then((response) => {
          self.auction = response.data

          self.isLoading = false
        })
        .catch((error) => {
          if (error.response) {
            self.error =
              error.response.data.error || error.response.data.message
          } else {
            self.error = 'Something went wrong. Please contact your developers'
          }

          self.isLoading = false
        })
    },
    stop() {
      const self = this
      self.isLoading = true

      this.$api
        .stopAuction(this.auction.id)
        .then((response) => {
          self.auction = response.data

          self.isLoading = false
        })
        .catch((error) => {
          self.isLoading = false
          self.auction.data.live_streaming.on_air = false

          if (error.response.status === 401) {
            self.error = self.$t('live.youtube_stopping_error')
          }
        })

      this.player.stopVideo()
    },
  },
  computed: {
    playerWidth() {
      return window.innerWidth.toString() - 100
    },
    playerHeight() {
      return this.playerWidth * 0.6
    },
  },
}
</script>

<style scoped>
.button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button > img {
  height: 30px;
}
</style>

<template>
  <div class="adwords-modal" ref="adwords">
    <div class="adwords-form">
      <a @click.prevent="close()" class="adwords-form__close"> &times; </a>
      <p class="adwords-form__text">
        {{ $t('adwords.text') }}
      </p>
      <br />
      <form>
        <input
          v-model="request.email"
          class="adwords-form__input"
          type="text"
          placeholder="Email"
        />
        <br />
        <button
          class="adwords-form__button"
          type="submit"
          @click.prevent="submit()"
        >
          {{ $t('adwords.submit') }}
        </button>
        <p v-if="error" class="adwords-form__error">
          {{ error }}
        </p>
      </form>
    </div>
  </div>
</template>

<script>
import ApiService from './../../services/api.service'

export default {
  name: 'adwords-form',
  data() {
    return {
      request: {
        email: '',
      },
      error: null,
    }
  },
  mounted() {
    if (this.$route.fullPath.endsWith('ref=Adwords')) {
      setTimeout(() => {
        this.$refs.adwords.style.display = 'block'
      }, 3000)
    }
  },
  methods: {
    submit() {
      ApiService.createMailjetContact(this.request)
        .then(() => {
          ApiService.addMailjetContactToList(this.request).then(() => {
            this.close()
          })
        })
        .catch((e) => {
          this.error = e.response.data.errors.email[0]
        })
    },
    close() {
      this.request.email = ''
      this.error = null
      this.$refs.adwords.style.display = 'none'
    },
  },
}
</script>

<style scoped>
.adwords-modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}
.adwords-form {
  min-height: 180px;
  height: 260px;
  max-width: 800px;
  width: 60%;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 60px 20px;
}
.adwords-form__close {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
.adwords-form__text {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  width: 70%;
  text-align: center;
  margin: auto;
}
.adwords-form__input {
  width: 70%;
  background: rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  font-style: normal;
  font-weight: 500;
  padding: 0 10px;
  border-width: 0;
  border-bottom: 2px solid #8b8b8b;
  font-size: 18px;
  color: #000;
  outline: none;
  height: 31px;
  line-height: 1.5;
  transition: all 0.2s;
}
.adwords-form__button {
  box-sizing: border-box;
  font-style: normal;
  width: 70%;
  margin-top: 20px;
  height: 51px;
  line-height: 47px;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  background-color: transparent;
  color: #363636;
  border: 2px solid #363636;
  padding: 0 12px;
  cursor: pointer;
  transition: all 0.2s;
}
.adwords-form__button:hover {
  background-color: #363636;
  color: white;
}
.adwords-form__error {
  color: red;
  font-size: 16px;
  margin-top: 10px;
}
@media only screen and (max-width: 769px) {
  .adwords-form__text,
  .adwords-form__error {
    font-size: 2rem;
  }
}
</style>

<template>
  <div>
    <div class="loader" v-if="!isLoaded">
      <img src="/img/loader.svg" alt="" />
    </div>
    <img
      class="page-main__header-img"
      src="/img/23-1.jpg"
      srcset="/img/23-1@2x.jpg 2x, /img/23-1@3x.jpg 3x"
      alt=""
    />

    <section class="propos" v-if="isLoaded">
      <breadcrumbs :steps="getBreadcrumbs()" context="context" />
      <article class="propos__info">
        <div class="row">
          <div class="col col-50">
            <h1 class="propos__title">{{ collection.translation.title }}</h1>
          </div>
          <div class="col col-50">
            <p class="propos__info" v-html="collection.translation.content"></p>
          </div>
        </div>
      </article>

      <div class="ventes__header ventes__long request__estimation">
        <a
          href="mailto:collection@piasa.fr"
          class="link link--underline propos__subtitle"
          >{{ $t('collections.make_an_appointment') }}</a
        >
      </div>

      <div v-if="collection.news.length > 0">
        <div class="news__header">
          <h2 class="news__subtitle">{{ $t('collections.news') }}</h2>
        </div>
        <div class="news__list">
          <div class="row">
            <news-item
              v-for="news in collection.news"
              :key="news.id"
              :news="news"
              class="col col-25"
            >
            </news-item>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div v-if="collection.employees.length > 0">
        <div class="specialites__header">
          <h2 class="specialites__subtitle">
            {{ $t('categories.specialists') }}
          </h2>
        </div>
        <div class="specialites__list">
          <div class="row">
            <specialist-item
              v-for="specialist in collection.employees"
              :key="specialist.id"
              :specialist="specialist"
              class="col col-20"
            >
            </specialist-item>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Breadcrumbs from '../layout/Breadcrumbs'
import SpecialistItem from '../categories/common/SpecialistItem'
import NewsItem from '../artists/common/NewsItem'

export default {
  components: {
    Breadcrumbs,
    SpecialistItem,
    NewsItem,
  },
  name: 'collection-details',
  metaInfo() {
    return {
      name: this.collection.translation.title,
    }
  },
  data() {
    return {
      isLoaded: false,
      collection: {
        translation: {
          title: '',
          content: '',
        },
      },
    }
  },
  created: function () {
    const self = this
    self.isLoaded = false

    this.$api.getCollections(this.$route.params.slug).then((response) => {
      self.collection = response.data

      self.isLoaded = true
    })
  },
  methods: {
    getBreadcrumbs() {
      return [
        {
          to: {
            name: 'app.collections.details',
            params: {
              slug: this.collection.slug,
            },
          },
          title: this.collection.translation.title,
        },
      ]
    },
    scrollMeTo(refName) {
      const element = this.$refs[refName]
      const top = element.offsetTop

      window.scrollTo(0, top)
    },
  },
}
</script>

<style scoped>
.propos__title {
  margin-bottom: 2rem;
}

.request__estimation {
  padding: 65px 0;
  text-align: center;
}

.show_more {
  font-size: 10px;
  text-transform: uppercase;
}

.specialites__header {
  border-top: solid 1px #e8e8e8;
}

.specialites__subtitle {
  text-transform: uppercase;
}

.news__subtitle {
  text-transform: uppercase;
}

.loader {
  text-align: center;
  top: 200px;
  width: 50px;
  min-height: 500px;
  margin: auto;
  position: relative;
}
</style>

import VuePusher from '../services/pusher.service'

export default {
  props: {
    $pusher: {
      type: Object,
      default: () => {
        return new VuePusher(process.env.VUE_APP_PUSHER_APP_KEY, {
          cluster: process.env.VUE_APP_PUSHER_CLUSTER,
          encrypted: true,
        })
      },
    },
  },
  beforeDestroy() {
    this.$pusher.disconnect()
  },
}

<template>
  <section class="ventes">
    <h1 class="ventes__title">
      {{ searchResults.total }} {{ $t('products.search_results') }} :
      {{ searchFilters.query }}
    </h1>

    <header class="ventes__header">
      <div class="ventes__control">
        <div class="row">
          <div class="col col-25">
            <piasa-selector
              :placeholder="$t('products.category')"
              :items="categories"
              label-key="translation.title"
            ></piasa-selector>
          </div>
        </div>
      </div>
    </header>

    <div v-for="(product, index) in products">
      <div class="ventes__upcoming" v-if="needToShowUpcomingDelimeter(index)">
        {{ $t('products.upcoming') }}
      </div>
      <div class="ventes__upcoming" v-if="needToShowPastDelimeter(index)">
        {{ $t('products.past') }}
      </div>
      <product-item :product="product" :key="product.id"></product-item>
    </div>
    <infinite-loading
      ref="loader"
      @infinite="loadMore"
      spinner="waveDots"
      :distance="0"
    >
      <span slot="no-more"> </span>

      <span slot="no-results">
        {{ $t('products.not_found') }}
      </span>
    </infinite-loading>
  </section>
</template>

<script>
import PiasaSelector from '../Inputs/PiasaSelector'
import ProductItem from './common/ProductItem'
import InfiniteLoading from 'vue-infinite-loading'
import { merge, pickBy, isNull, clone, isEmpty } from 'lodash'

export default {
  components: {
    ProductItem,
    PiasaSelector,
    InfiniteLoading,
  },
  name: 'search-results',
  metaInfo() {
    return {
      title: 'Search products',
    }
  },
  data() {
    return {
      searchResults: {
        total: 0,
        data: [],
      },
      products: [],
      categories: [],
      searchFilters: {
        query: this.$route.query.query,
        page: 1,
        order_by: 'auction_date',
        desc: 1,
        with: ['lots.auction.categories.translation'],
      },
    }
  },
  created: function () {
    const self = this

    this.$api.searchProducts(this.searchFilters).then((response) => {
      self.searchResults = response.data
    })

    this.$api
      .searchCategories({ all: 1, order_by: 'title', desc: 0 })
      .then((response) => {
        self.categories = response.data
      })
  },
  methods: {
    search(newFilters) {
      this.searchFilters = merge(this.searchFilters, newFilters)

      this.searchFilters = pickBy(this.searchFilters, (value) => {
        return !isNull(value)
      })

      this.searchFilters.page = 1

      this.$refs.auctionList.clear()
    },
    loadMore($state) {
      const self = this

      this.$api.searchProducts(clone(this.searchFilters)).then((response) => {
        self.searchResults = response.data

        this.products = this.products.concat(self.searchResults.data)

        if (isEmpty(self.searchResults.data)) {
          $state.complete()
        } else {
          $state.loaded()
        }
      })

      this.searchFilters.page++
    },
    needToShowUpcomingDelimeter(index) {
      return (
        index === 0 &&
        !this.moment(
          this.products[index].lots[0].auction.finished_at
        ).isBefore()
      )
    },
    needToShowPastDelimeter(index) {
      return (
        this.moment(
          this.products[index].lots[0].auction.finished_at
        ).isBefore() &&
        (index === 0 ||
          !this.moment(
            this.products[index - 1].lots[0].auction.finished_at
          ).isBefore())
      )
    },
  },
}
</script>

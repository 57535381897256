<template>
  <div class="auction">
    <online-auction-header :auction="auction" :user="user" />

    <div
      v-if="
        (auction.data.live_streaming &&
          (!isSubscribed || !user.profile.is_confirmed)) ||
        (auction.data.live_streaming &&
          !auction.data.live_streaming.on_air &&
          user.profile.is_confirmed &&
          isSubscribed)
      "
    >
      <auction-notification
        :auction="auction"
        :user="user"
        :is-loaded="isLoaded"
        @subscribed="loadAuction()"
      />
    </div>

    <div
      v-if="
        auction.data.live_streaming &&
        auction.data.live_streaming.on_air &&
        isLoaded &&
        isSubscribed &&
        user.profile.is_confirmed
      "
    >
      <section class="auction__section">
        <div class="row">
          <div class="col col-50">
            <youtube
              :video-id="auction.data.live_streaming.youtube_stream_id"
              :player-vars="{ autoplay: 1 }"
              player-width="100%"
            />
          </div>
          <div class="col col-50">
            <current-lot
              :auction="auction"
              :currentLot="0"
              v-if="auction.lots.length"
            />
          </div>
        </div>
      </section>
      <section class="auction__section">
        <div class="row">
          <div class="col col-50">
            <client-bid-managing
              :auction="auction"
              :is-bid-button-blocked="isBidButtonBlocked"
              :quick-bid-amount="quickBidAmount"
              :lot-id="lotId"
            />
            <client-auction-information
              :auction="auction"
              :user="user"
              :bestBidderId="bestBidderId"
              :isBestBidder="isBestBidder"
              :isRoomBid="isRoomBid"
            />
          </div>
          <div class="col col-50">
            <bids-list :auction="auction" :user="user" />
          </div>
        </div>
      </section>
      <live-streaming-lots-list
        :auction="auction"
        @lot-selected="$router.push(lotRedirect(arguments[0]))"
      />
    </div>
  </div>
</template>

<script>
import { isEmpty } from 'lodash'
import BidsMixin from './mixins/bids.mixin'
import BidsList from './common/BidsList'
import ClientAuctionInformation from './common/ClientAuctionInformation'
import LiveStreamingLotsList from './common/LiveStreamingLotsList'
import CurrentLot from './common/CurrentLot'
import ClientBidManaging from './common/ClientBidManaging'
import OnlineAuctionHeader from './common/OnlineAuctionHeader'
import moment from 'moment'
import AuctionNotification from './common/AuctionNotification'
import PusherMixin from './../../mixins/pusher.mixin'

export default {
  components: {
    AuctionNotification,
    BidsList,
    OnlineAuctionHeader,
    ClientAuctionInformation,
    ClientBidManaging,
    CurrentLot,
    LiveStreamingLotsList,
  },
  name: 'client-auction',
  metaInfo() {
    return {
      title: this.auction.translation.title.replace(/<[^>]*>?/gm, ''),
    }
  },
  mixins: [BidsMixin, PusherMixin],
  data() {
    return {
      isLoaded: false,
      isBidButtonBlocked: false,
      nextYoutubeVideo: null,
      user: {
        id: null,
        auction_subscriptions: [],
        rib: {
          media: null,
        },
        passport: {
          media: null,
        },
      },
      auction: {
        translation: {
          title: '',
        },
        data: {
          live_streaming: {
            on_air: false,
            youtube_stream_id: null,
            current_lot: null,
            minimum_rate_increase: null,
            current_price: null,
          },
        },
        lots: [],
        online_bids: [],
      },
    }
  },
  mounted() {
    const self = this

    this.loadUser()
    this.loadAuction().then(() => {
      self.$pusher
        .subscribe('auctions.' + self.auction.id)
        .bind('start', () => {
          self.loadAuction()
        })
        .bind('stop', () => {
          self.loadAuction()
        })
        .bind('bids.new', () => {
          self.loadAuction()
        })
        .bind('bids.cancel', () => {
          self.loadAuction()
        })
        .bind('changed_price', (data) => {
          self.auction.data.live_streaming.current_price = data.price

          if (data.submittedBid) {
            self.submitBid(data.submittedBid.id)
          }
          self.loadAuction()
        })
        .bind('changed_minimum_rate_increase', (data) => {
          self.auction.data.live_streaming.minimum_rate_increase = data.value
        })
        .bind('changed_youtube_stream', (data) => {
          this.auction.data.live_streaming.youtube_stream_id = data.stream_id
        })
        .bind('lots.sold', (data) => {
          if (data.next_lot) {
            self.loadAuction()
          } else {
            self.stopAuction()
          }
        })
        .bind('lots.skip', () => {
          self.loadAuction()
        })
        .bind('fair_warning', () => {
          self.loadAuction()
        })
    })
  },
  methods: {
    loadAuction() {
      const self = this

      self.isBidButtonBlocked = true
      return this.$api.getAuction(this.$route.params.slug).then((response) => {
        self.auction = response.data

        if (isEmpty(self.auction)) {
          self.auction = {
            lots: [],
          }
        }
        self.isLoaded = true
        self.isBidButtonBlocked = false
      })
    },
    loadUser() {
      const self = this

      return this.$api.getProfile().then((response) => {
        self.user = response.data
        self.user.rib = self.user.rib === null ? { media: null } : self.user.rib
        self.user.passport =
          self.user.passport === null ? { media: null } : self.user.passport
      })
    },
    getAuction(startedAt) {
      return this.$t('auctions.auction_date', {
        date: this.moment(startedAt).format('dddd DD MMMM YYYY'),
        to: this.moment(startedAt).format('LT'),
      })
    },
    stopAuction() {
      this.auction.finished_at = moment.now()
    },
    lotRedirect(lot) {
      return {
        name: 'app.products.details',
        params: {
          slug: lot.product.slug,
        },
      }
    },
  },
  computed: {
    quickBidAmount() {
      const bestBidAmount = this.bestBidAmount
      const actualCurrentBid =
        bestBidAmount > this.currentPrice ? bestBidAmount : this.currentPrice

      return actualCurrentBid + this.minimumRateIncrease
    },
    lotId() {
      const lotNumber = this.auction.data.live_streaming.current_lot

      return this.auction.lots.find((lot) => lot.lot_number === lotNumber).id
    },
    bestBidderId() {
      const bestUserBid = this.bestUserBid
      const currentPrice = this.auction.data.live_streaming.current_price

      return bestUserBid && bestUserBid.price === currentPrice
        ? bestUserBid.user_id
        : undefined
    },
    isBestBidder() {
      return this.bestBidderId === this.user.id
    },
    currentPrice() {
      return this.auction.data.live_streaming.current_price || 0
    },
    minimumRateIncrease() {
      const minimumRateIncrease = parseInt(
        this.auction.data.live_streaming.minimum_rate_increase
      )

      return minimumRateIncrease || 0
    },
    isSubscribed() {
      return this.user.auction_subscriptions.some(
        (auction) =>
          this.auction.id === auction.id && auction.pivot.is_confirmed
      )
    },
  },
}
</script>

<template>
  <section class="orders">
    <div class="container">
      <div class="orders__header">
        <h1>{{ $t('purchase.title') }}</h1>
        <p v-if="$t('purchase.description')">
          {{ $t('purchase.description') }}
        </p>
      </div>
      <div class="orders__list">
        <purchase-item
          v-for="(bid, index) in bidsList"
          :bid="bid"
          :key="index"
        ></purchase-item>

        <infinite-loading
          ref="loader"
          @infinite="loadMore(arguments[0])"
          spinner="waveDots"
          :distance="1000"
          v-if="hasMore"
        >
          <span slot="no-more"></span>
        </infinite-loading>
      </div>
      <a class="orders__refresh" @click="refreshBids">
        <img src="~@/assets/img/refresh.png" alt="Refresh" />
      </a>
    </div>
  </section>
</template>

<script>
import PurchaseItem from './common/PurchaseItem'
import InfiniteLoading from 'vue-infinite-loading'
import { clone, isEmpty } from 'lodash'

export default {
  components: {
    PurchaseItem,
    InfiniteLoading,
  },
  name: 'purchase',
  metaInfo() {
    return {
      title: 'Purchase',
    }
  },
  data() {
    return {
      bidsList: [],
      hasMore: true,
      searchFilters: {
        per_page: 20,
        desc: 1,
        page: 1,
        with: [
          'lot.product.translation',
          'lot.product.media',
          'lot.auction.employees',
        ],
      },
    }
  },
  methods: {
    loadMore($state) {
      const self = this

      this.$api.searchBids(clone(this.searchFilters)).then((response) => {
        self.addItems(response.data.data, $state)
      })

      this.searchFilters.page++
    },
    addItems(items, $state) {
      this.bidsList = this.bidsList.concat(items)

      if (isEmpty(items)) {
        $state.complete()
      } else {
        $state.loaded()
      }
    },
    refreshBids() {
      const self = this
      this.searchFilters.page = 1

      this.$api.searchBids(clone(this.searchFilters)).then((response) => {
        self.addItems(response.data.data)
      })

      window.scrollTo({
        top: 100,
        left: 100,
        behavior: 'smooth',
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.orders {
  position: relative;

  &__refresh {
    position: absolute;
    bottom: -50px;
    left: 50%;
    transform: translateX(-50%);

    img {
      transition: transform 0.3s;
    }

    &:hover img {
      transform: rotateZ(-90deg);
    }
  }
}
</style>

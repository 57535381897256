import Vue from 'vue'
import App from './App.vue'
import router from './router'
import HttpService from './services/http.service'
import store from './services/store.service'
import axios from 'axios'
import i18n from './services/i18n.service'
import moment from 'moment'
import SvgSprite from 'vue-svg-sprite'
import ApiMixin from './mixins/api.mixin'
import ImagesMixin from './mixins/images.mixin'
import VueTruncate from 'vue-truncate-filter'
import VModal from 'vue-js-modal'
import SocialSharing from 'vue-social-sharing'
import AddToCalendar from 'vue-add-to-calendar'
import Viewer from 'v-viewer'
import lineClamp from 'vue-line-clamp'
import Meta from 'vue-meta'
import VueYouTubeEmbed from 'vue-youtube-embed'
import VueScrollTo from 'vue-scrollto'

Vue.use(VueScrollTo)
Vue.use(SvgSprite, {
  url: '/img/sprite.svg',
  class: 'icon',
})

Vue.use(lineClamp, {})
Vue.use(VueTruncate)
Vue.use(AddToCalendar)
Vue.use(VModal, { dialog: true })
Vue.use(SocialSharing)
Vue.use(Meta)
Vue.use(VueYouTubeEmbed)

Vue.config.env = process.env

moment.locale(i18n.locale)

Vue.prototype.store = store
Vue.prototype.$http = axios

HttpService.init()

Vue.mixin(ApiMixin)
Vue.mixin(ImagesMixin)

Vue.use(Viewer)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  VModal,
  render: (h) => h(App),
}).$mount('#app')

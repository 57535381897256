<template>
  <div>
    <div class="loader" v-if="!isLoaded">
      <img src="/img/loader.svg" alt="" />
    </div>
    <section class="propos" v-if="isLoaded">
      <picture>
        <source
          :srcset="getSrcSet(news.media.link)"
          media="(max-width: 998px)"
        />
        <img
          class="page-main__header-img"
          :src="getResizedImageUrl(news.media.link, 'original')"
          :alt="news.media.name"
        />
      </picture>
      <breadcrumbs :steps="getBreadcrumbs()" context="context" />
      <article class="propos__info">
        <div class="row">
          <div class="col col-50">
            <div class="sticky">
              <span class="propos__date">
                {{
                  moment(news.publication_date)
                    .locale($i18n.locale)
                    .format('LL')
                }}
              </span>
              <h1 class="propos__title">{{ news.translation.title }}</h1>
              <portager
                :title="news.translation.title"
                :description="news.translation.description"
              />
              <div class="auction-block">
                <ul class="vente__info-list" v-if="news.auction">
                  <li class="vente__info-item">
                    <a
                      :href="
                        '/' +
                        $i18n.locale +
                        '/auctions/' +
                        news.auction.slug +
                        '#lots-list'
                      "
                      class="link link--underline"
                    >
                      {{ $t('auctions.show_lots') }}
                    </a>
                  </li>
                  <li class="vente__info-item" v-if="news.auction.free_catalog">
                    <a
                      :href="
                        $http.getBackendUrl() + news.auction.free_catalog.link
                      "
                      class="link link--underline"
                    >
                      {{ $t('auctions.catalog') }}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col col-50">
            <p class="propos__info" v-html="news.translation.content"></p>
          </div>
        </div>
      </article>
    </section>
  </div>
</template>

<script>
import Portager from '../layout/Portager'
import Breadcrumbs from '../layout/Breadcrumbs'

export default {
  components: {
    Breadcrumbs,
    Portager,
  },
  name: 'news-details',
  metaInfo() {
    return {
      title: this.news.translation.title,
      meta: [
        {
          name: 'description',
          content: this.news.translation.meta_description,
        },
      ],
    }
  },
  data() {
    return {
      isLoaded: false,
      news: {
        translation: {
          title: '',
          description: '',
          meta_title: '',
          meta_description: '',
        },
        media: {
          link: '',
        },
        auction: null,
      },
    }
  },
  created: function () {
    const self = this
    self.isLoaded = false

    this.$api.getNews(this.$route.params.id).then((response) => {
      self.news = response.data

      self.isLoaded = true
    })
  },
  methods: {
    getBreadcrumbs() {
      return [
        {
          to: {
            name: 'app.news.list',
          },
          title: this.$t('navigation.news'),
        },
        {
          to: {
            name: 'app.news.details',
            params: {
              id: this.$route.params.id,
            },
          },
          title: this.news.translation.title,
        },
      ]
    },
    isThereAnAuctionWithDate() {
      return this.news.auction && this.news.auction.started_at
    },
  },
}
</script>

<style scoped>
.propos__title {
  margin-bottom: 2rem;
}

.sticky {
  position: sticky;
  top: 2rem;
}

.auction-block {
  margin-top: 20px;
}

.loader {
  text-align: center;
  top: 200px;
  width: 50px;
  min-height: 500px;
  margin: auto;
  position: relative;
}
</style>

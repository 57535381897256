<template>
  <div>
    <div
      class="tabs__content-item tabs__content-item--active"
      v-if="!isSuccessful"
    >
      <div class="rating__form-section">
        <h2>{{ $t('estimation.information') }}</h2>
        <div class="row">
          <div class="col col-50">
            <div class="form__row">
              <piasa-selector
                :items="categories"
                @input="estimation.category_id = arguments[0].id"
                label-key="translation.title"
                :class="{ 'has-error': $v.estimation.category_id.$error }"
                :placeholder="$t('estimation.category')"
              >
              </piasa-selector>
            </div>
            <div class="form__row">
              <input
                class="form__input"
                type="text"
                v-model="estimation.title"
                :class="{ 'has-error': $v.estimation.title.$error }"
                :placeholder="$t('estimation.title')"
              />
            </div>
            <div class="form__row">
              <input
                class="form__input"
                type="text"
                v-model="estimation.dimensions"
                :class="{ 'has-error': $v.estimation.dimensions.$error }"
                :placeholder="$t('estimation.dimensions')"
              />
            </div>
          </div>
          <div class="col col-50">
            <textarea
              class="form__textarea"
              cols="30"
              rows="10"
              v-model="estimation.description"
              :class="{ 'has-error': $v.estimation.description.$error }"
              :placeholder="$t('estimation.information') + '*'"
            >
            </textarea>
          </div>
        </div>
      </div>
      <images-list
        v-model="estimation.media"
        :hasError="$v.estimation.media.$error"
      ></images-list>
      <div class="rating__form-footer">
        <div class="form__label-wrap">
          <input
            class="form__checkbox"
            type="checkbox"
            id="agree"
            v-model="agree"
          />
          <label
            class="form__label"
            for="agree"
            :class="{ 'has-error': !agree }"
          >
            {{ $t('estimation.agree') }}
          </label>
        </div>
        <div class="form__row">
          <button class="form__button button" @click="createEstimation()">
            {{ $t('estimation.send') }}
          </button>
        </div>
      </div>
    </div>
    <label v-if="isSuccessful" class="form__success"
      >Request successfully sent.<br />
      Please wait for answer</label
    >
  </div>
</template>

<script>
import PiasaSelector from '../Inputs/PiasaSelector'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import ImagesList from './common/ImagesList'

export default {
  components: {
    ImagesList,
    PiasaSelector,
  },
  name: 'estimation-request',
  mixins: [validationMixin],
  metaInfo() {
    return {
      title: 'Request Estimation',
    }
  },
  data() {
    return {
      categories: [],
      category: null,
      estimation: {
        category_id: null,
        media: [],
        title: '',
        dimensions: '',
        description: '',
      },
      agree: true,
      isSuccessful: false,
    }
  },
  validations: {
    estimation: {
      category_id: { required },
      media: { required },
      title: { required },
      description: { required },
      dimensions: { required },
    },
  },
  created: function () {
    const self = this

    this.$api
      .searchCategories({ all: 1, order_by: 'title', desc: 0 })
      .then((response) => {
        self.categories = response.data
      })
  },
  methods: {
    createEstimation() {
      this.$v.$touch()

      if (this.$v.$error || !this.agree) {
        return
      }

      const self = this

      this.$api.createEstimation(this.estimation).then(() => {
        self.isSuccessful = true
      })
    },
  },
}
</script>

<style scoped>
label.form__success {
  text-align: center;
  display: block;
}
</style>

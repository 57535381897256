<template>
  <div class="bid_online_wrapper">
    {{ $t('bid_online') }}
    <div id="bid-online"></div>
  </div>
</template>

<script>
export default {
  name: 'bid-online',
  metaInfo() {
    return {
      title: 'Bid online',
    }
  },
  mounted() {
    const bidScript = document.createElement('script')

    bidScript.setAttribute('id', '_afjs')
    bidScript.setAttribute('name', 'Piasa')
    bidScript.setAttribute('rel', 'https://Piasa.infinitebidding.com')
    bidScript.setAttribute(
      'src',
      'https://Piasa.infinitebidding.com/lib/syndicate/_af_include.js'
    )

    document.getElementById('bid-online').appendChild(bidScript)
  },
}
</script>

<style scoped>
.bid_online_wrapper {
  padding-top: 30px;
}
</style>

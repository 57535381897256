<template>
  <div class="auction__bid-col">
    <div class="auction__bid-current">
      <div class="auction__bid-current-text">
        {{ $t('live.current_auction') }}
      </div>
      <input
        class="auction__bid-current-text invisible_input price_input"
        type="text"
        maxlength="10"
        oninput="this.value=this.value.replace(/[^0-9]+/g,'');"
        v-if="priceEditMode"
        :value="price"
        ref="priceInput"
        @input="price = $event.target.value"
        @keyup.enter="submit()"
        @keyup.escape="cancel()"
      />
      <div class="auction__bid-current-value" v-if="!priceEditMode">
        {{ value }} {{ currency }}
      </div>
    </div>
    <div class="auction__bid-modify">
      <a
        class="link link--underline"
        v-if="!priceEditMode"
        @click="changeMode()"
      >
        {{ $t('live.change_price') }}
      </a>
      <a class="link link--underline" v-if="priceEditMode" @click="submit()">
        {{ $t('live.set_price') }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'lot-price-input',
  props: ['auction', 'value', 'increaseAmount'],
  data() {
    return {
      priceEditMode: false,
      price: this.value,
    }
  },
  computed: {
    currency() {
      if (this.$route.name === 'app.admin_auction') {
        return this.auction.id === 330 ? 'ZAR' : '€'
      }
      return '€'
    },
  },
  methods: {
    submit() {
      const numericRate = Number(this.price)
      if (numericRate) {
        this.$emit('input', numericRate)
      }

      this.priceEditMode = false
    },
    cancel() {
      this.price = this.value
      this.priceEditMode = false
    },
    increase() {
      this.price = parseInt(this.price) + parseInt(this.increaseAmount)
    },
    changeMode() {
      this.priceEditMode = !this.priceEditMode

      if (this.priceEditMode) {
        this.$nextTick(function () {
          this.$refs.priceInput.focus()
          this.increase()
        })
      }
    },
  },
  mounted() {
    window.addEventListener('keyup', (e) => {
      if (e.keyCode === 13) this.changeMode()
      if (e.keyCode === 27) this.priceEditMode = false
    })
  },
  watch: {
    value: function (newValue) {
      if (!this.priceEditMode) {
        this.price = newValue
      }
    },
  },
}
</script>

<template>
  <div>
    <div class="ventes__links">
      <router-link class="ventes__link" :to="{ name: 'app.auctions' }"
        >{{ $t('my_bidding.title') }}
      </router-link>
      <h1 class="ventes__link active">{{ $t('navigation.my_purchases') }}</h1>
    </div>
    <div class="loader placeholder" v-if="!isLoaded">
      <img src="/img/loader.svg" alt="Loader" />
    </div>
    <div
      v-if="isLoaded"
      v-for="(product, index) in products"
      :key="index"
      class="bid"
    >
      <img
        :src="getResizedImageUrl(product.media[0].link, '625x500')"
        alt="Piasa"
        class="bid__image"
      />
      <div class="bid__right">
        <div class="bid__top">
          <p class="bid__id">{{ product.lots[0].lot_number }}</p>
        </div>
        <p class="bid__title">
          {{
            product.translation.name
              ? product.translation.name
              : $t('purchase.no.info')
          }}
        </p>
        <div
          class="bid__description"
          v-html="
            product.translation.description
              ? product.translation.description
              : $t('purchase.no.description')
          "
        ></div>
        <div class="bid__bottom">
          <div class="bid__bottom-left">
            <p class="bid__est">
              {{ $t('products.result') }} :
              {{
                product.bestBidAmount +
                (product.bestBidAmount / 100) *
                  product.lots[0].auction.data.live_streaming.piasa_fee
              }}
              €
            </p>
          </div>
        </div>
      </div>
      <a class="bid__link">
        <svg
          width="16"
          height="13"
          viewBox="0 0 16 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.3825 8.75H15.0075V6.21875C15.0075 5.9375 14.8668 5.63281 14.6559 5.42188L12.3356 3.10156C12.1247 2.89062 11.82 2.75 11.5387 2.75H10.5075V1.625C10.5075 1.01562 9.99184 0.5 9.38247 0.5H3.38247C2.74965 0.5 2.25747 1.01562 2.25747 1.625V2.75H0.944967C0.82778 2.75 0.757467 2.84375 0.757467 2.9375V3.3125C0.757467 3.42969 0.82778 3.5 0.944967 3.5H7.31997C7.41372 3.5 7.50747 3.59375 7.50747 3.6875V4.0625C7.50747 4.17969 7.41372 4.25 7.31997 4.25H1.69497C1.57778 4.25 1.50747 4.34375 1.50747 4.4375V4.8125C1.50747 4.92969 1.57778 5 1.69497 5H6.56997C6.66372 5 6.75747 5.09375 6.75747 5.1875V5.5625C6.75747 5.67969 6.66372 5.75 6.56997 5.75H0.944967C0.82778 5.75 0.757467 5.84375 0.757467 5.9375V6.3125C0.757467 6.42969 0.82778 6.5 0.944967 6.5H5.81997C5.91372 6.5 6.00747 6.59375 6.00747 6.6875V7.0625C6.00747 7.17969 5.91372 7.25 5.81997 7.25H2.25747V10.25C2.25747 11.4922 3.26528 12.5 4.50747 12.5C5.74965 12.5 6.75747 11.4922 6.75747 10.25H9.75747C9.75747 11.4922 10.7653 12.5 12.0075 12.5C13.2497 12.5 14.2575 11.4922 14.2575 10.25H15.3825C15.57 10.25 15.7575 10.0859 15.7575 9.875V9.125C15.7575 8.9375 15.57 8.75 15.3825 8.75ZM4.50747 11.375C3.87465 11.375 3.38247 10.8828 3.38247 10.25C3.38247 9.64062 3.87465 9.125 4.50747 9.125C5.11684 9.125 5.63247 9.64062 5.63247 10.25C5.63247 10.8828 5.11684 11.375 4.50747 11.375ZM12.0075 11.375C11.3747 11.375 10.8825 10.8828 10.8825 10.25C10.8825 9.64062 11.3747 9.125 12.0075 9.125C12.6168 9.125 13.1325 9.64062 13.1325 10.25C13.1325 10.8828 12.6168 11.375 12.0075 11.375ZM13.8825 6.5H10.5075V3.875H11.5387L13.8825 6.21875V6.5Z"
            fill="#363636"
          />
        </svg>
        {{ $t('purchase.transport') }}
      </a>
    </div>
    <div v-if="isLoaded && !products.length" class="mock-container">
      {{ $t('profile.orders_not_found') }}
    </div>
  </div>
</template>

<script>
import { uniqBy, orderBy, last } from 'lodash'

export default {
  name: 'OrdersList',
  metaInfo() {
    return {
      title: 'Piasa',
    }
  },
  data() {
    return {
      products: [],
      isLoaded: false,
    }
  },
  mounted() {
    this.$api
      .searchBids({ user_id: this.$store.state.user.id, per_page: 100000 })
      .then((response) => {
        const bids = uniqBy(response.data.data, 'product_id')
        bids.forEach((bid) => {
          this.$api.getProduct(bid.product_id).then((response) => {
            const product = response.data
            if (
              last(orderBy(product.bids, 'price', 'asc')).price === bid.price &&
              this.moment(product.lots[0].auction.finished_at).isBefore()
            ) {
              product.bestBidAmount = bid.price
              this.products.push(product)
            }
          })
        })
        this.isLoaded = true
      })
  },
}
</script>

<style lang="scss" scoped>
.ventes {
  padding-top: 0;

  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: 52px;
    line-height: 46px;
    text-transform: none;
    padding: 127px 0 151px 0;
    margin-bottom: 4rem;
  }
}

/*ventes__link ventes__title*/
.ventes {
  &__links {
    display: flex;
    justify-content: center;
    font-size: 5rem;
    font-weight: 700;
    padding-top: 5rem;
    border-bottom: solid 1px #e8e8e8;
  }

  &__link {
    color: #8b8b8b;
    transition: all 0.2s;
    cursor: pointer;
    padding: 0 1rem;

    &:hover {
      color: #000;
    }

    &.active {
      color: #000;
      cursor: auto;
    }
  }
}

.bid {
  display: flex;
  padding: 36px 29px 36px 51px;
  position: relative;

  @media screen and (max-width: 1024px) {
    display: block;
    padding: 36px 25px 36px 31px;
  }

  &__image {
    width: 297px;
    height: 297px;
    object-fit: cover;

    @media screen and (max-width: 1024px) {
      width: unset;
      max-width: 100%;
      height: unset;
    }
  }

  &__right {
    width: 100%;
    margin-left: 36px;
    margin-right: 160px;

    @media screen and (max-width: 1024px) {
      margin-left: 0;
    }
  }

  &__link {
    width: 140px;
    height: 31px;
    border: 2px solid #8b8b8b;
    box-sizing: border-box;
    font-family: 'Futura';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #363636;
    background: #fff;
    white-space: nowrap;
    transition: all 0.25s;
    position: absolute;
    right: 0;
    bottom: 125px;

    @media screen and (max-width: 1024px) {
      position: unset;
    }

    svg {
      margin: 0 5px;

      path {
        transition: all 0.25s;
      }
    }

    &:hover {
      background: #363636;
      color: #fff;

      svg path {
        fill: #fff;
      }
    }
  }

  &__top {
    width: 100%;
    display: flex;

    @media screen and (max-width: 1024px) {
      display: block !important;
    }
  }

  &__id {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    color: #8b8b8b;
    margin-bottom: 11px;
  }

  &__counter {
    font-size: 20px;
    line-height: 26px;
    text-align: right;
    color: #000000;
    margin-left: auto;
    margin-bottom: 11px;

    @media screen and (max-width: 1024px) {
      font-size: 12px;
      text-align: left;
      width: 100%;
    }
  }

  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #000000;
    margin-bottom: 11px;

    @media screen and (max-width: 1024px) {
      font-size: 30px;
    }
  }

  &__description {
    font-size: 18px;
    line-height: 26px;
    color: #000000;
    margin-bottom: 8px;
    overflow: hidden;
    width: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }

  &__bottom {
    display: flex;

    @media screen and (max-width: 1024px) {
      display: block;
    }
  }

  &__bottom-right {
    margin-left: auto;
  }

  &__est {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #8b8b8b;
    margin-bottom: 7px;

    @media screen and (max-width: 1024px) {
      font-size: 16px;
    }

    span {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 26px;
      color: #4a4a4a;
      margin-left: 57px;

      @media screen and (max-width: 1024px) {
        font-size: 16px;
        margin-left: 25px;
      }
    }
  }

  &__current {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #000000;
    display: flex;

    @media screen and (max-width: 1024px) {
      font-size: 16px;
    }
  }

  &__best {
    width: 247px;
    height: 26px;
    background: #0db78d;
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    margin-left: 20px;
  }

  &__button {
    width: 134px;
    height: 31px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #ffffff;
    background: #363636;

    &:hover {
      color: #363636;
      background: #ffffff;
    }

    svg {
      margin-right: 4px;
      transition: all 0.2s;
    }

    &--light {
      color: #363636;
      background: #ffffff;
      margin-top: 20px;

      &:hover {
        color: #ffffff;
        background: #363636;

        & svg path {
          fill: #fff;
        }
      }
    }
  }
}
</style>

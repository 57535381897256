<template>
  <section class="container">
    <div class="product">
      <div class="loader" v-if="!isLoaded">
        <img src="/img/loader.svg" alt="" />
      </div>

      <div v-else class="product__content">
        <div class="row">
          <div class="col col-50">
            <image-slider :images="product.media" />
          </div>
          <div class="col col-50">
            <div class="product__controller controller">
              <lot-navigation
                :lot="product.previous_lot"
                direction="previous"
              />
              <product-breadcrumbs :steps="getBreadcrumbs()" align="center" />
              <lot-navigation :lot="product.next_lot" direction="next" />
            </div>
            <div class="counter" v-if="biddable && biddingInFuture">
              <h4>{{ $t('products.start_on') }}</h4>
              <span
                >{{
                  moment(auction.started_at).locale('fr').format('LLL')
                }}
                (UTC+1)</span
              >
            </div>
            <div class="counter" v-else-if="buyableOnline && biddingInFuture">
              <h4>{{ $t('products.start_on') }}</h4>
              <span
                >{{
                  moment(auction.started_at).locale('fr').format('LLL')
                }}
                (UTC+1)</span
              >
            </div>
            <div class="counter" v-if="biddable && biddingActive">
              <h4>{{ $t('products.time_left') }}</h4>
              <countdown
                :time="biddingEnd"
                :emit-events="true"
                @end="stopBidding"
              >
                <template slot-scope="props"
                  >{{ props.days }} j : {{ props.hours }} h :
                  {{ props.minutes }} min : {{ props.seconds }} sec
                </template>
              </countdown>
            </div>
            <div class="counter" v-else-if="buyableOnline && biddingActive">
              <h4>{{ $t('products.start_on') }}</h4>
              <countdown :time="biddingEnd">
                <template slot-scope="props"
                  >{{ props.days }} j : {{ props.hours }} h :
                  {{ props.minutes }} min : {{ props.seconds }} sec
                </template>
              </countdown>
            </div>
            <div class="product__info">
              <product-main-info
                :product="product"
                :auction="auction"
              ></product-main-info>

              <truncatable
                :text="product.translation.description"
                :textClass="'product__descr'"
                :showMore="$t('products.see_more')"
                :showMoreClass="'link  link--underline'"
                class="product__descr-wrap"
              >
              </truncatable>

              <strong v-if="estimationNotNull()" class="product__cost">
                {{ $t('products.estimation') }}: {{ product.min_estimation }}
                <span v-if="product.max_estimation">
                  / {{ product.max_estimation }}</span
                >
                {{ currency }}
              </strong>

              <h4 v-if="biddable && biddingActive && product.bids.length">
                <strong class="product__price">
                  {{ $t('products.current_price') }} :
                  {{ price(bestBidAmount) }} {{ currency }}
                </strong>
              </h4>

              <h4
                v-else-if="
                  biddable && biddingActive && product.bids.length === 0
                "
              >
                <strong class="product__price">
                  {{ $t('products.price2') }} : {{ price(startingPrice) }}
                  {{ currency }}
                </strong>
              </h4>

              <div class="product__cost-wrap">
                <div v-if="buyableOnline && product.price">
                  <strong class="product__price">
                    {{ $t('products.price2') }} : {{ price(product.price) }}
                    {{ currency }}
                  </strong>
                </div>
                <div v-else-if="biddable && biddingInFuture">
                  <strong class="product__price">
                    {{ $t('products.price2') }} : {{ price(startingPrice) }}
                    {{ currency }}
                  </strong>
                </div>
                <div v-else-if="biddingInPast && bestBidAmount && biddable">
                  <strong class="product__price">
                    {{ $t('products.result') }} :
                    {{
                      price(
                        bestBidAmount +
                          (bestBidAmount / 100) *
                            auction.data.live_streaming.piasa_fee
                      ) ||
                      price(
                        product.price +
                          (bestBidAmount / 100) *
                            auction.data.live_streaming.piasa_fee
                      )
                    }}
                    {{ currency }}
                  </strong>
                  <p class="price-info">* {{ $t('products.info') }}</p>
                </div>
                <div v-else-if="biddingInPast && bestBidAmount && !biddable">
                  <strong class="product__price">
                    {{ $t('products.result') }} :
                    {{ price(product.price) || price(bestBidAmount) }}
                    {{ currency }}
                  </strong>
                  <p class="price-info">* {{ $t('products.info') }}</p>
                </div>
              </div>

              <div
                class="highest__bidder"
                v-if="highestBidder && biddable && !biddingInFuture"
              >
                <i class="far fa-gem"></i> {{ $t('my_bidding.best_bid') }}
              </div>

              <div>
                <template v-if="auction.drouot_auction">
                  <button
                    v-if="!biddingInPast"
                    class="button"
                    @click="redirectToDrouotAuction()"
                  >
                    {{ $t('products.buy_on_drouot') }}
                  </button>
                </template>
                <template v-else>
                  <product-auth-notification
                    v-if="!userLoggedIn && !biddingInPast"
                    :is-loaded="isLoaded"
                  ></product-auth-notification>

                  <product-notification
                    v-if="
                      (userLoggedIn &&
                        biddable &&
                        !isSubscribed &&
                        !isConfirmed) ||
                      (userLoggedIn &&
                        biddable &&
                        isSubscribed &&
                        !isConfirmed) ||
                      (userLoggedIn && biddable && !isSubscribed && isConfirmed)
                    "
                    :product="product"
                    :auction="auction"
                    :user="user"
                    :is-loaded="isLoaded"
                    @subscribed="reloadUser"
                  ></product-notification>

                  <product-bidding-form
                    v-if="
                      userLoggedIn &&
                      isSubscribed &&
                      isConfirmed &&
                      biddingActive &&
                      !biddingLimitReached
                    "
                    :product="product"
                    :auction="auction"
                    :user="user"
                    :values="biddingValues"
                    :minimum-rate-increase="minimumRateIncrease"
                  ></product-bidding-form>

                  <button
                    class="button button__purchase button__purchase--white bid__button"
                    v-if="
                      !biddingInPast &&
                      !buyableOnline &&
                      !biddable &&
                      !bidsModalOpened
                    "
                    @click="bidsModalOpened = true"
                  >
                    {{ $t('auctions.bid') }}
                  </button>

                  <div
                    v-if="biddingActive && biddingLimitReached"
                    class="limit-info"
                  >
                    <h3>
                      {{ $t('ib.increase_limit_intro') }}
                      {{ user.profile.bid_limit }} {{ currency }}.
                      {{ $t('ib.increase_limit_contact') }}
                    </h3>
                    <button
                      class="button ib-button-limit-demand"
                      @click="conntactAskBid()"
                      :disabled="request_sent"
                    >
                      {{ $t('ib.ask') }}
                    </button>
                  </div>

                  <div class="row bid-options-modal" v-if="bidsModalOpened">
                    <div class="col col-100 modal-intro">
                      {{ $t('bid.title') }}
                      <a @click="bidsModalOpened = false">
                        <svg
                          width="15"
                          height="14"
                          viewBox="0 0 15 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M14.9179 1.41L13.514 0L7.94821 5.59L2.38241 0L0.978516 1.41L6.54432 7L0.978516 12.59L2.38241 14L7.94821 8.41L13.514 14L14.9179 12.59L9.35211 7L14.9179 1.41Z"
                            fill="#363636"
                          />
                        </svg>
                      </a>
                    </div>
                    <div class="col col-33">
                      <ul id="modal-navigation">
                        <li class="order active">
                          <a
                            href="javascript:"
                            @click="setActive('order', 'piasa-order')"
                            >{{ $t('bid.absentee.title') }}</a
                          >
                        </li>
                        <li class="telephone">
                          <a
                            href="javascript:"
                            @click="setActive('telephone', 'piasa-telephone')"
                            >{{ $t('bid.phone.title') }}</a
                          >
                        </li>
                        <li class="live">
                          <a
                            href="javascript:"
                            @click="setActive('live', 'piasa-live')"
                            >PIASA Live</a
                          >
                        </li>
                      </ul>
                    </div>
                    <div
                      class="col col-66 modal-content piasa-live"
                      style="display: block"
                    >
                      <p>
                        {{ $t('bid.live.text') }}
                      </p>
                      <a
                        class="button button__purchase button__purchase--white"
                        :href="getLiveAuctionRedirectData().path"
                      >
                        {{ $t('auctions.item.button_live') }}
                      </a>
                    </div>

                    <div class="col col-66 modal-content piasa-order">
                      <p>
                        {{ $t('bid.absentee.text-1') }}
                      </p>
                      <input
                        name="order-value"
                        class="bordered"
                        type="number"
                        :min="product.min_estimation"
                        v-model="purchaseOrderAmount"
                        placeholder="Votre enchère maximum (EUR)"
                      />
                      <button
                        class="button button__purchase button__purchase--white"
                        :disabled="orderPlaced"
                        @click="submitPurchaseOrder()"
                      >
                        {{ $t('auction_purchase.submit') }}
                      </button>
                      <p v-if="orderPlaced" class="success">Order placed!</p>
                      <p>
                        {{ $t('bid.absentee.text-2') }}
                      </p>
                    </div>

                    <div class="col col-66 modal-content piasa-telephone">
                      <p>
                        {{ $t('bid.phone.text-1') }}
                      </p>
                      <div class="row">
                        <div class="col col-25">
                          <input
                            class="form__input"
                            name="prefix"
                            type="text"
                            placeholder="+000"
                            v-model="purchaseOrderPrefix"
                          />
                        </div>
                        <div class="col col-50">
                          <input
                            class="form__input"
                            name="phone-number"
                            type="text"
                            placeholder="06 00 00 00 00"
                            v-model="purchaseOrderPhone"
                          />
                        </div>
                      </div>
                      <button
                        class="button button__purchase button__purchase--white"
                        :disabled="orderPlaced"
                        @click="submitPurchaseOrder()"
                      >
                        {{ $t('auction_purchase.submit') }}
                      </button>
                      <p v-if="orderPlaced" class="success">Order placed!</p>
                      <p>
                        {{ $t('bid.phone.text-2') }}
                      </p>
                    </div>
                  </div>

                  <ul
                    class="link__list link__list--horizontal"
                    style="display: none"
                  >
                    <li
                      class="link__item"
                      v-if="!biddingInPast && !buyableOnline && !biddable"
                    >
                      <router-link
                        class="link link--underline"
                        :to="{
                          name: 'app.auction.purchase.phone',
                          params: {
                            slug: auction.slug,
                            number: product.lots[0].lot_number,
                            lot: product.lots[0],
                          },
                        }"
                      >
                        {{ $t('products.phone_auction') }}
                      </router-link>
                    </li>
                  </ul>
                </template>
                <ul class="product__feedback">
                  <li v-if="!biddingInPast" class="product__feedback-item">
                    <condition-report-request
                      :lot-id="product.lots[0].id"
                      :auction="auction"
                      :product="product"
                    />
                  </li>
                </ul>
                <additional-product-information></additional-product-information>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import LotNavigation from './common/LotNavigation'
import ImageSlider from './common/ImageSlider'
import Truncatable from '../layout/Truncatable'
import ConditionReportRequest from './common/ConditionReportRequest'
import ProductMainInfo from './common/ProductMainInfo'
import { find, isEmpty, range, inRange, last } from 'lodash'
import VueCountdown from '@chenfengyuan/vue-countdown'
import Vue from 'vue'
import ProductBreadcrumbs from '@/components/layout/ProductBreadcrumbs'
import BidsMixin from '@/components/products/mixins/bids.mixin'
import AdditionalProductInformation from '@/components/products/common/AdditionalProductInformation'
import ProductNotification from '@/components/products/common/ProductNotification'
import ProductAuthNotification from '@/components/products/common/ProductAuthNotification'
import ProductBiddingForm from '@/components/products/common/ProductBiddingForm'
import VuePusher from '@/services/pusher.service'

Vue.component(VueCountdown.name, VueCountdown)

export default {
  components: {
    ProductBiddingForm,
    ProductAuthNotification,
    ProductNotification,
    AdditionalProductInformation,
    ProductBreadcrumbs,
    ConditionReportRequest,
    ImageSlider,
    LotNavigation,
    Truncatable,
    ProductMainInfo,
  },
  name: 'product-details',
  metaInfo() {
    return {
      title: this.product.translation.name.replace(/<[^>]*>?/gm, ''),
    }
  },
  mixins: [BidsMixin],
  data() {
    return {
      isLoaded: false,
      bidsModalOpened: false,
      purchaseOrderAmount: 0,
      purchaseOrderPrefix: '',
      purchaseOrderPhone: '',
      biddingValues: [],
      orderPlaced: false,
      minimumRateIncrease: 100,
      request_sent: false,
      auction: {
        translation: {
          title: '',
          description: '',
        },
        location: '',
        data: {
          publications: [],
          contact: {
            name: '',
            phone: '',
            email: '',
          },
        },
        media: [{ link: '' }],
        lots: [],
        started_at: null,
        finished_at: null,
        slug: '',
        drouot_auction: false,
        show_ending_date: false,
      },
      product: {
        translation: {
          name: '',
        },
        slug: '',
        price: null,
        min_estimation: null,
        max_estimation: null,
        media: [],
        lot: {
          lot_number: 1,
          drouot_url: null,
        },
        next_lot: null,
        previous_lot: null,
      },
      isBooked: false,
      conditionReportSuccess: null,
      user: {
        id: null,
        auction_subscriptions: [],
        product_subscriptions: [],
        product_limits: [],
        rib: {
          media: null,
        },
        passport: {
          media: null,
        },
      },
    }
  },
  computed: {
    currency() {
      return this.auction.id === 330 ? 'ZAR' : '€'
    },
    biddingLimitReached() {
      if (this.$store.state.token) {
        return this.directBid > parseInt(this.user.profile.bid_limit)
      }

      return false
    },
  },
  mounted: function () {
    const self = this
    this.loadProduct().then(() => {
      const pusher = new VuePusher(process.env.VUE_APP_PUSHER_APP_KEY, {
        cluster: process.env.VUE_APP_PUSHER_CLUSTER,
        encrypted: true,
      })
      pusher.subscribe('products.' + self.product.id).bind('bids.new', () => {
        self.reloadProduct()
      })
    })
  },
  methods: {
    reloadProduct() {
      const self = this
      return this.$api.getProduct(this.$route.params.slug).then((response) => {
        self.product = response.data
        this.getBiddingValues()
      })
    },
    reloadUser() {
      const self = this
      return this.$api.getProfile('undemanding').then((response) => {
        self.user = response.data
      })
    },
    loadProduct() {
      const self = this
      return this.$api.getProduct(this.$route.params.slug).then((response) => {
        self.product = response.data
        self.purchaseOrderAmount = self.product.min_estimation
        if (this.$store.state.token) this.loadOptions()
        this.loadAuction(response.data.lots[0].auction.slug)
      })
    },
    loadAuction(slug) {
      const self = this
      return this.$api.getAuction(slug).then((response) => {
        self.auction = response.data
        if (this.userLoggedIn) {
          this.loadUser()
        } else {
          self.isLoaded = true
        }
      })
    },
    loadUser() {
      const self = this
      return this.$api.getProfile('undemanding').then((response) => {
        self.user = response.data
        if (this.$store.state.token) {
          this.getBiddingValues()
        }
        self.user.rib = self.user.rib === null ? { media: null } : self.user.rib
        self.user.passport =
          self.user.passport === null ? { media: null } : self.user.passport
        self.isLoaded = true
      })
    },
    loadOptions() {
      this.$api.getOption('bid_limits', 'undemanding').then((response) => {
        if (inRange(this.product.price, 0, 500)) {
          this.minimumRateIncrease = parseInt(response['0 - 500'])
        } else if (inRange(this.product.price, 500, 1000)) {
          this.minimumRateIncrease = parseInt(response['500 - 1000'])
        } else if (inRange(this.product.price, 1000, 2000)) {
          this.minimumRateIncrease = parseInt(response['1000 - 2000'])
        } else if (inRange(this.product.price, 2000, 5000)) {
          this.minimumRateIncrease = parseInt(response['2000 - 5000'])
        } else if (inRange(this.product.price, 5000, 10000)) {
          this.minimumRateIncrease = parseInt(response['5000 - 10000'])
        } else if (inRange(this.product.price, 10000, 20000)) {
          this.minimumRateIncrease = parseInt(response['10000 - 20000'])
        } else if (inRange(this.product.price, 20000, 50000)) {
          this.minimumRateIncrease = parseInt(response['20000 - 50000'])
        } else if (this.product.price >= 50000) {
          this.minimumRateIncrease = parseInt(response['50000 +'])
        } else {
          this.minimumRateIncrease = parseInt(
            this.auction.data.live_streaming.minimum_rate_increase
          )
        }
      })
    },
    getBiddingValues() {
      if (
        this.biddingLimit &&
        this.biddingLimit.bid_limit_amount > this.directBid
      ) {
        const start = this.biddingLimit.bid_limit_amount
        this.biddingValues = range(
          start,
          parseInt(this.user.profile.bid_limit) + this.minimumRateIncrease,
          this.minimumRateIncrease
        )
      } else {
        const start = this.directBid
        this.biddingValues = range(
          start,
          parseInt(this.user.profile.bid_limit) + this.minimumRateIncrease,
          this.minimumRateIncrease
        )
      }
    },
    estimationNotNull() {
      return this.product.min_estimation || this.product.max_estimation
    },
    getBreadcrumbs() {
      return [
        {
          to: {
            name: 'app.auctions.details',
            params: {
              slug: this.auction.slug,
            },
          },
          title: this.$t('auctions.all_lots'),
        },
      ]
    },
    redirectToDrouotAuction() {
      const lot = find(this.product.lots, { product_id: this.product.id })

      if (!isEmpty(lot.drouot_url)) {
        window.location.href = lot.drouot_url
      }
    },
    getProductName() {
      return this.product.translation.name.replace(/(?:\r\n|\r|\n)/g, '<br />')
    },
    price(value) {
      return value ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : ''
    },
    getLiveAuctionRedirectData() {
      if (this.$store.state.is_admin) {
        return {
          path: '/live/auctions/' + this.auction.id + '/live',
          params: {
            id: this.auction.id,
          },
          meta: { requiresAuth: true },
        }
      }

      return {
        path: '/live/auctions/' + this.auction.id + '/live',
        params: {
          id: this.auction.id,
        },
        meta: { requiresAuth: true },
      }
    },
    setActive(element, content) {
      const listElements = Array.from(
        document.getElementById('modal-navigation').getElementsByTagName('li')
      )
      listElements.forEach((listElement) =>
        listElement.classList.remove('active')
      )
      document.getElementsByClassName(element)[0].classList.add('active')

      const contentElements = Array.from(
        document.getElementsByClassName('modal-content')
      )
      contentElements.forEach(
        (contentElement) => (contentElement.style.display = 'none')
      )
      document.getElementsByClassName(content)[0].style.display = 'block'
    },
    submitPurchaseOrder() {
      this.$api
        .createBid({
          auction_id: this.auction.id,
          product_id: this.product.id,
          price: this.purchaseOrderAmount,
          lot_id: this.product.lots[0].id,
          phone: this.purchaseOrderPrefix + this.purchaseOrderPhone,
          type: 'purchase_order',
        })
        .then(() => {
          this.orderPlaced = true
        })
        .catch((error) => {
          this.error = error.response.data.message
        })
    },
    stopBidding() {
      if (last(this.auction.lots).product_id === this.product.id) {
        this.$api.updateAuction(this.auction.id, { finished: true })
      }
      this.loadProduct()
    },
    conntactAskBid() {
      this.request_sent = true
      this.$api.askHigherBid({
        text: 'Please contact me, as I want to bid higher than 20000 euros',
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.loader {
  text-align: center;
  top: 200px;
  width: 50px;
  min-height: 500px;
  margin: auto;
  position: relative;
}

.bid__button {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 46px;
  font-family: 'Futura', sans-serif;
}

.link--underline {
  cursor: pointer;
}

.product__content {
  min-height: 595px;
}

.loader {
  text-align: center;
  top: 200px;
  width: 50px;
  height: 50px;
  margin: auto;
  position: relative;
}

.counter,
.product__cost {
  padding-bottom: 1em;
  border-bottom: 1px solid #e8e8e8;
}

.counter span {
  font-weight: 500;
  line-height: 26px;
  font-size: 21px;
  font-style: normal;
  color: #000000;
  text-transform: uppercase;
}

.counter h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 1.8px;
  text-transform: uppercase;
  color: #000000;
}

.product__info {
  padding-top: 19px;
  margin-left: 0;
  max-width: none;
}

.product__cost {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 26px;
  text-transform: uppercase;
  color: #8b8b8b;
}

.product__price {
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 26px;
  color: #000000;
  text-transform: uppercase;
}

.price-info {
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 15px;
  color: #8b8b8b;
  margin: 35px 0;
}

.product__descr-wrap {
  margin: 2rem 0;
}

.button__purchase {
  width: 256px;
  height: 51px;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: normal;
  margin: 2em 0;
}

.button__purchase--white {
  background: #363636;
  color: #fff;
}

.button__purchase--white:hover {
  color: #363636;
  background: #fff;
}

.product__feedback {
  justify-content: space-between;
}

.product__feedback-item:after {
  display: none;
}

.highest__bidder {
  text-align: center;
  background: #0db78d;
  color: #ffffff;
  padding: 0.5em 0;
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 16px;
}

.product__controller {
  padding-top: 18px;
}

.bid-options-modal {
  background: #ffffff;
  border: 1px solid #000000;
  box-sizing: border-box;
  margin-right: 0;
  margin-left: 0;
  padding: 22px 7px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 17px;
  color: #000000;
}

.bid-options-modal .modal-intro {
  margin-bottom: 17px;
  display: flex;
  justify-content: space-between;
}

.bid-options-modal ul {
  border-right: 1px solid #000000;
  list-style: none;
}

.bid-options-modal ul li {
  display: flex;
  align-items: center;
  height: 80px;
}

.bid-options-modal ul li.live.active {
  border-bottom: 1px solid #000000;
}

.bid-options-modal ul li.order.active {
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
}

.bid-options-modal ul li.telephone.active {
  border-top: 1px solid #000000;
}

.bid-options-modal .modal-content {
  display: none;
}

.bid-options-modal .modal-content input {
  padding: 10px;
  margin-bottom: 15px;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 17px;
  color: #000000;
}

.bid-options-modal .modal-content input.bordered {
  border: 2px solid #e8e8e8;
  width: 80%;
}

.limit-info,
button.ib-button-limit-demand {
  margin: 2em 0;
}

p.success {
  color: green;
}
</style>

<template>
  <section class="rating">
    <div class="tabs">
      <ul class="tabs__header">
        <li class="tabs__header-item">
          <router-link :to="{ name: 'app.estimation.request' }" class="link">{{
            $t('estimation.request')
          }}</router-link>
        </li>
        <li class="tabs__header-item">
          <router-link :to="{ name: 'app.estimation.results' }" class="link">{{
            $t('estimation.my_estimations')
          }}</router-link>
        </li>
      </ul>
      <div class="tabs__content">
        <router-view :key="$route.fullPath"></router-view>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'estimation',
}
</script>

<style scoped>
.router-link-active {
  color: black;
  cursor: auto;
}
</style>

<template>
  <div>
    <div class="row">
      <identifiers
        class="col col-33"
        :user="user"
        @cancel="cancel()"
      ></identifiers>
      <information
        class="col col-33"
        :user="user"
        @cancel="cancel()"
      ></information>
      <address-box
        class="col col-33"
        :user="user"
        @cancel="cancel()"
      ></address-box>
    </div>
    <IDBanPaddle></IDBanPaddle>
  </div>
</template>

<script>
import Identifiers from './common/Identifiers'
import Information from './common/Information'
import AddressBox from './common/AddressBox'
import IDBanPaddle from './common/IDBanPaddle'
import { get, clone } from 'lodash'

export default {
  components: {
    AddressBox,
    Information,
    Identifiers,
    IDBanPaddle,
  },
  name: 'profile-account',
  metaInfo() {
    return {
      title: 'Profile',
    }
  },
  data() {
    return {
      activeTab: 'account',
      user: {
        login: '-',
        password: '*********',
        confirm: '*********',
        first_name: '-',
        last_name: '-',
        id: '',
        profile: {
          qualification: 'professional',
          gender: 'male',
          address: '-',
          post_code: '-',
          city: '-',
          country: '-',
          phone: '-',
          bid_limit: 20000,
        },
        ceiling: '-',
        passport: '',
        rib: '',
        passport_media: '',
        rib_media: '',
      },
      originalData: {},
    }
  },
  methods: {
    cancel() {
      this.user = this.originalData
    },
    parseUser(data) {
      const user = data

      user.profile.address = get(user, 'profile.address', '')
      user.profile.post_code = get(user, 'profile.post_code', '')
      user.profile.city = get(user, 'profile.city', '')
      user.profile.country = get(user, 'profile.country', '')
      user.profile.phone = get(user, 'profile.phone', '')
      user.profile.bid_limit = get(user, 'profile.bid_limit', '')
      user.passport = get(user, 'passport', '')
      user.passport_media = get(user, 'passport.media', '')
      user.rib_media = get(user, 'rib.media', '')
      user.rib = get(user, 'rib', '')
      user.id = get(user, 'id', '')

      this.user = clone(user)
      this.originalData = clone(user)

      this.$store.commit('saveUsersFlags', user)
    },
  },
  created: function () {
    const self = this

    this.$api.getProfile().then((response) => {
      self.parseUser(response.data)
    })
  },
}
</script>

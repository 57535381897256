<template>
  <ul class="auction__bids-list">
    <li
      class="auction__bids-item auction__bids-item--latest"
      v-for="bid in events"
      :class="{ 'max-bid': highlightedBid(bid) }"
    >
      <template v-if="bid.type !== 'fair_warning'">
        <div class="auction__bids-info">
          <div class="auction__bids-title" v-if="bid.user_id">
            {{ $t('live.bidder') }} W{{ bid.user_id }}
          </div>
          <div class="auction__bids-title" v-if="!bid.user_id">
            {{ $t('live.room_bidder') }}
          </div>
          <div class="auction__bids-date">{{ bidCreatedDate(bid) }}</div>
        </div>
        <div class="auction__bids-cost">
          {{ bid.price }} {{ currency }}
          <div
            v-if="needToShowCancelButton(bid)"
            @click="$api.cancelBid(bid.id)"
            class="auction__bids-cancel"
          >
            Cancel
          </div>
        </div>
      </template>

      <template v-if="bid.type === 'fair_warning'">
        <div class="auction__bids-info">
          <div class="auction__bids-title auction__bids-title--fair_warning">
            {{ $t('live.fair_warning') }}
          </div>
          <div class="auction__bids-date">{{ bidCreatedDate(bid) }}</div>
        </div>
      </template>
    </li>
  </ul>
</template>

<script>
import { get, isNull, reverse, sortBy } from 'lodash'
import moment from 'moment-timezone'

import BidsMixin from '../mixins/bids.mixin'

export default {
  name: 'bids-list',
  props: ['auction', 'user'],
  mixins: [BidsMixin],
  methods: {
    highlightedBid(bid) {
      return (
        bid.user_id !== null &&
        bid.id === this.bestUserBidId &&
        bid.price > this.bestFloorBidAmount &&
        this.auction.data.live_streaming.current_price === bid.price
      )
    },
    bidCreatedDate(bid) {
      return moment
        .utc(bid.created_at)
        .tz('Europe/Paris')
        .format('YYYY-MM-DD HH:mm:ss')
    },
    needToShowCancelButton(bid) {
      if (this.user.role_id !== 1) {
        return false
      }

      if (!this.userBids.length) {
        return false
      }

      return this.userBids[0].id === bid.id
    },
  },
  computed: {
    userBids() {
      return this.bids.filter((bid) => {
        if (this.user.id === bid.user_id) {
          return true
        }

        // admin should have ability to cancel floor bid
        return this.user.role_id === 1 && isNull(bid.user_id)
      })
    },
    events() {
      // don't name this var 'events' because it doesn't work
      // name collision with computed name 'events'
      // i don't any idea about it
      let eventsArray = this.bids

      const currentLotId = get(
        this.auction,
        'data.live_streaming.current_lot_id'
      )
      const fairWarnings = get(
        this.auction,
        `data.live_streaming.fair_warnings.${currentLotId}`
      )
      if (fairWarnings) {
        const fairWarningEvents = fairWarnings.map((fairWarning) => {
          return { type: 'fair_warning', created_at: fairWarning }
        })

        eventsArray = reverse(
          sortBy(eventsArray.concat(fairWarningEvents), 'created_at')
        )
      }

      return eventsArray
    },
    currency() {
      if (
        this.$route.name === 'app.client_auction' ||
        this.$route.name === 'app.admin_auction'
      ) {
        return this.auction.id === 330 ? 'ZAR' : '€'
      }
      return '€'
    },
  },
}
</script>

<style scoped>
.max-bid div {
  color: #1aaa55;
}
.auction__bids-cost {
  text-align: right;
}
</style>

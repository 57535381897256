export default {
  navigation: {
    future_auctions: 'Upcoming auctions',
    past_auctions: 'Past auctions',
    news: 'News',
    artists: 'Artists',
    contact: 'Contact',
    profile: 'My Details',
    about: 'The House',
    edition: 'Edition',
    buy: 'How to buy',
    sell: 'How to sell',
    categories: 'Departments',
    press: 'Press',
    expertise: 'Expertise',
    my_purchases: 'My Purchases',
    my_sales: 'My sales',
    my_estimates: 'My Estimation',
    my_orders: 'My Orders',
    my_auctions: 'My Auctions',
    lots_followed: 'Lots Followed',
    search: 'Search lots…',
    bid_online: 'Bid online',
    collections: 'Inventory and collections',
  },
  auctions: {
    item: {
      button_live: 'Bid now',
      button_bid: 'Bid online',
      date: 'from {startDate} to {endDate}',
    },
    sidebar: {
      filters: 'Filters',
      artists: 'Artists/Creators',
      estimations: 'Estimate',
      bids: 'Current price',
      catalog: 'Catalog',
      catalog_see: 'See the catalog',
      catalog_buy: 'Buy the catalog',
      information: 'Auction information',
      description: 'Information',
      conditions: 'Conditions of sale',
      conditions_see: 'See conditions of sale',
      date_and_place: 'Date and place of the auction',
      public_exhibitions: 'Public exhibition',
      contacts: 'Contacts',
      from: 'from',
      to: 'to',
    },
    show_lots: 'Show lots',
    information: 'Information',
    categories: 'All categories',
    list: 'List',
    grid: 'Grid',
    viewing: 'Viewing',
    catalog: 'Catalog',
    catalog2: 'Catalog',
    buy_catalog: 'Buy catalog',
    catalog_results: 'Results PDF',
    calendar: '+ Add to your Calendar',
    bid_online: 'Bid online',
    buyable_online: 'Buy',
    not_found: 'No auctions found',
    started_at: 'Date and place of sale',
    bidding_started_at: 'Date of sale',
    auction_date: '{date} {to}',
    exhibition: 'Public exhibition',
    lots_search: 'Search lots…',
    publication: '{date} from {from} to {to}',
    modal_title: 'Add to the calendar of your choice',
    modal_other: 'Other',
    default_filter: 'All categories',
    types: 'Types of sales',
    types_default_filter: 'Types of sales',
    bid: 'Bid now',
    fallow: 'Follow',
    time_left: 'Time left',
    all_lots: 'All lots',
    register: 'Register to bid',
  },
  products: {
    next: 'Next lot',
    previous: 'Previous lot',
    leave_purchase: 'Bid now',
    buy_on_drouot: 'Bid with Drouot Online',
    phone_auction: 'Phone auction',
    how_to_bid: 'How to bid?',
    condition_report: 'Request condition report',
    condition_report_success:
      'Thank you. You will soon receive by email the conditions report.',
    condition_report_failed:
      'Sorry, something went wrong. Could you please try again later?',
    condition_report_modal: {
      title: 'Condition report',
      download: 'Download',
    },
    information_request: 'Information request',
    already_in_cart: 'Product is booked',
    added_to_cart: 'Added to cart',
    price: 'Price',
    price2: 'Starting bid',
    current_price: 'Current price',
    not_found: 'There is no products found',
    category: 'Category',
    search_results: 'results for',
    sold: 'Sold',
    upcoming: 'Upcoming Auctions',
    past: 'Past Auctions',
    see_more: 'See more',
    result: 'Result',
    estimation: 'Estimate',
    estimate: 'Estimate',
    start_on: 'START ON',
    time_left: 'TIME LEFT',
    info:
      'Displayed results include buyer’s fees and taxes. They are automatically generated and can be subject to modification',
  },
  product: {
    quick_bid: 'Quick bid',
    direct_bid: 'Direct bid',
    cancel: 'Cancel',
    confirm: 'Confirm',
    delivery: 'STORAGE AND DELIVERY',
    pay: 'PAY MY PURCHASE',
    storage: 'Storage',
    delivery2: 'Delivery',
    text:
      'I confirm my bid excluding sales charges. Please refer to the conditions of sale to know the amount of costs.',
    storage_text:
      'Items will be kept free of charge for 30 days. Thereafter the purchaser will be charged storage and insurance costs at the rate of €30 + tax, and €3 + tax, per day and per lot and €6 + tax per calendar day and per lot concerning the furniture. Past 60 days, PIASA assumes no liability for any damages that may occur to the lot, it being no longer covered by PIASA’s insurance.',
    delivery_text: `All paid purchases may be uplifted 24 hours after the sale at our storage site: PIASA 5 boulevard Ney 75018 Paris (Open from 9- am to 12 pm and 2pm to 5pm). The uplift of the items shall be by appointment only.
      Entrance via 215 rue d’Aubervilliers 75018 Paris, Level -1, zone C-15
      Maximum height of vehicles 3.90m
      Withdrawal of the items is done by appointment by e-mail: <a href="mailto:piasa-ney@piasa.fr">piasa-ney@piasa.fr</a>
      Contact : Marion Pelletier | <a href="tel:+33140348883">+33 1 40 34 88 83</a> |<br /><br /><i>It is possible to obtain an approximate transport estimate via the link below: This information is provided for your information only, and does not exclude, in any case, the use of the service provider of your choice.</i>`,
    pay_intro:
      'Payment for items, together with applicable taxes, shall be made in euros. Payment must be made immediately after the sale. The winning bidder may pay using the following means:',
    pay_text_1:
      'By credit or debit card in the auction room, or the account department 118 rue du Faubourg Saint Honoré Paris 75008 Paris, or 5 Boulevard Ney 75018 Paris : VISA and MASTERCARD. (American express not accepted). Remote payment by credit card is not accepted.',
    pay_text_2:
      'By certified bank cheque in euros with compulsory presentation of a valid identity document, or extract of registration in the trade registry ("Kbis" extract) dating from within the last 3 months for legal entities.',
    pay_text_3: 'By wire transfer in euros.',
    pay_text_4:
      "Cheques drawn on a foreign bank will not be authorised except with PIASA's prior agreement. For that purpose, buyers are advised to obtain a letter of credit from their bank for a value approaching their intended purchase price, which they will transmit to PIASA.",
    pay_text_5:
      "In cash: <ul><li>Up to €1,000 including costs and taxes, where the debtor's tax residence is in France or if acting for the purposes of a professional activity.</li><li>Up to €15,000 including costs and taxes where the debtor proves not being having tax residency in France and not acting for the purposes of a professional activity, on presentation of a passport and proof of residence.</li></ul>",
  },
  calendar: {
    title: 'Lot withdrawal date',
    description:
      'Choose the date and the time slots for the withdrawal of your lot from our stock at',
    interval: 'Choose a time slot',
    between: 'Between',
    or: 'Or',
    validate_button: 'Submit',
    before_dinner: '8h and 13h',
    after_dinner: '14h and 18h',
    error: 'You should select date',
  },
  auth: {
    the_login_has_already_been_taken:
      'This email address is already use for one account. Please login or create an account with a different email address.',
    incorrect_login_or_password: 'Incorrect login or password',
    login_title: 'Sign in',
    email: 'Email Address*',
    phone: 'Phone',
    password: 'Password*',
    confirm_password: 'Confirm password*',
    forgot_password: 'Forgot password?',
    login: 'Confirm',
    not_registered: 'Not registered?',
    create_account: 'Create an account',
    registration: 'Registration',
    particular: 'Particular',
    professional: 'Professional',
    male: 'Mr.',
    female: 'Mrs',
    first_name: 'First name*',
    last_name: 'Last name*',
    subscribe:
      'I agree to receive informations about PIASA\'s auctions, services and events. In accordance with the Privacy policy, you may unsubscribe at any time by clicking "unsubscribe" at the bottom of all our email communications.',
    agree_first:
      "By sending this form, you agree to this website's terms of use and acknowledge having read our",
    agree_link: 'Privacy policy',
    agree_second: 'of this site',
    register: 'Next',
    already_registered: 'I already have an account',
    password_recovery: 'Password recovery',
    reset_password: 'Reset Password',
    invalid_restore_token:
      'Looks like you have already restored your password by this link',
    try_again: 'Try again',
    bid_online: 'To bid online',
    click_here: 'click here',
    register_intro:
      'Through your PIASA account, you will be able to bid during our sales, to order our catalogues, to manage your subscription to our newsletter, and to update your personal details.',
    login_intro:
      'Through your PIASA account, you will have the opportunity to bid during our auction sales, to order our catalogues, to manage your subscription to the newsletter, and to update your personal details.',
    errors: {
      must_least_18_years_old: 'You must be at least 18 years old to register',
    },
    text2: 'Forgot your password ?',
    product_bidding:
      'To bid and participate in the auction your registration is necessary.',
  },
  profile: {
    account: 'Account',
    cart: 'Cart',
    documents: 'Documents',
    no_documents: 'There is no documents yet',
    orders: 'Latest purchases',
    orders_not_found: 'There is no orders yet',
    update: 'Update',
    save: 'Save',
    cancel: 'Cancel',
    identifiers: 'Login information',
    information: 'Personal information',
    address: 'Address',
    birthday: 'Date of birth',
    phone: 'Phone number',
    post_code: 'Zip',
    city: 'City',
    country: 'Country',
    account_verification: 'Account verification',
    account_verification_description:
      'To verify your account, please download your RIB and a copy of your ID',
    verification_add_files: 'Download my documents',
    ceiling: 'Ceiling',
  },
  cart: {
    empty: 'cart is empty',
    auction: 'Auction',
    category: 'Category',
    price: 'Result + charges',
    purchase: 'Set my purchase',
    remove: 'Remove from cart',
    view: 'View lot',
    bill: 'Facture',
  },
  contact_us: {
    PIASA: {
      PIASA: 'PIASA',
      location: {
        address: '118 rue du Faubourg Saint-Honoré',
        country: '75008 Paris France',
      },
      time: 'Monday to Friday from 10am to 6pm',
    },
    email: 'contact@piasa.fr',
    contact_us: 'Contact us',
    title: 'For any information request, you can fill out the form below',
    subject: 'Subject of your message',
    text: 'Your message',
    send: 'Send',
    subscribe_button: 'Subscribe',
    subscribe: 'Subscribe to our Newsletter Be informed of the latest auctions',
    storage: 'Furniture storage',
    follow: 'Follow us',
    storage_contacts: [
      '5 boulevard Ney',
      'Paris (75018) FRANCE',
      'only by appointment',
      '+33 (1) 40 34 88 81',
      'piasa-ney@piasa.fr',
    ],
  },
  estimation: {
    request: 'Estimate request',
    my_estimations: 'My estimation',
    information: 'Object Information',
    title: 'Title',
    dimensions: 'Dimensions',
    images: 'Images or documents',
    agree:
      'By submitting this form, you agree to the terms of use of this site',
    send: 'Send',
    add: 'Add image',
    need_to_add_media: 'You should upload at least one image',
    empty: 'There are no estimation yet',
    estimated: 'Valid',
    rejected: 'Rejected',
    not_estimated: 'Not estimated yet',
    category: 'Select category',
  },
  purchase: {
    title: 'My purchase orders',
    description: '',
    item: {
      sale: 'Sale',
      date: 'Date',
      lot: 'Lot',
      specialty: 'Specialty',
      order: 'Order',
    },
    label: {
      valid: 'Valid',
      refuse: 'Refuse',
    },
    more: 'Voir le lot',
    no: {
      description: 'no description',
      info: 'no information',
      phone: 'no phone',
    },
    transport: 'Devis transport',
  },
  expertise: {
    title: 'Expertise',
    contacts_title: 'For any expertise, you can contact:',
    experts_list: 'All our specialists',
    sale: 'Terms of Sales',
  },
  footer: {
    about: 'About Us',
    sell: 'Buy & sell',
    press: 'Press',
    categories: 'Categories',
    legal_notice: 'Legal Notice',
    calendar: 'Calendar',
  },
  news: {
    title: 'News',
    other: 'Other articles',
    follow: 'Follow us on Instagram',
  },
  artists: {
    title: 'Artists',
    news: 'News',
    products: 'Products',
    future_auctions: 'Upcoming auctions',
    results: 'Results',
    estimation_request: 'Request a free estimate',
    show_more: 'SHOW MORE',
  },
  collections: {
    title: 'Collection',
    contacts: 'Contacts',
    make_an_appointment: 'Make an appointment',
    news: 'large collection',
  },
  subscribe: {
    title: 'Newsletter',
    description: 'Subscribe to the Newsletter',
    email: 'Email',
    submit: 'Subscribe',
    success: 'Subscription successfully registered',
  },
  not_found: {
    title: 'Sorry, this page does not exist',
    text: 'If you can not access the desired content, please contact us',
    go_home: 'Back to home',
  },
  payment: {
    complete: 'Payment complete',
    success_text: 'Your payment was successful.',
    failed: 'Payment failed',
    failed_text: 'Your payment method was declined',
    already_paid: 'This order has already been paid for',
    card_label: 'Credit or debit card',
    submit: 'Submit Payment',
    field_placeholder: 'Card number',
    cancel: 'Payment cancel',
    cancel_text: 'The order has been cancelled',
  },
  categories: {
    specialists: 'Contacts',
  },
  auction_purchase: {
    limit_amount: 'Limit amount of your order',
    description: [
      'You must enclose an RIB and a copy of an identity document (passport or national identity card).',
      'Written purchase orders or telephone auctions are a facility for customers. Neither PIASA nor its employees can be held responsible for any errors or omissions in their performance or in case of non-performance thereof.',
      'Absent bid must be received by us at least 24 hours before the sale.',
      'This service is available for lots with a low estimate of more than 500 €.',
    ],
    terms_label: [
      'I have read the ',
      'general conditions',
      ', information and opinion\n' +
        'printed in the catalog and agrees to be bound by their content as well as by any modifications that may be\n' +
        'brought to them either by notice in the auction room or by announcement made before or during\n' +
        'sale. I ask you to buy for my personal account, at the limits in euros, the lots that I have designated\n' +
        'opposite (the limits do not include the costs borne by the buyer).',
    ],
    leave_purchase: 'Leave a buy order',
    RIB_label: 'Add your IBAN',
    RIB_label2: 'Upload an image or document',
    passport_label: 'Add your ID',
    submit: 'Submit',
    error: {
      license: 'Please accept the General Conditions',
      order: 'Please input the amount of your purchase order',
      phone: 'Please input your phone number',
    },
    phone: 'Phone during the sale',
  },
  press: {
    title: 'Press',
    release: 'Press release',
  },
  titles: {
    news: 'PIASA auction house | News',
    home: 'PIASA, Auction House | Art & Design',
    calendar: 'PIASA auction house | Calendar',
    artists: 'PIASA maison de vente aux enchères | Artistes',
  },
  meta: {
    news:
      'Access all the latest news from PIASA auction house in Modern and Contemporary Art, Design, Jewelry, works of art and furniture',
    home:
      'PIASA auction house has established a unique identity through its cutting-edge selection in Modern and Contemporary art, Design, Jewelry, works of art and furniture',
    calendar:
      'PIASA auction house organizes several Modern and Contemporary art, Design, Jewelry, works of art and furniture auction sales throughout the year.',
  },
  home: 'Home',
  show_more: 'Show more',
  cookies: {
    message:
      'We use cookies on our site to improve your user experience. If you do not act you will be treated as having accepted cookies.',
    accept: 'Accept',
  },
  bid_online:
    'PIASA offers you to bid live on sales from your computer. To sign up for a sale and be able to bid online, simply create a live account or log in to your existing account.',
  live: {
    youAreLastBidder:
      'You are already the highest bidder on this lot. Please wait before bidding again.',
    title: 'Live',
    return_to_last_auction: 'Return to last auction',
    show_live_stream: 'Show live stream',
    fair_warning: 'Fair warning',
    current_auction: 'Current bid',
    internet_auction: 'Internet max bid',
    change_price: 'Change price',
    set_price: 'Set price',
    bidder: 'Bidder',
    minimum_amount_for_next_bid: 'Minimum increase price rate',
    change_minimum_amount_for_next_bid: 'Change minimum increase price rate',
    set_minimum_amount_for_next_bid: 'Set minimum increase price rate',
    bidders: 'Bidders',
    watchers: 'Watchers',
    sold: 'Lot sold / Next Lot',
    sold_and_stop: 'Sold and stop',
    skip: 'Skip Lot',
    previous: 'Back to Previous Lot',
    next: 'Skip to Next Lot',
    lots_sold: 'Lots sold',
    search_lots: 'Search lots...',
    quick_bid: 'Quick bid',
    make_bid: 'Direct bid',
    place_offer: 'Amount',
    stop: 'Stop',
    start: 'Start',
    low_bid_value_error: 'Your bid can’t be less than quick bid',
    high_bid_value_error: 'Your big cannot be more than 1 999 999 999',
    you_are_best: "You're the best bidder on lot {lot_number}",
    you_are_not_the_best: 'Bidder {bidder_id} bidded a higher amount',
    bidding_started: 'Bidding on lot {lot_number} started',
    auction_started: 'Auction {auction_name} started',
    auction_will_start_soon: 'The live will start in',
    auction_finished:
      'The auction is over, thank you for being with us. Our team will contact you.',
    auction_finished_title: 'You have won following lots:',
    auction_confirm: 'Congratulations! You are registred to the Live Auction.',
    product_confirm_warning: "Warning: You're subscription is not approved yet",
    auction_confirm_warning:
      'Warning: Your account is not verified yet. Please upload your national identification card and your IBAN',
    auction_confirm_short_warning:
      'Please upload your national identification card and your IBAN',
    auction_must_registered_title:
      'You are not yet register to the live auction.',
    online_auction_must_registered_title:
      'You are not yet register to the online auction.',
    auction_must_registered:
      'To watch and participate the PIASA auction, a register request is needed.',
    online_auction_must_registered:
      'To watch and participate the PIASA auction, a register request is needed.',
    no_permission: "You don't have enough permissions to manage this auction",
    back_to_auction: 'Back to Auction',
    youtube_stopping_error:
      'We have some trouble with youtube stream stopping. ' +
      'Auction has finished and you should turn it off manually',
    subscribe: 'Register to the Live Auction',
    subscribe_online: 'Register to the Online Auction',
    already_subscribed: 'You are successfully subscribed on this auction',
    room_bidder: 'Floor bid',
    room_bid: 'Bid in the room of {amount}',
    lot_not_selected: 'Current lot are not selected',
    countdown_days: 'Days',
    countdown_hours: 'Hours',
    countdown_minutes: 'Minutes',
    countdown_seconds: 'Seconds',
    submit_bid: 'Bid',
    image_upload_success: 'Images was successfully uploaded',
    image_upload_max_exceeded:
      'The maximum file size is 3Mo, please upload a document with a smaller resolution.',
    image_upload_wrong_ext:
      ' The authorized files extensions are : .jpg, .png and .pdf',
    user_is_vip: 'You are a VIP user, your account is automatically validated',
    user_is_confirmed: 'Your account has been already verified',
    bidder_info: 'You are bidder : W{number}',
  },
  submit: 'Submit',
  adwords: {
    text:
      'To get an estimation, leave us your email and we will contact you soon.',
    submit: 'Submit',
  },
  ib: {
    passport: 'Identification document',
    iban: 'Bank details',
    paddle: 'Your paddle number is',
    limit: 'Your bid limit per sale is ',
    ask: ' I would like to increase my bid limit',
    message: 'Message to admin:',
    writte: 'Writte message here...',
    close: 'Close',
    send: 'Send',
    padd_img: 'Paddle ',
    sended: 'Files were sended!',
    valid: 'Valid document',
    validation: 'Waiting for validation',
    increase_limit_intro: 'You have reached your bid limit of ',
    increase_limit_contact: 'Please contact us to increase it.',
    increase_success:
      'Your request has been sent and we will get back at you shortly!',
  },
  index: {
    currently: 'CURRENTLY IN AUCTION',
    coming: 'COMING SOON FOR AUCTION',
    estimate: 'Get auction estimate',
    buying_guide: 'Buying Guide',
    selling_guide: 'Selling Guide',
    stories: 'STORIES',
    news: 'News',
    read_more: ' Read more',
  },
  account: {
    title: 'Complete your account',
    subtitle:
      'By completing your account you will be able to bid during our auctions, submit purchase orders, and participate in our online only sales',
    particular: 'Individual',
    professional: 'Professional',
    company: 'Company',
    address1: 'Address 1',
    address2: 'Address 2',
    postal_code: 'Postal code',
    area_code: '+0000',
    phone: '06 00 00 00 00',
    city: 'City / town',
    birthday: 'Date of Birth',
    locale: 'Language of communication',
    licence_agreement: 'I am an adult',
    skip: 'Skip this step',
    submit: ' Confirm',
    filter_country: 'Country',
    filter_language: 'Language',
    filter_en: 'English',
    filter_fr: 'French',
  },
  validation: {
    title: 'Thank you',
    text1:
      'We have sent you an email please click the confirmation link within in order to continue your account creation.',
    text2: 'If you do not receive this message, please check your spam folder.',
    text3:
      'In order to avoid this message going to your spam folder, consider adding digital@piasa.fr to your contact list.',
    subscription_agreement:
      'By bidding in this auction, I acknowledge having read and accepted the terms and conditions listed in the conditions of sale, including important notices and the site terms of use.',
  },
  my_bidding: {
    title: 'My Bidding',
    time_left: 'Time left:',
    estimate: 'Estimation',
    current_bid: 'Enchère actuelle',
    button_bid: 'Bid Now',
    button_stop_follow: 'Unfollow',
    best_bid: "You're the highest bidder",
  },
  my_purchase: {
    title: 'My buying',
    estimate: 'Estimation',
    adjudication: 'Adjudication',
    transport: 'Transport quote',
  },
  bid: {
    title: 'How would you like to bid?',
    absentee: {
      title: 'Absentee bid',
      'text-1':
        "I confirm the amount of my maximum bid excluding buyer's premium.",
      'text-2':
        'By bidding in this auction, I acknowledge that I have read and agree to the terms and conditions listed in the Terms of Sale, including the Important Notices and Terms of Use of the site.',
    },
    phone: {
      title: 'Telephone bidding',
      'text-1':
        'You can make your bidding request by phone. We will call you on the day of the auction when the chosen item will be auctioned and transmit your bid directly.',
      'text-2':
        'By bidding in this auction, I acknowledge that I have read and agree to the terms and conditions listed in the Terms of Sale, including the Important Notices and Terms of Use of the site.',
    },
    live: {
      text:
        'The online auction platform allows you to follow the sale in real time and to participate directly from your computer.',
    },
  },
}

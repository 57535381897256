<template>
  <div class="row">
    <div class="col col-50">
      <div class="validation">
        <h1>{{ $t('validation.title') }}</h1>
        <p class="intro">
          {{ $t('validation.text1') }}
          <br />
          <br />
          {{ $t('validation.text2') }}
          <br />
          <br />
          {{ $t('validation.text3') }}
        </p>
      </div>
    </div>
    <div class="col col-50">
      <div class="validation-apparel">
        <img src="/img/validation.png" alt="Piasa" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'validation',
  metaInfo() {
    return {
      title: 'Validation',
    }
  },
}
</script>

<style scoped>
.validation .row {
  padding: 30px 0;
}

p.intro {
  max-width: 390px;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 27px;
  color: #363636;
}

@media (min-width: 768px) {
  .validation {
    margin-top: 180px;
    margin-right: 130px;
  }
}
</style>

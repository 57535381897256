<template>
  <div class="icons">
    <div class="container">
      <div class="icons__grid">
        <div class="icons__wrap">
          <router-link :to="{ name: 'app.estimation' }" class="icons__item">
            <img src="~@/assets/img/icon-1.svg" alt="estimate" />
          </router-link>
          <p class="icons__title">
            <router-link :to="{ name: 'app.estimation' }">
              {{ $t('index.estimate') }}
            </router-link>
          </p>
        </div>
        <div class="icons__wrap">
          <router-link
            :to="{
              name: 'app.static_page',
              params: {
                slug: 'how-to-bid',
              },
            }"
            class="icons__item"
          >
            <img src="~@/assets/img/icon-2.svg" alt="buying-guide" />
          </router-link>
          <p class="icons__title">
            <router-link
              :to="{
                name: 'app.static_page',
                params: {
                  slug: 'how-to-bid',
                },
              }"
            >
              {{ $t('index.buying_guide') }}
            </router-link>
          </p>
        </div>
        <div class="icons__wrap">
          <router-link
            :to="{
              name: 'app.static_page',
              params: {
                slug: 'how-to-sell',
              },
            }"
            class="icons__item"
          >
            <img src="~@/assets/img/icon-3.svg" alt="selling-guide" />
          </router-link>
          <p class="icons__title">
            <router-link
              :to="{
                name: 'app.static_page',
                params: {
                  slug: 'how-to-sell',
                },
              }"
            >
              {{ $t('index.selling_guide') }}
            </router-link>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomePageIcons',
}
</script>

<style scoped>
.icons {
  border-top: 1px solid #e8e8e8;
  padding: 60px 0;
  margin-top: 60px;
}

.icons__grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  text-align: center;
}

.icons__item {
  width: 292px;
  height: 292px;
  left: 105px;
  top: 2329px;
  background: #faf9f9;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: 0 auto;
}

.icons__title {
  font-style: normal;
  font-weight: normal;
  font-size: 27px;
  line-height: 52px;
  text-align: center;
  color: #000000;
  margin-top: 32px;
}

@media (max-width: 1024px) {
  .icons__grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>

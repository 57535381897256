<template>
  <div class="auction__info-value">
    <input
      class="invisible_input rate_increase_input"
      type="text"
      maxlength="10"
      oninput="this.value=this.value.replace(/[^0-9]+/g,'');"
      v-if="editMode"
      ref="rateInput"
      :value="value"
      @keyup.enter="submit()"
      @keyup.escape="cancel()"
      @input="minimumRageIncrease = $event.target.value"
    />
    <label v-if="!editMode" @click="chengeMode()">
      {{ value }} {{ currency }}
    </label>
    <span class="auction__info-value-modify">
      <a v-if="!editMode" @click="chengeMode()" class="link link--underline">
        {{ $t('live.change_minimum_amount_for_next_bid') }}
      </a>
      <a v-if="editMode" @click="submit()" class="link link--underline">
        {{ $t('live.set_minimum_amount_for_next_bid') }}
      </a>
    </span>
  </div>
</template>

<script>
export default {
  name: 'RateIncreaseInput',
  props: ['value', 'auction'],
  data() {
    return {
      editMode: false,
      minimumRageIncrease: this.value,
    }
  },
  computed: {
    currency() {
      return this.auction.id === 330 ? 'ZAR' : '€'
    },
  },
  methods: {
    chengeMode() {
      this.editMode = true

      this.$nextTick(function () {
        this.$refs.rateInput.select()
      })
    },
    submit() {
      const numericRate = Number(this.minimumRageIncrease)
      this.editMode = false

      if (numericRate) {
        this.$emit('input', numericRate)
      }
    },
    cancel() {
      this.editMode = false
    },
  },
}
</script>

<template>
  <div class="row">
    <div class="col col-50">
      <div class="signup-form">
        <h1>{{ $t('auth.login_title') }}</h1>
        <p class="intro">
          {{ $t('auth.login_intro') }}
        </p>
        <form class="form">
          <div class="form__row">
            <input
              class="form__input"
              type="email"
              v-model="params.login"
              :placeholder="$t('auth.email')"
              :class="{ 'has-error': $v.params.login.$error }"
            />
          </div>
          <div class="form__row">
            <input
              class="form__input"
              type="password"
              v-model="params.password"
              :placeholder="$t('auth.password')"
              @input="$v.params.password.$touch()"
              :class="{ 'has-error': $v.params.password.$error }"
            />
          </div>
          <div class="form__row reset-link">
            <router-link
              :to="{ name: 'app.forgot_password' }"
              class="sign-in__forgot-link link"
            >
              {{ $t('auth.text2') }}
            </router-link>
          </div>
          <div v-if="error" class="field__error form__row">
            {{ $t(`auth.${error}`) }}
          </div>
          <div class="row">
            <div class="col col-50">
              <router-link
                :to="{ name: 'app.sign_up' }"
                class="link create-account"
              >
                {{ $t('auth.create_account') }}
              </router-link>
            </div>
            <div class="col col-50">
              <a id="register-btn" class="button" @click="login()">{{
                $t('auth.login')
              }}</a>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="col col-50">
      <div class="signup-apparel">
        <img src="/img/signup.jpg" alt="Piasa" />
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'

export default {
  name: 'sign-in',
  mixins: [validationMixin],
  validations: {
    params: {
      login: { required, email },
      password: { required },
    },
  },
  metaInfo() {
    return {
      title: 'Sign In',
    }
  },
  data() {
    return {
      params: {
        login: '',
        password: '',
      },
      error: '',
    }
  },
  methods: {
    login() {
      this.$v.$touch()

      const self = this

      self.error = ''

      if (this.$v.$error) {
        return
      }

      this.$api
        .login(this.params)
        .then((response) => {
          const url = self.$store.state.redirect_back_url ||
            this.$route.query.redirect || {
              name: 'app.profile',
            }

          this.$store.commit('token', response.data.token)
          this.$store.commit('saveUsersFlags', response.data.user)
          self.$store.commit('setRedirectBackUrl', null)

          self.$router.push(url)
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.error = this.$t('incorrect_login_or_password')
          } else {
            self.error = error.response.data.message
          }
        })
    },
  },
}
</script>

<style scoped>
/*TODO: need to move it to scss files*/
.field__error {
  color: #ce081b;
}

div.has-error {
  margin-top: 5px;
  top: 100%;
  left: 0;
  right: 0;
  text-align: center;
  position: absolute;
}

.form__row {
  position: relative;
  padding: 30px 0;
  margin-bottom: 0;
}

.signup-form .form .row {
  padding: 30px 0;
}

p.intro {
  font-size: 2.5rem;
}

.form__row.reset-link {
  text-align: right;
  padding-top: 0;
}

.create-account {
  height: 100%;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 46px;
  text-align: center;
  color: #000000;
}

@media (min-width: 768px) {
  .signup-form {
    margin-top: 180px;
    margin-right: 130px;
  }
}

@media (max-width: 767.98px) {
  .form .col {
    padding: 20px 0;
  }

  .form__row {
    margin: 0;
    padding: 20px 0;
  }

  .signup-form .form .row {
    padding: 0;
    margin: 0;
  }
}
</style>

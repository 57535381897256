export default {
  navigation: {
    future_auctions: 'VENTES FUTURES',
    past_auctions: 'VENTES PASSÉES',
    news: 'ACTUALITÉS',
    artists: 'Artistes',
    contact: 'Contact',
    profile: 'Mon Compte',
    about: 'La Maison',
    edition: 'Edition',
    buy: 'Comment acheter',
    sell: 'Comment vendre',
    categories: 'Spécialités',
    press: 'Presse',
    expertise: 'Expertise',
    my_purchases: 'Mes Achats',
    my_sales: 'Mes Ventes',
    my_estimates: 'Mes Estimations',
    my_orders: 'Mes Ordres d’achats',
    my_auctions: 'Mes Enchères',
    lots_followed: 'Lots suivis',
    search: 'Rechercher un lot…',
    bid_online: 'Enchérir en ligne',
    collections: 'Inventaire et collections',
  },
  auctions: {
    item: {
      button_live: 'Participer à la vente',
      button_bid: 'Participer à la vente',
      date: 'de {startDate} à {endDate}',
    },
    sidebar: {
      filters: 'Filtres',
      artists: 'Artistes/Créateurs',
      estimations: 'Estimations',
      bids: 'Enchéres actuelles',
      catalog: 'Catalogue',
      catalog_see: 'Voir le catalogue',
      catalog_buy: 'Acheter le catalogue',
      information: 'Informations sur la vente',
      description: 'Informations',
      conditions: 'Conditions de vente',
      conditions_see: 'Voir nos conditions de vente',
      date_and_place: 'Date et lieu de la vente',
      public_exhibitions: 'Exposition publique',
      contacts: 'Contacts',
      from: 'de',
      to: 'à',
    },
    show_lots: 'Voir les lots',
    information: 'Informations',
    categories: 'Toutes les spécialités',
    list: 'Liste',
    grid: 'Grille',
    viewing: 'Affichage',
    catalog: 'Catalogue',
    catalog2: 'Catalogue',
    buy_catalog: 'Acheter le catalogue',
    catalog_results: 'Résultats PDF',
    calendar: '+ Ajouter à votre calendrier',
    bid_online: 'Enchérir en ligne',
    buyable_online: 'Acheter',
    not_found: 'Aucune ventes aux enchères trouvé',
    auction_date: '{date} à {to}',
    started_at: 'Date et lieu de la vente',
    bidding_started_at: 'Date de la vente',
    exhibition: 'Exposition publique',
    lots_search: 'Rechercher un lot…',
    publication: '{date} de {from} à {to}',
    modal_title: 'Ajouter au calendrier de votre choix',
    modal_other: 'Autre',
    default_filter: 'Toutes les spécialités',
    types: 'Types de ventes',
    types_default_filter: 'Types de ventes',
    bid: 'Enchérir',
    fallow: 'Suivre',
    time_left: 'Clôture dans',
    all_lots: 'Tous les lots',
    register: 'Participer à la vente',
  },
  products: {
    next: 'Lot suivant',
    previous: 'Lot précédent',
    leave_purchase: 'Enchérir',
    buy_on_drouot: 'Enchérir avec Drouot Online',
    phone_auction: 'Enchère par téléphone',
    how_to_bid: 'Comment enchérir?',
    condition_report: 'Rapport de condition',
    condition_report_success:
      'Merci. Vous allez recevoir prochainement par email le rapport de conditions.',
    condition_report_failed:
      "Désolé, quelque chose s'est mal passé. Pourriez-vous s'il vous plaît réessayer plus tard?",
    information_request: "Demande d'information",
    condition_report_modal: {
      title: 'Rapport de condition',
      download: 'Télécharger',
    },
    already_in_cart: 'Le produit est réservé',
    added_to_cart: 'Ajouté au panier',
    price: 'Mise à prix',
    price2: 'Mise à prix',
    current_price: 'Enchère actuelle',
    not_found: "Il n'y a pas de produits trouvés",
    category: 'Catégorie',
    search_results: 'resultats pour',
    sold: 'Vendu',
    upcoming: 'Ventes Futures',
    past: 'Ventes Passées',
    see_more: 'Voir plus',
    result: 'Résultat',
    estimation: 'Estimations',
    estimate: 'Estimation',
    start_on: 'DÉBUTE LE',
    time_left: 'CLÔTURE DANS',
    info:
      'Les résultats sont affichés frais acheteur et taxes compris. Ils sont générés automatiquement et peuvent subir des modifications.',
  },
  product: {
    quick_bid: 'Enchére rapide',
    direct_bid: 'Enchère directe',
    cancel: 'Annuler',
    confirm: 'Confirmer',
    delivery: 'STOCKAGE ET LIVRAISON',
    pay: 'RÉGLER MON ACHAT',
    storage: 'Stockage',
    delivery2: 'Livraison',
    text:
      'Je confirme mon enchère hors frais de vente. Merci de vous reportez aux conditions de vente pour connaître le montant des frais.',
    storage_text:
      'Les lots pourront être gardés à titre gracieux pendant 30 jours. Passé ce délai, des frais de dépôts et d’assurance seront supportés par les acquéreurs au tarif de 30 euros HT forfaitaire et 3 euros HT par jour calendaire et par lot, 6 euros HT par jour calendaire et par lot concernant le mobilier. Passé 60 jours, PIASA décline toute responsabilité quant aux dommages que l’objet pourrait encourir, la garantie de PIASA cessera alors de plein droit.',
    delivery_text: `Tous les achats réglés pourront être enlevés 24 heures après la vente dans notre stockage :
      PIASA 5 boulevard Ney 75018 Paris (Ouvert de 9h à 12h et de 14h à 17h).
      Entrée par le 215 rue d’Aubervilliers 75018 Paris, Niveau -1, zone C-15
      Hauteur maximum du camion 3m90
      L’enlèvement des objets se fait sur rendez-vous par mail : <a href="mailto:piasa-ney@piasa.fr">piasa-ney@piasa.fr</a>
      Contact : Marion Pelletier | <a href="tel:+33140348883">+33 1 40 34 88 83</a> |<br /><br /><i>Il vous est possible d’obtenir un devis de transport approximatif via le lien ci-dessous : Ces informations vous sont fournies à titre indicatif, et n’excluent, en aucun cas, le recours au prestataire de votre choix.</i>`,
    pay_intro:
      'Le règlement des objets, ainsi que celui des taxes s’y appliquant, sera effectué en euros. Le paiement doit être effectué immédiatement après la vente. L’adjudicataire pourra s’acquitter de sa facture par les moyens suivants:',
    pay_text_1:
      'Par carte bancaire en salle, ou auprès du service comptabilité au 118 rue du Faubourg Saint Honoré 75008 Paris, ou au 5 Boulevard Ney 75018 Paris : VISA et MASTERCARD. (L’American express n’est pas acceptée). Le paiement par carte bancaire à distance n’est pas accepté.',
    pay_text_2:
      'Par chèque bancaire certifié en euros avec présentation obligatoire d’une pièce d’identité en cours de validité, ou d’un Kbis datant de moins de 3 mois pour les personnes morales.',
    pay_text_3: 'Par virement bancaire en euros.',
    pay_text_4:
      'Les chèques tirés sur une banque étrangère ne seront autorisés qu’après l’accord préalable de PIASA, pour cela, il est conseillé aux acheteurs d’obtenir, avant la vente, une lettre accréditive de leur banque pour une valeur avoisinant leur intention d’achat, qu’ils transmettront à PIASA.',
    pay_text_5:
      'En espèces: <ul><li>Jusqu’à 1 000 € frais et taxes comprises lorsque le débiteur a son domicile fiscal en France ou agit pour les besoins d’une activité professionnelle.</li><li>Jusqu’à 15 000 € frais et taxes comprises lorsque le débiteur justifie qu’il n’a pas son domicile fiscal en France et n’agit pas pour les besoins d’une activité professionnelle, sur présentation d’un passeport et justificatif de domicile.</li></ul>',
  },
  calendar: {
    title: 'Date de retrait du lot',
    description:
      'Choisissez la date et le créneaux horaire du retrait de votre lot dans notre stock au',
    interval: 'Choisissez un créneaux horaire',
    between: 'Entre',
    or: 'Ou',
    validate_button: 'Valider',
    before_dinner: '8h et 13h',
    after_dinner: '14h et 18h',
    error: 'Vous devriez choisir la date',
  },
  auth: {
    the_login_has_already_been_taken:
      ' Cette adresse e-mail est déjà utilisée pour un compte. Veuillez vous connecter à la place ou créer un compte avec une adresse e-mail différente.',
    incorrect_login_or_password: 'Identifiant ou mot de passe incorrect',
    login_title: 'Connexion',
    email: 'Adresse email*',
    phone: 'Numéro de téléphone',
    password: 'Mot de passe*',
    confirm_password: 'Confirmer*',
    forgot_password: 'Mot de passe oublié?',
    login: 'Valider',
    not_registered: 'Pas encore inscrit?',
    create_account: 'Créer un compte',
    registration: 'Inscription',
    particular: 'Particulier',
    professional: 'Professionnel',
    male: 'Monsieur',
    female: 'Madame',
    name: 'Nom',
    first_name: 'Prénom*',
    last_name: 'Nom*',
    subscribe:
      'J’accepte de recevoir des informations concernant les ventes aux enchères, services et événements de PIASA. Conformément à la Politique de confidentialité vous pouvez vous désinscrire à tout moment en cliquant sur le lien « Se désinscrire » qui se trouve en bas de toutes nos communications par e-mail.',
    agree_first:
      'En envoyant ce formulaire, vous accepter les conditions d’utilisation de ce site et reconnaissez avoir pris connaissance de notre',
    agree_link: 'politique de gestion des données personnelles.',
    agree_second: 'de ce site',
    register: 'Suivant',
    already_registered: 'J’ai déjà un compte',
    password_recovery: 'Récupération de mot de passe',
    reset_password: 'Réinitialiser le mot de passe',
    invalid_restore_token:
      'On dirait que vous avez déjà restauré votre mot de passe par ce lien.',
    try_again: 'Réessayer',
    bid_online: 'Pour enchérir en ligne,',
    click_here: 'cliquer ici',
    register_intro:
      'Grâce à votre compte PIASA, vous serez en mesure d’enchérir lors de nos ventes, de commander nos catalogues, de gérer votre abonnement à la newsletter, et de mettre à jour vos données personnelles',
    login_intro:
      'Grâce à votre compte PIASA, vous serez en mesure d’enchérir lors de nos ventes, de commander nos catalogues, de gérer votre abonnement à la newsletter, et de mettre à jour vos données',
    errors: {
      must_least_18_years_old: 'You must be at least 18 years old to register',
    },
    text2: 'Mot de passe oublié ?',
    product_bidding:
      'Pour enchérir et participer à la vente aux enchères votre inscription est nécessaire',
  },
  profile: {
    account: 'Compte',
    cart: 'Achats en cours',
    documents: 'Documents',
    no_documents: "Il n'y a pas encore de documents",
    orders: 'Derniers achats',
    orders_not_found: "Il n'y a pas encore d'ordres",
    update: 'Modifer',
    save: 'Save',
    cancel: 'Cancel',
    identifiers: 'Identifiants',
    information: 'Informations',
    address: 'Adresse',
    birthday: 'Date de naissance',
    phone: 'Numéro de téléphone',
    post_code: 'Code postal',
    city: 'Ville',
    country: 'Pays',
    account_verification: 'Vérification du compte',
    account_verification_description:
      'Pour vérifier votre compte, veuillez télécharger votre RIB ainsi qu’un exemplaire de votre pièce d’identité',
    verification_add_files: 'Télécharger mes documents',
    ceiling: 'Plafond ',
  },
  cart: {
    empty: 'Le panier est vide',
    auction: 'Vente',
    category: 'Spécialité',
    price: 'Résultat + frais',
    purchase: 'Régler mon achat',
    remove: 'Retrait de l’objet',
    view: 'Voir le lot',
    bill: 'Facture',
  },
  contact_us: {
    PIASA: {
      PIASA: 'PIASA',
      location: {
        address: '118 rue du Faubourg Saint-Honoré',
        country: '75008 Paris France',
      },
      time: 'Du lundi au vendredi de 10h à 18h',
    },
    email: 'contact@piasa.fr',
    contact_us: 'Nous contacter',
    title:
      'Pour toute demande d’informations, vous pouvez remplir le formulaire ci-dessous ',
    subject: "L'objet de votre message",
    text: 'Votre message',
    send: 'Envoyer',
    subscribe_button: "S'abonner",
    subscribe:
      'Inscrivez-vous à notre Newsletter Soyez informé des dernières ventes aux enchères',
    storage: 'Garde-meuble',
    follow: 'Suivez-nous',
    storage_contacts: [
      '5 boulevard Ney',
      'Paris (75018) FRANCE',
      'uniquement sur rendez vous',
      '+33 (1) 40 34 88 81',
      'piasa-ney@piasa.fr',
    ],
  },
  estimation: {
    request: 'Demande d’estimation',
    my_estimations: 'Mes estimations',
    information: 'Information sur l’objet',
    title: 'Titre',
    dimensions: 'Dimensions',
    images: 'Images ou documents',
    agree:
      'En envoyant ce formulaire, vous accepter les conditions d’utilisation de ce site',
    send: 'Envoyer',
    add: 'Ajouter une photo',
    need_to_add_media: 'Vous devez télécharger au moins une image',
    empty: "Il n'y a pas encore d'estimation",
    estimated: 'Valide',
    rejected: 'Refuse',
    not_estimated: 'Pas encore évalué',
    category: 'Sélectionner une catégoie',
  },
  purchase: {
    title: 'Mes ordres d’achats',
    description: '',
    item: {
      sale: 'Vente',
      date: 'Date',
      lot: 'Lot',
      specialty: 'Spécialité',
      order: 'Ordre',
    },
    label: {
      valid: 'Validé',
      refuse: 'Réglé',
    },
    more: 'Voir le lot',
    no: {
      description: 'pas de description',
      info: 'aucune information',
      phone: 'pas de téléphone',
    },
    transport: 'Devis transport',
  },
  expertise: {
    title: 'Expertise',
    contacts_title: 'Pour toute expertise, vous pouvez prendre contact avec :',
    experts_list: 'Tous nos spécialistes',
    sale: 'Conditions générales de vente',
  },
  footer: {
    about: 'À propos',
    sell: 'Acheter & vendre',
    press: 'Presse',
    categories: 'Spécialités',
    legal_notice: 'Mentions légales',
    calendar: 'Calendrier',
  },
  news: {
    title: 'Actualités',
    other: 'Plus d’articles',
    follow: 'Suivez-nous sur Instagram',
  },
  artists: {
    title: 'Artistes',
    news: 'Actualités',
    products: 'Produits',
    future_auctions: 'Ventes futures',
    results: 'Résultats',
    estimation_request: "Faire une demande d'estimation gracieuse",
    show_more: 'VOIR PLUS',
  },
  collections: {
    title: 'Collections',
    contacts: 'Contacts',
    make_an_appointment: 'Prendre un rendez-vous',
    news: 'les grandes collections',
  },
  subscribe: {
    title: 'Newsletter',
    description: 'Inscrivez vous à la Newsletter',
    email: 'Addresse email',
    submit: 'S’inscrire',
    success: 'Abonnement enregistré avec succès',
  },
  not_found: {
    title: 'Désolé, cette page n’existe pas',
    text:
      'Si vous n’arrivez pas à accéder au contenu désiré, veuillez nous contacter',
    go_home: 'Retour à l’accueil',
  },
  payment: {
    complete: 'Paiement complet',
    success_text: 'Votre paiement a été accepté.',
    failed: 'Paiement échoué',
    failed_text: 'Votre paiement a été refusé.',
    already_paid: 'Cette commande a déjà été payée',
    card_label: 'Carte de crédit ou de débit',
    submit: 'Soumettre le paiement',
    field_placeholder: 'Numéro de carte',
    cancel: 'Paiement annuler',
    cancel_text: 'La commande a été annulée',
  },
  categories: {
    specialists: 'Contacts',
  },
  auction_purchase: {
    limit_amount: 'Montant limite de votre ordre',
    description: [
      "Joindre obligatoirement un RIB ainsi qu’une copie d'une pièce d’identité (passeport ou carte nationale d’identité).",
      'Les ordres d’achat écrits ou les enchères par téléphone sont une facilité pour les clients. Ni PIASA, ni ses employés ne pourront étre tenus responsables en cas d’erreur éventuelles ou omission dans leur exécution comme en cas de non exécution de ceux-ci.',
      "Les demandes d'enchères téléhoniques doivent impérativement nous parvenir au moins 24 jeures avant la vente.",
      "Ce service est offert pour les lots dont l'estimation basse est supérieure à 500€.",
    ],
    terms_label: [
      'J’ai pris connaissance des ',
      'conditions générales',
      ', informations et avis\n' +
        'imprimés dans le catalogue et accepte d être lié(e) par leur contenu ainsi que par toute modication pouvant\n' +
        'leur être apportée, soit par avis a ché dans la salle de vente, soit par annonce faite avant ou pendant la\n' +
        'vente. Je vous prie d’acquérir pour mon compte personnel, aux limites en euros, les lots que j’ai désignés\n' +
        'ci-contre (les limites ne comprenant pas les frais à la charge de l’acheteur).',
    ],
    leave_purchase: 'Laisser un ordre d’achat',
    RIB_label: 'Ajouter votre RIB',
    RIB_label2: 'Ajouter une image ou un document',
    passport_label: 'Ajouter votre pièce d’identité',
    submit: 'Envoyer',
    error: {
      license: 'Merci d’accepter les conditions générales',
      order: 'Merci d’indiquer le montant de votre ordre d’achat',
      phone: 'Veuillez entrer votre numéro de téléphone',
    },
    phone: 'Téléphone pendant la vente',
  },
  press: {
    title: 'La Presse',
    release: 'Télécharger le communiqué',
  },
  titles: {
    news: 'PIASA maison de vente aux enchères | Actualités',
    home: 'PIASA maison de vente aux enchères | Art & Design',
    calendar: 'PIASA maison de vente aux enchères | Calendrier',
    artists: 'PIASA maison de vente aux enchères | Artistes',
  },
  meta: {
    news:
      'Accédez à toutes les dernières actualités de la maison de ventes aux enchères PIASA en Art Moderne et Contemporain, Design, Bijoux, Mobilier et Objets d’Art.',
    home:
      'PIASA, maison de vente aux enchères, possède une identité unique grâce à ses sélections pointues en Art Moderne et Contemporain, Design, Bijoux, Mobilier et Objets d’Art',
    calendar:
      "PIASA, organise tout au long de l'années de nombreuses ventes aux enchères d'Art Moderne et Contemporain, Design, Bijoux, Mobilier et Objets d’Art.",
  },
  home: 'Accueil',
  show_more: 'Voir plus',
  cookies: {
    message:
      'En poursuivant votre navigation sur ce site, vous accepter l’utilisation de Cookies pour réaliser des statistiques de navigation, et pour faciliter le partage d’information sur les réseaux sociaux.',
    accept: 'Accepter',
  },
  bid_online:
    "PIASA vous propose d'enchérir en direct lors de ses ventes, depuis votre ordinateur. Pour vous inscrire à une vente et pouvoir enchérir en ligne, il vous suffit de créer un compte live ou de vous connecter à votre compte déjà existant.",
  live: {
    youAreLastBidder:
      "Vous êtes déjà le plus haut enchérisseur sur ce lot. Merci de patienter avant d'enchérir de nouveau.",
    title: 'Live',
    return_to_last_auction: 'Revenir à la dernière enchère',
    show_live_stream: 'Afficher le flux en direct',
    fair_warning: 'Fair warning',
    current_auction: 'Enchère Actuelle',
    internet_auction: 'Enchère Internet',
    change_price: 'Modifier prix',
    set_price: 'Fixer le prix',
    bidder: 'Enchérisseur',
    minimum_amount_for_next_bid: 'Palier',
    change_minimum_amount_for_next_bid: 'Modifier le palier',
    bidders: 'Enchérisseurs',
    watchers: 'Visiteurs',
    sold: 'Vendu / Passer au Lot suivant',
    sold_and_stop: 'Vendu et arrêté',
    skip: 'Passer le lot',
    previous: 'Revenir au Lot Précédent',
    next: 'Passer au lot suivant',
    lots_sold: 'Lots Vendus',
    search_lots: 'Rechercher des lots...',
    quick_bid: 'Enchère Rapide',
    make_bid: 'Enchère Direct',
    place_offer: 'Montant',
    stop: 'Arrêtez',
    start: 'Début',
    low_bid_value_error:
      'Votre enchère ne peut être inférieure à une enchère rapide',
    high_bid_value_error: 'Votre grand ne peut pas être plus de 1 999 999 999',
    you_are_best: 'Vous êtes le meilleur enchérisseur sur le lot {lot_number}',
    you_are_not_the_best:
      'Enchérisseur {bidder_id} a placer une offre plus grande que la votre',
    bidding_started: 'L’enchère du lot {lot_number} débute',
    auction_started: 'La vente {auction_name} débute',
    auction_will_start_soon: 'Le live commencera dans',
    auction_finished:
      "La vente est terminée. Merci d'avoir été avec nous. Nous vous recontacterons rapidement.",
    auction_finished_title: 'Vos lots :',
    auction_confirm:
      'Félicitations ! Votre inscription pour le live de la vente est pris en compte.',
    product_confirm_warning: "Warning: You're subscription is not approved yet",
    auction_confirm_warning:
      "Attention: Votre compte n'est pas encore vérifié. Merci d'uploader votre carte d'identité et votre RIB",
    auction_confirm_short_warning:
      "Merci d'uploader votre carte d'identité et votre RIB",
    auction_must_registered_title: "Vous n'êtes pas encore inscrit à la vente",
    online_auction_must_registered_title:
      "Vous n'êtes pas encore inscrit à la vente",
    auction_must_registered:
      "Pour voir et participer à la vente PIASA, une demande d'inscription est nécessaire",
    online_auction_must_registered:
      "Pour voir et participer à la vente PIASA, une demande d'inscription est nécessaire",
    no_permission:
      "Vous n'avez pas assez d'autorisations pour gérer cette enchère",
    back_to_auction: 'Retour à la vente aux enchères',
    youtube_stopping_error:
      "Nous avons quelques problèmes avec l'arrêt du flux youtube. " +
      "La vente aux enchères est terminée et vous devriez l'éteindre",
    subscribe: 'S’inscrire à la vente',
    subscribe_online: 'S’inscrire à la vente',
    already_subscribed: 'Vous êtes abonné avec succès à cette enchère',
    room_bidder: 'Floor bid',
    room_bid: 'Enchère dans la salle de {amount}',
    lot_not_selected: "Le lot actuel n'est pas sélectionné",
    countdown_days: 'Jours',
    countdown_hours: 'Heures',
    countdown_minutes: 'Minutes',
    countdown_seconds: 'Secondes',
    submit_bid: 'Enchérir',
    image_upload_success: 'Les images ont été téléchargées avec succès',
    image_upload_max_exceeded:
      'La taille maximum du fichier est 3Mo, merci de charger un document avec une résolution inférieure.',
    image_upload_wrong_ext:
      'Les extensions fichiers autorisées sont .jpg, .png et .pdf',
    user_is_vip:
      'Vous êtes un utilisateur VIP, votre compte est automatiquement validé',
    user_is_confirmed: 'Votre compte a déjà été vérifié',
    bidder_info: "Vous êtes l'enchérisseur : W{number}",
  },
  submit: 'Envoyer',
  adwords: {
    text:
      'Pour obtenir une estimation, inscrivez votre email et nous vous contacterons prochainement.',
    submit: 'Envoyer',
  },
  ib: {
    passport: "Document d'identité ",
    iban: 'Document bancaire',
    paddle: 'Votre numéro de paddle',
    limit: 'Votre plafond d’enchères est limité à ',
    ask: 'Je demande une augmentation',
    message: 'Message to admin:',
    writte: 'Writte message here...',
    close: 'X',
    send: 'Send',
    padd_img: 'Paddle ',
    sended: 'Files were sended!',
    valid: 'Document validé',
    validation: 'En attente de validation',
    increase_limit_intro: "Vous avez atteint votre limite d'enchère ",
    increase_limit_contact: "merci de nous contacter pour l'augmenter.",
    increase_success: 'Votre demande a été prise en compte!',
  },
  index: {
    currently: 'Actuellement en vente',
    coming: 'Prochainement en vente',
    estimate: 'Demander une estimation',
    buying_guide: 'Guide de l’acheteur',
    selling_guide: 'Guide du vendeur',
    stories: 'actualités',
    news: 'ACTUALITÉ',
    read_more: 'En savoir plus',
  },
  account: {
    title: 'Compléter votre compte',
    subtitle:
      'En complétant votre compte vous serez en mesure d’enchérir lors de nos ventes, de déposer des ordres, et de participer à nos ventes online-only.',
    particular: 'Particulier',
    professional: 'Professionnel',
    company: 'Société',
    address1: 'Adresse 1',
    address2: 'Adresse 2',
    postal_code: 'Code postal',
    area_code: '+0000',
    phone: '06 00 00 00 00',
    city: 'Ville',
    birthday: 'Date de naissance',
    locale: 'Langue de communication',
    legal_age: 'Je déclare que je suis majeur.',
    licence_agreement: 'Je déclare que je suis majeur',
    skip: 'Passer cette étape',
    submit: 'Valider',
    filter_country: 'Pays',
    filter_language: 'Langue de communication',
    filter_en: 'Anglais',
    filter_fr: 'Français',
  },
  validation: {
    title: 'Merci',
    text1:
      'Nous vous avons envoyé un e-mail. Merci de cliquer sur le lien de confirmation présent dans cet e-mail pour continuer la création de votre compte.',
    text2:
      'Si vous ne recevez pas ce message, pensez à consulter vos messages indésirables.',
    text3:
      'Afin d’éviter que ce message ne soit considéré comme indésirable, ajoutez digital@piasa.fr à votre liste de contacts.',
    subscription_agreement:
      "En enchérissant dans cette vente aux enchères, je reconnais avoir lu et accepté les termes et conditions énumérés dans les conditions de vente, y compris les avis importants et les conditions d'utilisation du site.",
  },
  my_bidding: {
    title: 'Mes enchères',
    time_left: 'Clôture dans:',
    estimate: 'Estimation',
    current_bid: 'Enchère actuelle',
    button_bid: 'Enchérir',
    button_stop_follow: 'Suivi',
    best_bid: 'Vous êtes le meilleur enchérisseur',
  },
  my_purchase: {
    title: 'Mes achats',
    estimate: 'Estimation',
    adjudication: 'Adjudication',
    transport: 'Devis transport',
  },
  bid: {
    title: 'Comment souhaitez vous enchérir ?',
    absentee: {
      title:'Ordre d’achat',
      'text-1': 'Je confirme le montant de mon enchère maximum hors frais de vente.',
      'text-2': 'En enchérissant dans cette vente aux enchères, je reconnais avoir lu et accepté les termes et conditions énumérés dans les Conditions de vente, y compris les avis importants et les conditions d\'utilisation du site.'
    },
    phone: {
      title: 'Ordre téléphonique',
      'text-1':'Vous déposez votre demande d\'enchères par téléphone. Nous vous appellerons le jour de la vente au moment de la mise en vente du lot choisi et transmettrons en direct vos enchères.',
      'text-2':'En enchérissant dans cette vente aux enchères, je reconnais avoir lu et accepté les termes et conditions énumérés dans les Conditions de vente, y compris les avis importants et les Conditions d\'utilisation du site.'
    },
    live: {
      text:'La plate-forme d’enchère en ligne vous permet de suivre la vente en temps réel et d’y participer directement depuis votre ordinateur.'
    }
  }
}

<template>
  <section class="auction__section">
    <div class="auction__lots-header">
      <div class="row">
        <div class="col col-25">
          <div class="input__wrap" v-if="enableSearch">
            <input
              class="input"
              type="text"
              :placeholder="$t('auctions.lots_search')"
              v-model="query"
            />
            <div class="input__inner">
              <svg
                v-svg
                symbol="icon-search"
                size="15 15"
                class="search__button-icon"
              ></svg>
            </div>
          </div>
        </div>
        <div class="col col-75">
          <div class="auction__lots-navigation">
            <div class="auction__lots-navigation-controller"></div>
            <div class="auction__lots-navigation-info">
              <div class="auction__lots-navigation-page">
                {{ soldLotsCount() }} / {{ auction.lots.length }}
              </div>
              <div class="auction__lots-navigation-sold">
                {{ $t('live.lots_sold') }} {{ getAuctionProgress() }}%
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="auction__lots-body">
      <div class="row">
        <live-streaming-lots-item
          class="col col-25"
          v-for="lot in filteredResults"
          :lot="lot"
          :auction="auction"
          :key="lot.id"
          @reopenned="$emit('reopenned', lot)"
          @clicked="$emit('lot-selected', lot)"
        />
      </div>
    </div>
  </section>
</template>

<script>
import LiveStreamingLotsItem from './LiveStreamingLotsItem'

export default {
  components: {
    LiveStreamingLotsItem,
  },
  name: 'live-streaming-lots-list',
  props: {
    auction: {
      type: Object,
      default: () => {
        return {}
      },
    },
    enableSearch: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      query: '',
    }
  },
  methods: {
    soldLotsCount() {
      return this.auction.lots.filter((lot) => !lot.product.count).length
    },
    getAuctionProgress() {
      return parseInt((this.soldLotsCount() / this.auction.lots.length) * 100)
    },
  },
  computed: {
    filteredResults() {
      return this.auction.lots.filter(
        (lot) =>
          lot.product.translation.name
            .toLowerCase()
            .indexOf(this.query.toLowerCase()) !== -1
      )
    },
  },
}
</script>

<template>
  <div class="auction__info">
    <h3 class="auction__info-title">
      <span class="auction__info-icon auction__info-icon--info"></span>
      Informations
    </h3>
    <ul class="auction__info-list">
      <li class="auction__info-item" v-if="isRoomBid">
        {{
          $t('live.room_bid', {
            amount: auction.data.live_streaming.current_price,
          })
        }}
        {{ currency }}
      </li>
      <li
        class="auction__info-item auction__info-item--active"
        v-if="isBestBidder"
      >
        {{
          $t('live.you_are_best', {
            lot_number: auction.data.live_streaming.current_lot,
          })
        }}
      </li>
      <li
        class="auction__info-item"
        v-if="bestBidderId && bestBidderId !== user.id"
      >
        {{ $t('live.you_are_not_the_best', { bidder_id: bestBidderId }) }}
      </li>
      <li class="auction__info-item">
        {{
          $t('live.bidding_started', {
            lot_number: auction.data.live_streaming.current_lot,
          })
        }}
      </li>
      <li class="auction__info-item">
        {{
          $t('live.auction_started', {
            auction_name: auction.translation.title.replace(
              /<(?:.|\\n)*?>/gm,
              ''
            ),
          })
        }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'client-auction-information',
  props: ['auction', 'user', 'bestBidderId', 'isBestBidder', 'isRoomBid'],
  computed: {
    currency() {
      if (this.$route.name === 'app.client_auction') {
        return this.auction.id === 330 ? 'ZAR' : '€'
      }
      return '€'
    },
  },
}
</script>

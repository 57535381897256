<template>
  <section class="ventes">
    <div class="ventes__links">
      <h1 class="ventes__link active">{{ $t('navigation.lots_followed') }}</h1>
    </div>
    <div class="loader placeholder" v-if="!isLoaded">
      <img src="/img/loader.svg" alt="Loader" />
    </div>
    <div
      v-for="(product, bidIndex) in user.product_subscriptions"
      :key="bidIndex"
      class="bid"
    >
      <img
        :src="getResizedImageUrl(product.media[0].link, '625x500')"
        alt="Piasa"
        class="bid__image"
      />
      <div class="bid__right">
        <div class="bid__top"></div>
        <p class="bid__title">
          {{
            product.translation.name
              ? product.translation.name
              : $t('purchase.no.info')
          }}
        </p>
        <div
          class="bid__description"
          v-html="
            product.translation.description
              ? product.translation.description
              : $t('purchase.no.description')
          "
        ></div>
        <div class="bid__bottom">
          <div class="bid__bottom-left">
            <p class="bid__est">
              {{ $t('my_bidding.estimate') }} : {{ product.min_estimation }} /
              {{ product.max_estimation }} €
            </p>
          </div>
          <div class="bid__bottom-right">
            <router-link
              v-if="!isInPast(product)"
              :to="{
                name: 'app.products.details',
                params: { slug: product.slug },
              }"
              class="bid__button button"
              >{{ $t('my_bidding.button_bid') }}</router-link
            >
            <button
              class="bid__button button bid__button--unfollow"
              @click.prevent="unsubscribe(product)"
            >
              <svg
                width="14"
                height="13"
                viewBox="0 0 14 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.7692 1.97656C10.4802 0.875 8.58174 1.08594 7.38643 2.30469L6.94112 2.77344L6.47237 2.30469C5.30049 1.08594 3.37862 0.875 2.08956 1.97656C0.612993 3.24219 0.542681 5.49219 1.85518 6.85156L6.40206 11.5391C6.68331 11.8438 7.17549 11.8438 7.45674 11.5391L12.0036 6.85156C13.3161 5.49219 13.2458 3.24219 11.7692 1.97656Z"
                  fill="white"
                />
                <path
                  d="M11.7692 1.97656L12.0946 1.59693L12.0941 1.59645L11.7692 1.97656ZM7.38643 2.30469L7.02942 1.95454L7.02393 1.96031L7.38643 2.30469ZM6.94112 2.77344L6.58756 3.12699L6.9503 3.48973L7.30362 3.11781L6.94112 2.77344ZM6.47237 2.30469L6.11188 2.65131L6.11881 2.65824L6.47237 2.30469ZM2.08956 1.97656L1.76473 1.59645L1.76416 1.59693L2.08956 1.97656ZM1.85518 6.85156L1.49548 7.19886L1.49628 7.19969L1.85518 6.85156ZM6.40206 11.5391L6.76957 11.1998L6.76095 11.1909L6.40206 11.5391ZM7.45674 11.5391L7.09774 11.1908L7.08934 11.1999L7.45674 11.5391ZM12.0036 6.85156L12.3625 7.19969L12.3633 7.19886L12.0036 6.85156ZM12.0941 1.59645C10.577 0.300019 8.38166 0.575875 7.02946 1.95458L7.7434 2.65479C8.78183 1.596 10.3834 1.44998 11.4444 2.35668L12.0941 1.59645ZM7.02393 1.96031L6.57862 2.42906L7.30362 3.11781L7.74893 2.64906L7.02393 1.96031ZM7.29467 2.41988L6.82592 1.95113L6.11881 2.65824L6.58756 3.12699L7.29467 2.41988ZM6.83278 1.95813C5.50094 0.57301 3.2799 0.301664 1.76473 1.59645L2.41438 2.35668C3.47734 1.44834 5.10005 1.59886 6.11195 2.65124L6.83278 1.95813ZM1.76416 1.59693C0.0658436 3.05263 -0.0139201 5.63555 1.49548 7.19886L2.21488 6.50426C1.09928 5.34882 1.16014 3.43174 2.41495 2.35619L1.76416 1.59693ZM1.49628 7.19969L6.04316 11.8872L6.76095 11.1909L2.21408 6.50343L1.49628 7.19969ZM6.03465 11.8782C6.51389 12.3974 7.34491 12.3974 7.82415 11.8782L7.08934 11.1999C7.00607 11.2901 6.85273 11.2901 6.76946 11.1999L6.03465 11.8782ZM7.81564 11.8872L12.3625 7.19969L11.6447 6.50343L7.09785 11.1909L7.81564 11.8872ZM12.3633 7.19886C13.8727 5.63555 13.793 3.05263 12.0946 1.59693L11.4438 2.35619C12.6987 3.43174 12.7595 5.34882 11.6439 6.50426L12.3633 7.19886Z"
                  fill="black"
                />
              </svg>
              {{ $t('my_bidding.button_stop_follow') }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="isLoaded && !user.auction_subscriptions.length"
      class="mock-container"
    >
      {{ $t('auctions.not_found') }}
    </div>
    <a v-if="isLoaded" class="ventes__refresh" @click="getProfile">
      <img src="~@/assets/img/refresh.png" alt="Refresh" />
    </a>
  </section>
</template>

<script>
import _, { isEmpty, get } from 'lodash'

export default {
  name: 'FollowedProducts',
  data() {
    return {
      isLoaded: false,
      bestBids: [],
      user: {
        auction_subscriptions: [],
      },
    }
  },
  computed: {
    auctionSubscriptions() {
      return _.orderBy(this.user.auction_subscriptions, 'started_at', 'desc')
    },
  },
  metaInfo() {
    return {
      title: 'My auctions',
    }
  },
  created() {
    this.getProfile()
  },
  methods: {
    getProfile() {
      const self = this

      this.$api.getProfile().then((response) => {
        self.user = response.data

        self.user.auction_subscriptions.forEach((auction) => {
          const bid = _.maxBy(auction.my_bids, (bid) => bid.price)
          if (bid) self.bestBids.push(bid)
        })

        this.isLoaded = true

        window.scrollTo({
          top: 100,
          left: 100,
          behavior: 'smooth',
        })
      })
    },
    needToAddMonth(index) {
      if (isEmpty(this.items)) {
        return false
      }

      const previousAuction = get(this.items, index - 1)

      if (isEmpty(previousAuction)) {
        return true
      }

      const previousAuctionMonth = this.moment(
        previousAuction.started_at
      ).format('M')
      const currentAuctionMonth = this.moment(
        this.items[index].started_at
      ).format('M')

      return previousAuctionMonth !== currentAuctionMonth
    },
    isInPast(item) {
      if (item.auctions.length)
        return this.moment(item.auctions[0].finished_at).isBefore()
      return false
    },
    lotNumber(number) {
      return number < 10 ? '0' + number : number
    },
    isBestBid(bid) {
      const res = this.bestBids.findIndex((item) => item.id === bid.id)

      return res !== -1
    },
    unsubscribe(product) {
      this.$api
        .unsubscribeOnProductBidding(product.id)
        .then(() => this.getProfile())
      this.$store.commit('updateProductSubscriptions', product)
    },
  },
}
</script>

<style lang="scss" scoped>
.ventes {
  padding-top: 0;

  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: 52px;
    line-height: 46px;
    text-transform: none;
    padding: 127px 0 151px 0;
    margin-bottom: 4rem;
  }
}
/*ventes__link ventes__title*/
.ventes {
  &__links {
    display: flex;
    justify-content: center;
    font-size: 5rem;
    font-weight: 700;
    padding-top: 5rem;
    border-bottom: solid 1px #e8e8e8;
  }

  &__link {
    color: #8b8b8b;
    transition: all 0.2s;
    cursor: pointer;
    padding: 0 1rem;

    &:hover {
      color: #000;
    }

    &.active {
      color: #000;
      cursor: auto;
    }
  }
}

.bid {
  display: flex;
  padding: 36px 29px 36px 51px;

  @media screen and (max-width: 1024px) {
    display: block;
    padding: 36px 25px 36px 31px;
  }

  &__image {
    width: 297px;
    height: 297px;
    object-fit: cover;

    @media screen and (max-width: 1024px) {
      width: unset;
      max-width: 100%;
      height: unset;
    }
  }

  &__right {
    width: 100%;
    margin-left: 36px;

    @media screen and (max-width: 1024px) {
      margin-left: 0;
    }
  }

  &__top {
    width: 100%;
    display: flex;

    @media screen and (max-width: 1024px) {
      display: block !important;
    }
  }

  &__id {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    color: #8b8b8b;
    margin-bottom: 11px;
  }

  &__counter {
    font-size: 20px;
    line-height: 26px;
    text-align: right;
    color: #000000;
    margin-left: auto;
    margin-bottom: 11px;

    @media screen and (max-width: 1024px) {
      font-size: 12px;
      text-align: left;
      width: 100%;
    }
  }

  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #000000;
    margin-bottom: 11px;

    @media screen and (max-width: 1024px) {
      font-size: 30px;
    }
  }

  &__description {
    font-size: 18px;
    line-height: 26px;
    color: #000000;
    margin-bottom: 8px;
    overflow: hidden;
    width: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }

  &__bottom {
    display: flex;

    @media screen and (max-width: 1024px) {
      display: block;
    }
  }

  &__bottom-right {
    margin-left: auto;
  }

  &__est {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #8b8b8b;
    margin-bottom: 7px;

    @media screen and (max-width: 1024px) {
      font-size: 16px;
    }

    span {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 26px;
      color: #4a4a4a;
      margin-left: 57px;

      @media screen and (max-width: 1024px) {
        font-size: 16px;
        margin-left: 25px;
      }
    }
  }

  &__current {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #000000;
    display: flex;

    @media screen and (max-width: 1024px) {
      font-size: 16px;
    }
  }

  &__best {
    width: 247px;
    height: 26px;
    background: #0db78d;
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    margin-left: 20px;
  }

  &__button {
    width: 134px;
    height: 31px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #ffffff;
    background: #363636;

    &:hover {
      color: #363636;
      background: #ffffff;
    }

    svg {
      margin-right: 4px;
      transition: all 0.2s;
    }

    &--light {
      color: #363636;
      background: #ffffff;
      margin-top: 20px;

      &:hover {
        color: #ffffff;
        background: #363636;

        & svg path {
          fill: #fff;
        }
      }
    }

    &--unfollow {
      border: 2px solid #363636;
      background: #363636;
      color: #fff;
      margin-top: 20px;

      & svg {
        margin-right: 6px;
      }
    }
  }
}
</style>

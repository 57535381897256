<template>
  <div class="auction__header">
    <h1 class="vente__title" v-html="auction.translation.title"></h1>
    <span class="vente__bidder_info" v-if="user.role_id !== 1">{{
      $t('live.bidder_info', { number: user.id })
    }}</span>
    <ul class="vente__descr-list">
      <li class="vente__descr-item">
        <svg
          v-svg
          symbol="icon-calendar"
          size="18 18"
          class="vente__descr-icon"
        ></svg>
        {{ getAuction(auction.started_at) }}
      </li>
      <li class="vente__descr-item">
        <svg
          v-svg
          symbol="icon-map-marker"
          size="18 18"
          class="vente__descr-icon"
        ></svg>
        {{ auction.location }}
      </li>
    </ul>
    <div class="auction-status" v-if="auction.data.live_streaming.on_air">
      Live
    </div>
  </div>
</template>

<script>
export default {
  name: 'online-auction-header',
  props: ['auction', 'user'],
  methods: {
    getAuction(startedAt) {
      return this.$t('auctions.auction_date', {
        date: this.moment(startedAt).format('dddd DD MMMM YYYY'),
        to: this.moment(startedAt).format('LT'),
      })
    },
  },
}
</script>

<style scoped>
.vente__title {
  max-width: 50%;
}

@media (max-width: 1024px) {
  .vente__title {
    max-width: none;
    font-size: 30px;
  }
}
</style>

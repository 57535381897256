<template>
  <div>
    <div class="loader" v-if="!isLoaded">
      <img src="/img/loader.svg" alt="" />
    </div>
    <section class="propos" v-if="isLoaded">
      <picture>
        <source
          :srcset="getSrcSet(press.media.link)"
          media="(max-width: 998px)"
        />
        <img
          class="page-main__header-img"
          :src="getResizedImageUrl(press.media.link, 'original')"
          :alt="press.media.name"
        />
      </picture>
      <breadcrumbs :steps="getBreadcrumbs()" context="context" />
      <article class="propos__info">
        <div class="row">
          <div class="col col-50">
            <div class="sticky">
              <h1 class="propos__title">{{ press.translation.title }}</h1>
              <portager />
              <div class="auction-block">
                <ul class="vente__info-list">
                  <li
                    class="vente__info-item"
                    v-if="press.translation.press_release"
                  >
                    <a
                      target="_blank"
                      :href="
                        $http.getBackendUrl() +
                        press.translation.press_release.link
                      "
                      class="link link--underline"
                    >
                      {{ $t('press.release') }}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col col-50">
            <p class="propos__info" v-html="press.translation.content"></p>
          </div>
        </div>
      </article>
    </section>
  </div>
</template>

<script>
import Portager from '../layout/Portager'
import Breadcrumbs from '../layout/Breadcrumbs'

export default {
  components: {
    Breadcrumbs,
    Portager,
  },
  name: 'press-details',
  metaInfo() {
    return {
      title: this.press.translation.title,
      meta: [
        {
          name: 'description',
          content: this.press.translation.meta_description,
        },
      ],
    }
  },
  data() {
    return {
      isLoaded: false,
      press: {
        translation: {
          title: '',
          description: '',
          press_release: {},
          press_release_id: null,
          meta_title: '',
          meta_description: '',
        },
        media: {
          link: '',
        },
      },
    }
  },
  created: function () {
    const self = this
    self.isLoaded = false

    this.$api.getPress(this.$route.params.id).then((response) => {
      self.press = response.data
      self.isLoaded = true
    })
  },
  methods: {
    getBreadcrumbs() {
      return [
        {
          to: {
            name: 'app.presses.list',
          },
          title: this.$t('navigation.press'),
        },
        {
          to: {
            name: 'app.presses.details',
            params: {
              id: this.$route.params.id,
            },
          },
          title: this.press.translation.title,
        },
      ]
    },
  },
}
</script>

<style scoped>
.propos__title {
  margin-bottom: 2rem;
}

.sticky {
  position: sticky;
  top: 2rem;
}

.auction-block {
  margin-top: 20px;
}

.loader {
  text-align: center;
  top: 200px;
  width: 50px;
  min-height: 500px;
  margin: auto;
  position: relative;
}
</style>

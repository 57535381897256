<template>
  <section class="propos">
    <div class="page-main__header-img">
      <google-map
        class="google-map"
        address="118 rue du Faubourg Saint-Honoré 75008 Paris France"
      ></google-map>
    </div>
    <breadcrumbs :steps="getBreadcrumbs()"></breadcrumbs>
    <h1 class="contact__title">{{ $t('contact_us.contact_us') }}</h1>
    <div class="contact__info">
      <div class="row">
        <div class="col col-33">
          <h3 class="contact__info-title">
            {{ $t('contact_us.PIASA.PIASA') }}
          </h3>
          <ul class="contact__info-list">
            <li class="contact__info-item">
              <svg
                v-svg
                symbol="icon-map-marker"
                size="18 18"
                class="icon"
              ></svg
              >{{ $t('contact_us.PIASA.location.address') }}
              <div>{{ $t('contact_us.PIASA.location.country') }}</div>
            </li>
            <li class="contact__info-item">
              <svg v-svg symbol="icon-clock-o" size="18 18" class="icon"></svg
              >{{ $t('contact_us.PIASA.time') }}
            </li>
            <li class="contact__info-item">
              <svg v-svg symbol="icon-phone" size="18 18" class="icon" />
              <a href="tel:+330153341010" class="link">+33 (0)1 53 34 10 10</a>
            </li>
            <li class="contact__info-item">
              <svg v-svg symbol="icon-email" size="18 18" class="icon" />
              <a href="mailto:contact@piasa.fr" class="link">{{
                $t('contact_us.email')
              }}</a>
            </li>
          </ul>
        </div>
        <div class="col col-33">
          <h3 class="contact__info-title">{{ $t('contact_us.storage') }}</h3>
          <ul class="contact__info-list">
            <li class="contact__info-item">
              <svg v-svg symbol="icon-map-marker" size="18 18" class="icon" />
              {{ $t('contact_us.storage_contacts.0') }}
            </li>
            <li class="contact__info-item">
              {{ $t('contact_us.storage_contacts.1') }}
            </li>
            <li class="contact__info-item">
              <svg v-svg symbol="icon-clock-o" size="18 18" class="icon" />
              {{ $t('contact_us.storage_contacts.2') }}
            </li>
            <li class="contact__info-item">
              <svg v-svg symbol="icon-phone" size="18 18" class="icon" />
              <a href="tel:+330140348884" class="link">+33 (0)1 53 34 10 10</a>
            </li>
            <li class="contact__info-item">
              <svg v-svg symbol="icon-email" size="18 18" class="icon" />
              <a href="mailto:piasa-ney@piasa.fr" class="link">{{
                $t('contact_us.storage_contacts.4')
              }}</a>
            </li>
          </ul>
        </div>
        <div class="col col-33">
          <h3 class="contact__info-title">{{ $t('contact_us.follow') }}</h3>
          <ul class="contact__social-list social__list">
            <li class="social__item">
              <a
                href="https://www.facebook.com/PiasaAuction/"
                class="social__link"
              >
                <svg
                  v-svg
                  symbol="icon-facebook-f"
                  size="20 20"
                  class="social__link-icon"
                />
                <span class="visually-hidden">Facebook</span>
              </a>
            </li>
            <li class="social__item">
              <a
                href="https://www.instagram.com/piasa_auction/"
                class="social__link"
              >
                <svg
                  v-svg
                  symbol="icon-instagram"
                  size="20 20"
                  class="social__link-icon"
                />
                <span class="visually-hidden">Instagram</span>
              </a>
            </li>
            <li class="social__item">
              <a
                href="https://pinterest.com/piasa_auction/"
                class="social__link"
              >
                <svg
                  v-svg
                  symbol="icon-pinterest-p"
                  size="20 20"
                  class="social__link-icon"
                />
                <span class="visually-hidden">Pinterest</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Breadcrumbs from '../layout/Breadcrumbs'
import GoogleMap from '../layout/GoogleMap'

export default {
  components: {
    Breadcrumbs,
    GoogleMap,
  },
  name: 'contacts',
  methods: {
    getBreadcrumbs() {
      return [
        {
          to: {
            name: 'app.contact_us',
          },
          title: this.$t('contact_us.contact_us'),
        },
      ]
    },
  },
}
</script>

<style scoped>
.google-map {
  width: 100%;
  height: 100%;
}
</style>

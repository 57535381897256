import { last, get, sortBy, isEmpty } from 'lodash'

export default {
  methods: {
    submitBid(bidId) {
      const bid = this.bids.find((bid) => bid.id === bidId)
      if (bid) {
        bid.is_submitted = true
      }
    },
  },
  computed: {
    biddable() {
      return this.auction.biddable_online
    },
    startingPrice() {
      return this.product.price
    },
    buyableOnline() {
      return this.product.buyable_online
    },
    biddingEnd() {
      return this.moment(this.auction.finished_at)
        .add(2 * (this.product.lots[0].lot_number - 1), 'minutes')
        .diff(this.moment())
    },
    biddingActive() {
      return (
        this.moment(this.auction.started_at).isBefore() &&
        !this.auction.finished &&
        this.biddingEnd > 0
      )
    },
    biddingInFuture() {
      return this.moment(this.auction.started_at).isAfter()
    },
    biddingInPast() {
      if (this.biddable) {
        return this.biddingEnd < 0
      } else return this.auction.finished
    },
    bids() {
      return this.product.bids
    },
    bestBid() {
      const orderedUserBids = sortBy(this.bids, 'created_at')
      return last(orderedUserBids)
    },
    bestBidAmount() {
      return get(this.bestBid, 'price', this.startingPrice)
    },
    highestBidder() {
      if (this.bestBid) {
        return this.bestBid.user_id === this.user.id
      } else {
        return false
      }
    },
    directBid() {
      if (this.bids.length) {
        return this.bestBidAmount + this.minimumRateIncrease
      } else return this.bestBidAmount
    },
    bestUserBid() {
      const filteredUserBids = this.bids.filter(
        (bid) => bid.user_id === this.user.id
      )
      const orderedUserBids = sortBy(filteredUserBids, 'created_at')
      return last(orderedUserBids)
    },
    bestUserBidId() {
      const bid = this.bestUserBid

      return bid ? bid.id : undefined
    },
    bestUserBidAmount() {
      return get(this.bestUserBid, 'price', 0)
    },
    isSubscribed() {
      return this.user.auction_subscriptions.some(
        (auction) => this.auction.id === auction.id
      )
    },
    subscription() {
      return this.user.auction_subscriptions.find(
        (auction) => this.auction.id === auction.id
      )
    },
    isConfirmed() {
      if (this.subscription) {
        if (
          this.subscription.pivot.is_confirmed === null &&
          !this.user.profile.vip
        ) {
          return false
        }

        if (
          this.subscription.pivot.is_confirmed === true ||
          this.user.profile.vip
        ) {
          return true
        }
      }

      return false
    },
    biddingLimit() {
      if (this.user.product_limits.length) {
        const filteredProductLimits = this.user.product_limits.filter(
          (limit) => this.product.id === limit.product_id
        )
        const orderedProductLimits = sortBy(filteredProductLimits, 'updated_at')
        return last(orderedProductLimits)
      }

      return false
    },
    userLoggedIn() {
      return !isEmpty(this.$store.state.user)
    },
  },
}

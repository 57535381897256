<template>
  <div>
    <div class="intro">
      <span class="product__number">{{ product.lots[0].lot_number }}</span>
      <div v-if="!biddingInPast">
        <a
          v-if="subscribed(product)"
          class="button product-button product-button--gray product-button--unfollow"
          @click.prevent="unsubscribe(product)"
        >
          <svg
            width="14"
            height="13"
            viewBox="0 0 14 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.7692 1.97656C10.4802 0.875 8.58174 1.08594 7.38643 2.30469L6.94112 2.77344L6.47237 2.30469C5.30049 1.08594 3.37862 0.875 2.08956 1.97656C0.612993 3.24219 0.542681 5.49219 1.85518 6.85156L6.40206 11.5391C6.68331 11.8438 7.17549 11.8438 7.45674 11.5391L12.0036 6.85156C13.3161 5.49219 13.2458 3.24219 11.7692 1.97656Z"
              fill="white"
            />
            <path
              d="M11.7692 1.97656L12.0946 1.59693L12.0941 1.59645L11.7692 1.97656ZM7.38643 2.30469L7.02942 1.95454L7.02393 1.96031L7.38643 2.30469ZM6.94112 2.77344L6.58756 3.12699L6.9503 3.48973L7.30362 3.11781L6.94112 2.77344ZM6.47237 2.30469L6.11188 2.65131L6.11881 2.65824L6.47237 2.30469ZM2.08956 1.97656L1.76473 1.59645L1.76416 1.59693L2.08956 1.97656ZM1.85518 6.85156L1.49548 7.19886L1.49628 7.19969L1.85518 6.85156ZM6.40206 11.5391L6.76957 11.1998L6.76095 11.1909L6.40206 11.5391ZM7.45674 11.5391L7.09774 11.1908L7.08934 11.1999L7.45674 11.5391ZM12.0036 6.85156L12.3625 7.19969L12.3633 7.19886L12.0036 6.85156ZM12.0941 1.59645C10.577 0.300019 8.38166 0.575875 7.02946 1.95458L7.7434 2.65479C8.78183 1.596 10.3834 1.44998 11.4444 2.35668L12.0941 1.59645ZM7.02393 1.96031L6.57862 2.42906L7.30362 3.11781L7.74893 2.64906L7.02393 1.96031ZM7.29467 2.41988L6.82592 1.95113L6.11881 2.65824L6.58756 3.12699L7.29467 2.41988ZM6.83278 1.95813C5.50094 0.57301 3.2799 0.301664 1.76473 1.59645L2.41438 2.35668C3.47734 1.44834 5.10005 1.59886 6.11195 2.65124L6.83278 1.95813ZM1.76416 1.59693C0.0658436 3.05263 -0.0139201 5.63555 1.49548 7.19886L2.21488 6.50426C1.09928 5.34882 1.16014 3.43174 2.41495 2.35619L1.76416 1.59693ZM1.49628 7.19969L6.04316 11.8872L6.76095 11.1909L2.21408 6.50343L1.49628 7.19969ZM6.03465 11.8782C6.51389 12.3974 7.34491 12.3974 7.82415 11.8782L7.08934 11.1999C7.00607 11.2901 6.85273 11.2901 6.76946 11.1999L6.03465 11.8782ZM7.81564 11.8872L12.3625 7.19969L11.6447 6.50343L7.09785 11.1909L7.81564 11.8872ZM12.3633 7.19886C13.8727 5.63555 13.793 3.05263 12.0946 1.59693L11.4438 2.35619C12.6987 3.43174 12.7595 5.34882 11.6439 6.50426L12.3633 7.19886Z"
              fill="black"
            />
          </svg>
          {{ $t('my_bidding.button_stop_follow') }}
        </a>
        <a
          v-else
          class="button product-button product-button--gray"
          @click.prevent="subscribe(product)"
        >
          <svg
            width="14"
            height="13"
            viewBox="0 0 14 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.7692 1.97656C10.4802 0.875 8.58174 1.08594 7.38643 2.30469L6.94112 2.77344L6.47237 2.30469C5.30049 1.08594 3.37862 0.875 2.08956 1.97656C0.612993 3.24219 0.542681 5.49219 1.85518 6.85156L6.40206 11.5391C6.68331 11.8438 7.17549 11.8438 7.45674 11.5391L12.0036 6.85156C13.3161 5.49219 13.2458 3.24219 11.7692 1.97656Z"
              fill="white"
            />
            <path
              d="M11.7692 1.97656L12.0946 1.59693L12.0941 1.59645L11.7692 1.97656ZM7.38643 2.30469L7.02942 1.95454L7.02393 1.96031L7.38643 2.30469ZM6.94112 2.77344L6.58756 3.12699L6.9503 3.48973L7.30362 3.11781L6.94112 2.77344ZM6.47237 2.30469L6.11188 2.65131L6.11881 2.65824L6.47237 2.30469ZM2.08956 1.97656L1.76473 1.59645L1.76416 1.59693L2.08956 1.97656ZM1.85518 6.85156L1.49548 7.19886L1.49628 7.19969L1.85518 6.85156ZM6.40206 11.5391L6.76957 11.1998L6.76095 11.1909L6.40206 11.5391ZM7.45674 11.5391L7.09774 11.1908L7.08934 11.1999L7.45674 11.5391ZM12.0036 6.85156L12.3625 7.19969L12.3633 7.19886L12.0036 6.85156ZM12.0941 1.59645C10.577 0.300019 8.38166 0.575875 7.02946 1.95458L7.7434 2.65479C8.78183 1.596 10.3834 1.44998 11.4444 2.35668L12.0941 1.59645ZM7.02393 1.96031L6.57862 2.42906L7.30362 3.11781L7.74893 2.64906L7.02393 1.96031ZM7.29467 2.41988L6.82592 1.95113L6.11881 2.65824L6.58756 3.12699L7.29467 2.41988ZM6.83278 1.95813C5.50094 0.57301 3.2799 0.301664 1.76473 1.59645L2.41438 2.35668C3.47734 1.44834 5.10005 1.59886 6.11195 2.65124L6.83278 1.95813ZM1.76416 1.59693C0.0658436 3.05263 -0.0139201 5.63555 1.49548 7.19886L2.21488 6.50426C1.09928 5.34882 1.16014 3.43174 2.41495 2.35619L1.76416 1.59693ZM1.49628 7.19969L6.04316 11.8872L6.76095 11.1909L2.21408 6.50343L1.49628 7.19969ZM6.03465 11.8782C6.51389 12.3974 7.34491 12.3974 7.82415 11.8782L7.08934 11.1999C7.00607 11.2901 6.85273 11.2901 6.76946 11.1999L6.03465 11.8782ZM7.81564 11.8872L12.3625 7.19969L11.6447 6.50343L7.09785 11.1909L7.81564 11.8872ZM12.3633 7.19886C13.8727 5.63555 13.793 3.05263 12.0946 1.59693L11.4438 2.35619C12.6987 3.43174 12.7595 5.34882 11.6439 6.50426L12.3633 7.19886Z"
              fill="black"
            />
          </svg>
          {{ $t('auctions.fallow') }}</a
        >
      </div>
    </div>
    <h1 class="product__title">
      <b><label v-html="getProductName()"></label></b>
    </h1>
  </div>
</template>

<script>
import BidsMixin from '@/components/products/mixins/bids.mixin'

export default {
  name: 'product-main-info',
  props: {
    product: {
      type: Object,
      required: true,
    },
    auction: {
      type: Object,
      required: true,
    },
  },
  mixins: [BidsMixin],
  methods: {
    getProductName() {
      return this.product.translation.name.replace(/(?:\r\n|\r|\n)/g, '<br />')
    },
    subscribed(product) {
      if (this.$store.state.user.product_subscriptions) {
        const index = this.$store.state.user.product_subscriptions.findIndex(
          (item) => item.id === product.id
        )
        return index !== -1
      }
    },
    subscribe(product) {
      if (this.$store.state.token) {
        this.$api.subscribeOnProductBidding(product.id)
        this.$store.commit('updateProductSubscriptions', product)
        return
      }
      this.$router.push({
        name: 'app.sign_in',
      })
    },
    unsubscribe(product) {
      if (this.$store.state.token) {
        this.$api.unsubscribeOnProductBidding(product.id)
        this.$store.commit('updateProductSubscriptions', product)
        return
      }
      this.$router.push({
        name: 'app.sign_in',
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.intro {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 28px;
}

.intro .product__number {
  font-style: normal;
  font-weight: 500;
  font-size: 27px;
  line-height: 32px;
  color: #8b8b8b;
}

.intro .button.product-button {
  width: 136px;
  height: 31px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;

  i {
    margin-right: 6px;
  }

  svg {
    margin-right: 6px;
  }

  &--gray {
    border: 2px solid #8b8b8b;
    box-sizing: border-box;
  }

  &--unfollow {
    border: 2px solid #363636;
    background: #363636;
    color: #fff;
  }
}

i.fas.fa-heart {
  margin-right: 1em;
}

.product__title {
  font-weight: 600;
  font-size: 26px;
  line-height: 26px;
  color: #000000;
  font-style: normal;
}
</style>

<template>
  <div>
    <a
      v-if="!isEmptyCondition && conditionReportSuccess === null"
      @click="onClickShowConditionReport()"
      class="condition-report-link"
      target="_top"
    >
      {{ $t('products.condition_report') }}
    </a>
    <a
      v-if="isEmptyCondition && conditionReportSuccess === null"
      @click="sendReport()"
      class="condition-report-link"
      target="_top"
    >
      {{ $t('products.condition_report') }}
    </a>
    <a
      v-if="conditionReportSuccess === true"
      class="link"
      target="_top"
      disabled
    >
      {{ $t('products.condition_report_success') }}
    </a>
    <a
      v-if="conditionReportSuccess === false && !error"
      class="link"
      target="_top"
      disabled
    >
      {{ $t('products.condition_report_failed') }}
    </a>
    <a
      v-if="conditionReportSuccess === false && error"
      class="link"
      target="_top"
      disabled
    >
      {{ error }}
    </a>
    <template v-if="$store.state.token">
      <condition-report-modal
        :auction="auction"
        :product="product"
      ></condition-report-modal>
    </template>
  </div>
</template>

<script>
import ConditionReportModal from './ConditionReportModal'

export default {
  name: 'ConditionReportRequest',
  components: { ConditionReportModal },
  props: {
    lotId: {
      required: true,
      type: Number,
    },
    auction: {
      required: true,
      type: Object,
    },
    product: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      conditionReportSuccess: null,
      error: null,
    }
  },
  methods: {
    sendReport() {
      if (this.$http.isTokenExpired()) {
        this.$router.push({ name: 'app.sign_in' })

        return
      }

      const self = this

      this.$api
        .sendReport(this.lotId)
        .then(() => {
          self.conditionReportSuccess = true
        })
        .catch((error) => {
          if (error.response) {
            self.error =
              error.response.data.error || error.response.data.message
          }

          self.conditionReportSuccess = false
        })
    },
    onClickShowConditionReport() {
      if (this.store.state.token) {
        this.$modal.show('condition-report-modal')
      } else {
        this.$store.commit('setRedirectBackUrl', location.pathname)

        this.$router.push({ name: 'app.sign_in' })
      }
    },
  },
  computed: {
    isEmptyCondition() {
      return this.product.condition_media.length === 0
    },
  },
}
</script>

<style lang="scss" scoped>
.condition-report-link {
  width: 206px;
  height: 51px;
  background: #ffffff;
  border: 1px solid #000000;
  $font-family: 'Futura', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 46px;
  text-align: center;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.25s;

  &:hover {
    background: #363636;
    color: #fff;
  }
}
</style>

<template>
  <section class="ventes">
    <div class="ventes__links">
      <h1 class="ventes__link active">{{ $t('my_bidding.title') }}</h1>
      <router-link class="ventes__link" :to="{ name: 'app.orders.list' }"
        >{{ $t('navigation.my_purchases') }}
      </router-link>
    </div>
    <div class="loader placeholder" v-if="!isLoaded">
      <img src="/img/loader.svg" alt="Loader" />
    </div>
    <div v-for="(auction, index) in auctionSubscriptions">
      <div v-if="needToAddMonth(index)" class="ventes__monthly">
        {{ moment(item.started_at).format('MMMM YYYY') }}
      </div>
      <auction-item :auction="auction" :border="false" />
      <div
        v-for="(bid, bidIndex) in getLatestBids(auction.my_bids)"
        :key="bidIndex"
        :bid="bid.lot.product"
        class="bid"
      >
        <img
          :src="getResizedImageUrl(bid.lot.product.media[0].link, '625x500')"
          alt="Piasa"
          class="bid__image"
        />
        <div class="bid__right">
          <div class="bid__top">
            <p class="bid__id">{{ lotNumber(bid.lot.lot_number) }}</p>
            <p
              class="bid__counter"
              v-if="auction.finished_at && !isInPast(auction.finished_at)"
            >
              {{ $t('my_bidding.time_left') }} :
              <countdown
                :time="
                  new Date(auction.finished_at).getTime() - new Date().getTime()
                "
              >
                <template slot-scope="props"
                  >{{ props.days }} j : {{ props.hours }} h :
                  {{ props.minutes }} min : {{ props.seconds }} sec
                </template>
              </countdown>
            </p>
          </div>
          <p class="bid__title">
            {{
              bid.lot.product.translation.name
                ? bid.lot.product.translation.name
                : $t('purchase.no.info')
            }}
          </p>
          <div
            class="bid__description"
            v-html="
              bid.lot.product.translation.description
                ? bid.lot.product.translation.description
                : $t('purchase.no.description')
            "
          ></div>
          <div class="bid__bottom">
            <div class="bid__bottom-left">
              <p class="bid__est">
                {{ $t('my_bidding.estimate') }} :
                {{ bid.lot.product.min_estimation }} /
                {{ bid.lot.product.max_estimation }} €
              </p>
              <p
                class="bid__current"
                v-if="bid.lot.product.bids.length && !auction.finished"
              >
                {{ $t('my_bidding.current_bid') }} : {{ bidPrice(bid) }} €
                <span v-if="isBestBid(bid)" class="bid__best">{{
                  $t('my_bidding.best_bid')
                }}</span>
              </p>
              <p
                class="bid__current"
                v-if="bid.lot.product.bids.length && auction.finished"
              >
                {{ $t('products.result') }} : {{ bidPrice(bid) }} €
                <span v-if="isBestBid(bid)" class="bid__best">{{
                  $t('my_bidding.best_bid')
                }}</span>
              </p>
            </div>
            <div
              class="bid__bottom-right"
              v-if="!isInPast(auction.finished_at)"
            >
              <router-link
                :to="{
                  name: 'app.products.details',
                  params: { slug: bid.lot.product.slug },
                }"
                class="bid__button button"
                >{{ $t('my_bidding.button_bid') }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="isLoaded && !user.auction_subscriptions.length"
      class="mock-container"
    >
      {{ $t('auctions.not_found') }}
    </div>
    <a v-if="isLoaded" class="ventes__refresh" @click="getProfile">
      <img src="~@/assets/img/refresh.png" alt="Refresh" />
    </a>
  </section>
</template>

<script>
import _, { isEmpty, get, uniqBy } from 'lodash'
import AuctionItem from './common/AuctionItem'
import VueCountdown from '@chenfengyuan/vue-countdown'
import Vue from 'vue'

Vue.component(VueCountdown.name, VueCountdown)

export default {
  name: 'MyAuctions',
  components: { AuctionItem },
  data() {
    return {
      isLoaded: false,
      bestBids: [],
      user: {
        auction_subscriptions: [],
      },
    }
  },
  computed: {
    auctionSubscriptions() {
      return _.orderBy(this.user.auction_subscriptions, 'started_at', 'desc')
    },
  },
  metaInfo() {
    return {
      title: 'My auctions',
    }
  },
  created() {
    this.getProfile()
  },
  methods: {
    getProfile() {
      const self = this

      this.$api.getProfile().then((response) => {
        self.user = response.data

        self.user.auction_subscriptions.forEach((auction) => {
          if (auction.my_bids.length) {
            const bids = auction.my_bids.filter(
              (bid) => bid.type === 'online_bid'
            )

            uniqBy(bids, 'product_id').forEach((bid) => {
              if (bid.price === _.maxBy(bid.lot.product.bids, 'price').price) {
                self.bestBids.push(bid)
              }
            })
          }
        })

        this.isLoaded = true

        window.scrollTo({
          top: 100,
          left: 100,
          behavior: 'smooth',
        })
      })
    },
    needToAddMonth(index) {
      if (isEmpty(this.items)) {
        return false
      }

      const previousAuction = get(this.items, index - 1)

      if (isEmpty(previousAuction)) {
        return true
      }

      const previousAuctionMonth = this.moment(
        previousAuction.started_at
      ).format('M')
      const currentAuctionMonth = this.moment(
        this.items[index].started_at
      ).format('M')

      return previousAuctionMonth !== currentAuctionMonth
    },
    bidPrice(bid) {
      return _.maxBy(bid.lot.product.bids, 'price').price
    },
    isInPast(item) {
      return this.moment(item).isBefore()
    },
    lotNumber(number) {
      return number < 10 ? '0' + number : number
    },
    isBestBid(bid) {
      const result = this.bestBids.find((item) => item.id === bid.id)
      return !!result
    },
    unsubscribe(product) {
      this.$api
        .unsubscribeOnProductBidding(product.id)
        .then(() => this.getProfile())
      this.$store.commit('updateProductSubscriptions', product)
    },
    getLatestBids(bids) {
      return uniqBy(bids, 'product_id')
    },
  },
}
</script>

<style lang="scss" scoped>
.ventes {
  padding-top: 0;

  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: 52px;
    line-height: 46px;
    text-transform: none;
    padding: 127px 0 151px 0;
    margin-bottom: 4rem;
  }
}

/*ventes__link ventes__title*/
.ventes {
  &__links {
    display: flex;
    justify-content: center;
    font-size: 5rem;
    font-weight: 700;
    padding-top: 5rem;
    border-bottom: solid 1px #e8e8e8;
  }

  &__link {
    color: #8b8b8b;
    transition: all 0.2s;
    cursor: pointer;
    padding: 0 1rem;

    &:hover {
      color: #000;
    }

    &.active {
      color: #000;
      cursor: auto;
    }
  }
}

.bid {
  display: flex;
  padding: 36px 29px 36px 51px;

  @media screen and (max-width: 1024px) {
    display: block;
    padding: 36px 25px 36px 31px;
  }

  &__image {
    width: 297px;
    height: 297px;
    object-fit: cover;

    @media screen and (max-width: 1024px) {
      width: unset;
      max-width: 100%;
      height: unset;
    }
  }

  &__right {
    width: 100%;
    margin-left: 36px;

    @media screen and (max-width: 1024px) {
      margin-left: 0;
    }
  }

  &__top {
    width: 100%;
    display: flex;

    @media screen and (max-width: 1024px) {
      display: block !important;
    }
  }

  &__id {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    color: #8b8b8b;
    margin-bottom: 11px;
  }

  &__counter {
    font-size: 20px;
    line-height: 26px;
    text-align: right;
    color: #000000;
    margin-left: auto;
    margin-bottom: 11px;

    @media screen and (max-width: 1024px) {
      font-size: 12px;
      text-align: left;
      width: 100%;
    }
  }

  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #000000;
    margin-bottom: 11px;

    @media screen and (max-width: 1024px) {
      font-size: 30px;
    }
  }

  &__description {
    font-size: 18px;
    line-height: 26px;
    color: #000000;
    margin-bottom: 8px;
    overflow: hidden;
    width: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }

  &__bottom {
    display: flex;

    @media screen and (max-width: 1024px) {
      display: block;
    }
  }

  &__bottom-right {
    margin-left: auto;
  }

  &__est {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #8b8b8b;
    margin-bottom: 7px;

    @media screen and (max-width: 1024px) {
      font-size: 16px;
    }

    span {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 26px;
      color: #4a4a4a;
      margin-left: 57px;

      @media screen and (max-width: 1024px) {
        font-size: 16px;
        margin-left: 25px;
      }
    }
  }

  &__current {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #000000;
    display: flex;

    @media screen and (max-width: 1024px) {
      font-size: 16px;
    }
  }

  &__best {
    width: 247px;
    height: 26px;
    background: #0db78d;
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    margin-left: 20px;
  }

  &__button {
    width: 134px;
    height: 31px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #ffffff;
    background: #363636;

    &:hover {
      color: #363636;
      background: #ffffff;
    }

    svg {
      margin-right: 4px;
      transition: all 0.2s;
    }

    &--light {
      color: #363636;
      background: #ffffff;
      margin-top: 20px;

      &:hover {
        color: #ffffff;
        background: #363636;

        & svg path {
          fill: #fff;
        }
      }
    }
  }
}
</style>

<template>
  <section class="blog">
    <header class="blog__header">
      <h1 class="blog__title">{{ $t('artists.title') }}</h1>
    </header>
    <div class="blog__list">
      <div class="row">
        <div
          class="col"
          v-for="(artists, index) in artistsList"
          :class="{
            'col-50': index < 2,
            'col-33': index >= 2,
          }"
        >
          <artists-list-item :artists="artists" />
        </div>
      </div>
      <infinite-loading
        ref="loader"
        @infinite="loadMore(arguments[0])"
        spinner="waveDots"
        :distance="1000"
        v-if="hasMore"
      >
        <span slot="no-more"></span>
      </infinite-loading>
    </div>
  </section>
</template>

<script>
import ArtistsListItem from './ArtistsListItem'
import InfiniteLoading from 'vue-infinite-loading'
import { clone, isEmpty } from 'lodash'

export default {
  components: {
    ArtistsListItem,
    InfiniteLoading,
  },
  name: 'artists-list',
  metaInfo() {
    return {
      title: this.$t('titles.artists'),
      meta: [{ name: 'name', content: this.$t('meta.artists') }],
    }
  },
  data() {
    return {
      artistsList: [],
      searchFilters: {
        page: 1,
        per_page: 14,
        draft: false,
      },
      hasMore: true,
    }
  },
  methods: {
    loadMore($state) {
      const self = this

      this.$api.searchArtists(clone(this.searchFilters)).then((response) => {
        self.addItems(response.data.data, $state)
      })

      this.searchFilters.page++
    },
    addItems(items, $state) {
      this.artistsList = this.artistsList.concat(items)

      if (isEmpty(items)) {
        $state.complete()
      } else {
        $state.loaded()
      }
    },
  },
}
</script>

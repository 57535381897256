import { merge, pickBy, isNull, clone } from 'lodash'

export default {
  methods: {
    search(newFilters) {
      this.searchFilters = merge(this.searchFilters, newFilters)

      this.searchFilters = pickBy(this.searchFilters, (value) => {
        return !isNull(value)
      })

      this.searchFilters.page = 1

      this.$refs.auctionList.clear()
    },
    loadMore($state) {
      const self = this

      this.$api.searchAuctions(clone(this.searchFilters)).then((response) => {
        const auctions = response.data.data

        self.$refs.auctionList.addItems(clone(auctions), $state)
      })

      this.searchFilters.page++
    },
  },
}

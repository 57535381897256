<template>
  <section class="ventes">
    <h1 class="ventes__title">{{ $t('navigation.future_auctions') }}</h1>
    <auction-list
      ref="auctionList"
      @load-more="loadMore(arguments[0])"
      @search="search(arguments[0])"
      :categories="categories"
    >
    </auction-list>
    <a class="ventes__refresh" @click="refreshAuctions">
      <img src="~@/assets/img/refresh.png" alt="Refresh" />
    </a>
  </section>
</template>

<script>
import SearchMixin from './mixins/search.mixin'
import AuctionList from './common/AuctionList'
import { bus } from '@/lib/bus'

export default {
  components: {
    AuctionList,
  },
  mixins: [SearchMixin],
  name: 'future-auctions',
  metaInfo() {
    return {
      title: 'Future auctions',
    }
  },
  data() {
    return {
      categories: [],
      viewStyle: 'list',
      searchFilters: {
        order_by: 'started_at',
        desc: 0,
        page: 1,
        finished: false,
        with: ['media', 'translation', 'free_catalog', 'paid_catalog'],
        with_lots_count: 1,
      },
    }
  },
  created: function () {
    const self = this

    this.$api
      .searchCategories({
        all: 1,
        auctions_filter: 'future',
        order_by: 'title',
        desc: 0,
      })
      .then((response) => {
        self.categories = response.data
      })
  },
  methods: {
    refreshAuctions() {
      bus.$emit('refresh')

      window.scrollTo({
        top: 100,
        left: 100,
        behavior: 'smooth',
      })
    },
  },
}
</script>

<style scoped>
.ventes {
  position: relative;
}

.ventes__refresh {
  position: absolute;
  bottom: -50px;
  left: 50%;
  transform: translateX(-50%);
}

.ventes__refresh img {
  transition: transform 0.3s;
}

.ventes__refresh:hover img {
  transform: rotateZ(-90deg);
}
</style>

<template>
  <div>
    <article class="card">
      <div class="card__img image-box">
        <img
          :src="getThumbnail(specialist.avatar.media.link)"
          :srcset="getSrcSet(specialist.avatar.media.link)"
          :alt="specialist.avatar.media.name"
          v-if="specialist.avatar"
        />
      </div>
      <h3 class="card__title">
        {{ specialist.first_name }} {{ specialist.last_name }}
      </h3>
      <a :href="'tel:' + specialist.profile.phone" class="link">
        {{ specialist.profile.phone }} </a
      ><br />
      <a class="person__contact link" :href="'mailto:' + specialist.email">
        {{ specialist.email }}
      </a>
    </article>
  </div>
</template>

<script>
export default {
  name: 'specialist-item',
  props: ['specialist'],
}
</script>

<style scoped>
.card__img {
  margin-bottom: 2rem;
}
</style>

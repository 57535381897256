<template>
  <div>
    <div v-if="isLoaded && !isStopped" class="subscribe">
      <div class="subscribe__wrap">
        <div v-if="isSubscribed">
          <p
            class="subscribe__text"
            v-if="subscription.pivot.is_confirmed === true"
          >
            {{ $t('live.auction_confirm') }}
          </p>

          <h2 class="subscribe__subtitle">The auction will be at</h2>
          <div class="subscribe__timer">
            {{ moment(auction.started_at).format('HH:mm') }} on
            {{ moment(auction.started_at).format('DD.MM.YYYY.') }}
          </div>
        </div>

        <p
          v-if="
            hasError ||
            (subscription && subscription.pivot.is_confirmed === null)
          "
          class="subscribe__text subscribe__text--warning"
        >
          {{ $t('live.auction_confirm_warning') }}
        </p>

        <div v-if="!isSubscribed">
          <h2 v-if="auction.biddable_online" class="subscribe__subtitle">
            {{ $t('live.online_auction_must_registered_title') }}
          </h2>
          <h2 v-else class="subscribe__subtitle">
            {{ $t('live.auction_must_registered_title') }}
          </h2>
          <p class="subscribe__text">
            {{ $t('live.auction_must_registered') }}
          </p>

          <div class="subscribe-container">
            <a
              v-if="auction.biddable_online"
              class="button subscribe__button"
              @click="subscribe()"
              >{{ $t('live.subscribe_online') }}</a
            >
            <a v-else class="button subscribe__button" @click="subscribe()">{{
              $t('live.subscribe')
            }}</a>
          </div>
        </div>
      </div>

      <profile-account />
    </div>

    <div v-if="isLoaded && isStopped" class="mock-container">
      <label>{{ $t('live.auction_finished') }}</label>
      <won-lots-resume :auction="auction" />
    </div>

    <div class="loader" v-if="!isLoaded">
      <img src="/img/loader.svg" alt="" />
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import WonLotsResume from './WonLotsResume'
import ProfileAccount from '../../profile/ProfileAccount'

export default {
  name: 'auction-notification',
  components: {
    WonLotsResume,
    ProfileAccount,
  },
  props: ['auction', 'user', 'isLoaded'],
  data() {
    return {
      time: '',
      isSubscribedNow: false,
      hasError: false,
    }
  },
  created() {
    const self = this
    this.$api.getServerTime().then((response) => {
      self.time = response.data.date
    })
  },
  methods: {
    subscribe() {
      const self = this

      if (this.hasError) {
        this.$scrollTo('#account_verification', 500, {})

        return
      }

      this.$api.subscribeOnLiveStream(this.auction.id).then(() => {
        window.dataLayer.push({
          event: 'subscribeAuction',
        })

        self.isSubscribedNow = true

        self.$emit('subscribed')
      })
    },
    imagesUploaded() {
      window.scrollTo(0, 0)

      this.subscribe()
    },
  },
  computed: {
    isStopped() {
      return (
        this.auction.finished_at && moment(this.auction.finished_at).isBefore()
      )
    },
    isSubscribed() {
      if (this.isSubscribedNow) {
        return true
      }

      return this.user.auction_subscriptions.some(
        (auction) => this.auction.id === auction.id
      )
    },
    subscription() {
      if (this.isSubscribedNow) {
        return {
          pivot: {
            is_confirmed: null,
          },
        }
      }

      return this.user.auction_subscriptions.find(
        (auction) => this.auction.id === auction.id
      )
    },
  },
}
</script>

<style scoped>
.subscribe-container {
  margin-top: 30px;
}
</style>

<template>
  <div class="filters">
    <h4>{{ $t('index.read_more') }}</h4>
    <vsa-list>
      <vsa-item>
        <vsa-heading> {{ $t('product.delivery') }} </vsa-heading>

        <vsa-content>
          {{ $t('product.storage') }}<br /><br />
          {{ $t('product.storage_text') }}
          <br /><br />
          <u>{{ $t('product.delivery2') }}</u
          ><br /><br />
          <div v-html="$t('product.delivery_text')"></div>
        </vsa-content>
      </vsa-item>
      <vsa-item>
        <vsa-heading> {{ $t('product.pay') }} </vsa-heading>

        <vsa-content>
          {{ $t('product.pay_intro') }}
          <br /><br />
          <ol>
            <li>{{ $t('product.pay_text_1') }}</li>
            <li>{{ $t('product.pay_text_2') }}</li>
            <li>{{ $t('product.pay_text_3') }}</li>
            <li>{{ $t('product.pay_text_4') }}</li>
            <li v-html="$t('product.pay_text_5')"></li>
          </ol>
        </vsa-content>
      </vsa-item>
    </vsa-list>
  </div>
</template>

<script>
import VueSimpleAccordion from 'vue-simple-accordion'
import Vue from 'vue'
Vue.use(VueSimpleAccordion)

export default {
  name: 'AdditionalProductInformation',
}
</script>

<style scoped>
.filters {
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  padding: 1em 0;
}

.filters h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 29px;
  color: #000000;
  margin: 0;
  text-transform: uppercase;
}
</style>

<template>
  <div
    class="cookies"
    :class="{ 'cookies--opened': !(isAcceptCookie() || !isOpened) }"
  >
    <div class="container">
      <p class="cookies__text">
        {{ $t('cookies.message') }}
        <a @click="acceptCookie()" class="link link--underline">{{
          $t('cookies.accept')
        }}</a>
      </p>
      <a @click="acceptCookie()" class="cookies__close">
        <span class="cookies__close-icon"></span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'cookies-message',
  data() {
    return {
      isOpened: true,
    }
  },
  methods: {
    acceptCookie() {
      this.isOpened = false
      localStorage.setItem('accept-cookie', 1)
    },
    isAcceptCookie() {
      return localStorage.getItem('accept-cookie')
    },
  },
}
</script>

<style lang="scss">
.cookies .container {
  display: flex;
}

.cookies .link.link--underline {
  font-weight: 400;
  color: #fff;
  border-color: #fff;
  opacity: 0.4;

  &:hover,
  &:focus,
  &:active {
    opacity: 0.7;
  }
}
</style>

<template>
  <div class="row">
    <div class="col col-50">
      <div class="auth">
        <p class="auth__title">{{ $t('account.title') }}</p>
        <p class="auth__subtitle">
          {{ $t('account.subtitle') }}
        </p>
        <form class="form">
          <radio-buttons-box
            name="gender"
            :items="radioButtonItems.type"
            v-model="user.profile.qualification"
          >
          </radio-buttons-box>
          <div class="row" v-if="user.profile.qualification === 'professional'">
            <div class="col col-100">
              <input
                class="form__input"
                type="text"
                :placeholder="$t('account.company')"
                v-model="user.profile.company"
              />
            </div>
          </div>
          <div class="row">
            <div class="col col-100">
              <input
                class="form__input"
                type="text"
                :placeholder="$t('account.address1')"
                v-model="user.profile.address"
                :class="{ 'has-error': $v.user.profile.address.$error }"
              />
            </div>
          </div>
          <div class="row">
            <div class="col col-50">
              <input
                class="form__input"
                type="text"
                :placeholder="$t('account.postal_code')"
                v-model="user.profile.post_code"
                :class="{ 'has-error': $v.user.profile.post_code.$error }"
              />
            </div>
            <div class="col col-50">
              <input
                class="form__input"
                type="text"
                :placeholder="$t('account.city')"
                v-model="user.profile.city"
                :class="{ 'has-error': $v.user.profile.city.$error }"
              />
            </div>
          </div>
          <div class="row">
            <div class="col col-100">
              <select
                class="form__input"
                v-model="user.profile.country"
                :class="{ 'has-error': $v.user.profile.country.$error }"
              >
                <option
                  v-for="country in countries"
                  :key="country"
                  :value="country"
                >
                  {{ country }}
                </option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col col-25">
              <input
                class="form__input"
                type="text"
                :placeholder="$t('account.area_code')"
                v-model="user.profile.area_code"
                :class="{ 'has-error': $v.user.profile.area_code.$error }"
              />
            </div>
            <div class="col col-75">
              <input
                class="form__input"
                type="text"
                :placeholder="$t('account.phone')"
                v-model="user.profile.phone"
                :class="{ 'has-error': $v.user.profile.phone.$error }"
              />
            </div>
          </div>
          <div class="row">
            <div class="col col-100">
              <datepicker
                input-class="form__input"
                v-model="user.profile.birthday"
                :placeholder="$t('account.birthday')"
                format="yyyy-MM-dd"
                :language="datepickerLang[$route.params.locale]"
              />
            </div>
          </div>
          <div class="row">
            <div class="col col-100">
              <piasa-selector
                :items="locales"
                @input="selectLocale(arguments[0])"
                label-key="translation.title"
                :all-items-label="$t('account.filter_language')"
                :placeholder="$t('account.filter_language')"
              >
              </piasa-selector>
            </div>
          </div>
          <div class="form__label-wrap">
            <input
              class="form__checkbox"
              type="checkbox"
              id="license"
              v-model="license_agreement"
            />
            <label
              class="form__label"
              for="license"
              :class="{ 'has-error': $v.license_agreement.$error }"
            >
              {{ $t('account.licence_agreement') }}
            </label>
          </div>
          <div class="row">
            <div class="col col-50">
              <router-link :to="{ name: 'app.home' }" class="link auth__link">
                {{ $t('account.skip') }}
              </router-link>
            </div>
            <div class="col col-50">
              <a class="button" @click="submit">{{ $t('account.submit') }}</a>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="col col-50">
      <div class="signup-apparel">
        <img src="/img/account.png" alt="Piasa" />
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import RadioButtonsBox from '../Inputs/RadioButtonsBox'
import PiasaSelector from '@/components/Inputs/PiasaSelector'
import Datepicker from 'vuejs-datepicker'
import { en, fr } from 'vuejs-datepicker/dist/locale'
import ApiService from '@/services/api.service'
import { get, clone } from 'lodash'
import axios from 'axios'

export default {
  components: { RadioButtonsBox, PiasaSelector, Datepicker },
  name: 'account',
  mixins: [validationMixin],
  metaInfo() {
    return {
      title: 'Account',
    }
  },
  data() {
    return {
      error: '',
      user: {
        id: '',
        profile: {
          qualification: '',
          company: '',
          address: '',
          post_code: '',
          city: '',
          country: '',
          phone: '',
          birthday: '',
          language: '',
        },
      },
      locales: [
        {
          value: 'English',
          translation: {
            title: this.$t('account.filter_en'),
          },
        },
        {
          value: 'French',
          translation: {
            title: this.$t('account.filter_fr'),
          },
        },
      ],
      license_agreement: false,
      radioButtonItems: {
        type: [
          {
            value: 'particular',
            title: this.$t('account.particular'),
          },
          {
            value: 'professional',
            title: this.$t('account.professional'),
          },
        ],
      },
      datepickerLang: {
        en: en,
        fr: fr,
      },
      countries: [],
    }
  },
  validations: {
    license_agreement: { required, checked: (value) => value === true },
    user: {
      profile: {
        qualification: { required },
        address: { required },
        post_code: { required },
        city: { required },
        phone: { required },
        area_code: { required },
        country: { required },
      },
    },
  },
  methods: {
    submit() {
      this.$v.$touch()

      if (this.$v.$error && !this.license_agreement) {
        return
      }

      ApiService.updateUser(this.user)
        .then(() => {
          this.$router.push({ name: 'app.profile.account' })
        })
        .catch((error) => {
          this.error = error.response.data.error
        })
    },
    parseUser(data) {
      const user = data

      user.profile.qualification = get(user, 'profile.qualification', '')
      user.profile.company = get(user, 'profile.company', '')
      user.profile.address = get(user, 'profile.address', '')
      user.profile.post_code = get(user, 'profile.post_code', '')
      user.profile.city = get(user, 'profile.city', '')
      user.profile.country = get(user, 'profile.country', '')
      user.profile.phone = get(user, 'profile.phone', '')
      user.profile.language = get(user, 'profile.language', '')
      user.id = get(user, 'id', '')

      this.user = clone(user)
    },
    selectLocale(item) {
      this.user.profile.locale = item
    },
  },
  created: function () {
    this.$api.getProfile().then((response) => {
      this.parseUser(response.data)
    })

    axios
      .get('https://countriesnow.space/api/v0.1/countries')
      .then((response) => {
        const uniqueArray = response.data.data.filter(
          (country, index, self) =>
            index === self.findIndex((t) => t.country === country.country)
        )
        this.countries = uniqueArray.map((country) => country.country)
      })
  },
}
</script>

<style lang="scss" scoped>
.field__error {
  color: #ce081b;
}

.auth {
  max-width: 380px;
  margin-top: 10px;

  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 51px;
    color: #363636;
  }

  &__subtitle {
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 21px;
    color: #363636;
  }

  &__link {
    height: 100%;
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 46px;
    color: #8b8b8b;
  }
}

div.has-error {
  margin-top: 5px;
  top: 100%;
  left: 0;
  right: 0;
  text-align: center;
  position: absolute;
}

.form__row {
  position: relative;
  margin-bottom: 0;
}

.form > .row {
  margin-bottom: 35px;
}

@media (max-width: 767.98px) {
  .form .col {
    padding: 20px 0;
  }

  .form__row {
    margin: 0;
    padding: 20px 0;
  }
}
</style>

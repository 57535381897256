<template>
  <div>
    <section class="purchase" v-if="!isSuccess">
      <h1 class="purchase__page-title">
        {{ $t('auction_purchase.leave_purchase') }}
      </h1>
      <div class="purchase__descr">
        <div class="row">
          <div class="col col-25 offset-25">
            <div class="image-box">
              <img
                :src="
                  lot && lot.product
                    ? getThumbnail(lot.product.media[0].link)
                    : ''
                "
                :srcset="
                  lot && lot.product ? getSrcSet(lot.product.media[0].link) : ''
                "
                alt=""
              />
            </div>
          </div>
          <div class="col col-33">
            <h2 class="purchase__title">
              {{ lot && lot.product ? lot.product.translation.name : '' }}
            </h2>
            <p
              class="purchase__descr-text"
              v-html="
                lot && lot.product ? lot.product.translation.description : ''
              "
            ></p>
            <p class="purchase__descr-info" v-if="isPrice">
              {{ $t('auction_purchase.limit_amount') }}
            </p>
            <p class="purchase__descr-info" v-if="isPhone">
              {{ $t('auction_purchase.phone') }}
            </p>
            <div class="form__row">
              <input
                type="text"
                class="form__input form__input--large purchase__input"
                placeholder="00,00 €"
                v-model="price"
                :class="{ 'has-error': $v.price.$error }"
                v-if="isPrice"
              />
              <input
                type="tel"
                class="form__input form__input--large purchase__input"
                placeholder="+33"
                v-model="phone"
                :class="{ 'has-error': $v.phone.$error }"
                v-if="isPhone"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="purchase__person">
        <div class="row">
          <div class="col col-50">
            <p class="paragraph">{{ $t('auction_purchase.description.0') }}</p>
            <p class="paragraph">{{ $t('auction_purchase.description.1') }}</p>
            <p class="paragraph">
              {{ $t('auction_purchase.description.2') }} <br />{{
                $t('auction_purchase.description.3')
              }}
            </p>
          </div>
          <div class="col col-25">
            <div class="image-box">
              <img
                :src="getThumbnail(RIB.link)"
                :srcset="getSrcSet(RIB.link)"
                v-if="RIB"
                alt=""
              />
              <img
                class="close-button"
                src="/img/delete.png"
                @click="onRIBDelete()"
                v-if="RIB"
                alt=""
              />
              <input
                type="file"
                @change="onRIBChange"
                accept="image/*"
                id="RIB"
                style="display: none"
                v-if="!RIB"
              />
              <label
                class="purchase__add-image purchase__add-image--order"
                for="RIB"
                v-if="!RIB"
              >
                <span>{{ $t('auction_purchase.RIB_label') }}</span>
              </label>
            </div>
          </div>
          <div class="col col-25">
            <div class="image-box">
              <div v-if="passport">
                <img
                  :src="getThumbnail(passport.link)"
                  :srcset="getSrcSet(passport.link)"
                  alt=""
                />
                <img
                  class="close-button"
                  src="/img/delete.png"
                  @click="onPassportDelete()"
                  alt=""
                />
              </div>
              <div v-if="!passport">
                <input
                  type="file"
                  @change="onPassportChange"
                  accept="image/*"
                  style="display: none"
                  id="passport"
                />
                <label
                  class="purchase__add-image purchase__add-image--passport"
                  for="passport"
                >
                  <span
                    >{{ $t('auction_purchase.passport_label.0') }}<br />{{
                      $t('auction_purchase.passport_label.1')
                    }}</span
                  >
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="purchase__agreement">
        <div class="form__label-wrap">
          <input
            type="checkbox"
            id="license"
            class="form__checkbox"
            v-model="agree_license"
          />
          <label
            for="license"
            class="form__label"
            :class="{ 'has-error': $v.agree_license.$error }"
          >
            {{ $t('auction_purchase.terms_label.0') }}
            <router-link
              class="link--underline"
              :to="{ name: 'app.static_page', params: { slug: 'cgv' } }"
            >
              {{ $t('auction_purchase.terms_label.1') }}
            </router-link>
            {{ $t('auction_purchase.terms_label.2') }}
          </label>
        </div>
      </div>
      <button class="button purchase__button" @click="submit()">
        {{ $t('auction_purchase.submit') }}
      </button>
      <div v-if="$v.$error" class="purchase__error-message has-error">
        <div v-if="$v.price.$error">
          {{ $t('auction_purchase.error.order') }}
        </div>
        <div v-if="$v.phone.$error">
          {{ $t('auction_purchase.error.phone') }}
        </div>
        <div v-if="$v.agree_license.$error">
          {{ $t('auction_purchase.error.license') }}
        </div>
      </div>
    </section>
    <label v-if="isSuccess" class="form__success"
      >Request successfully sent.<br />
      Please wait for answer</label
    >
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, numeric, requiredIf } from 'vuelidate/lib/validators'
import phone from '../../validations/phone'

export default {
  name: 'auction-purchase',
  mixins: [validationMixin],
  validations: {
    price: {
      required: requiredIf(function () {
        return !this.isPhone
      }),
      numeric,
    },
    phone: {
      required: requiredIf(function () {
        return !this.isPrice
      }),
      phone,
    },
    agree_license: { required },
  },
  metaInfo() {
    return {
      title: 'Auction Purchase',
    }
  },
  data() {
    return {
      agree_license: false,
      RIB: null,
      passport: null,
      price: null,
      lot: {},
      phone: null,
      isSuccess: false,
      error: '',
    }
  },
  created() {
    if (this.$route.params.lot) {
      this.lot = this.$route.params.lot
    } else {
      const self = this

      this.$api.getAuction(this.$route.params.slug).then((response) => {
        self.lot = response.data.lots.find((lot) => {
          return lot.lot_number === self.$route.params.number
        })
      })
    }
  },
  methods: {
    onRIBDelete() {
      const self = this

      this.$api.deleteMedia(self.RIB.id).then(() => {
        self.RIB = null
      })
    },
    onPassportDelete() {
      const self = this

      this.$api.deleteMedia(self.passport.id).then(() => {
        self.passport = null
      })
    },
    onRIBChange(e) {
      const self = this
      const file = e.target.files[0]

      this.$api.uploadMedia(file.name, file).then((response) => {
        self.filename = file.name
        self.RIB = response.data
      })
    },
    onPassportChange(e) {
      const self = this
      const file = e.target.files[0]

      this.$api.uploadMedia(file.name, file).then((response) => {
        self.filename = file.name
        self.passport = response.data
      })
    },
    clear() {
      this.filename = null
    },
    submit() {
      this.$v.$touch()
      if (this.$v.$error) {
        return
      }

      const self = this

      this.$api
        .createBid({
          price: this.price,
          phone: this.phone,
          media: this.getMedia(),
          lot_id: this.lot.id,
          type: 'purchase_order',
        })
        .then(() => {
          self.isSuccess = true
        })
        .catch((error) => {
          this.error = error.response.data.message
        })
    },
    getMedia() {
      const media = []

      if (this.RIB !== null) {
        media.push(this.RIB.id)
      }

      if (this.passport !== null) {
        media.push(this.passport.id)
      }

      return media
    },
  },
  computed: {
    isPrice() {
      return this.$route.name === 'app.auction.purchase.price'
    },
    isPhone() {
      return this.$route.name === 'app.auction.purchase.phone'
    },
  },
}
</script>

<style scoped>
.close-button {
  width: 20px;
  height: 20px;
  top: 5px;
  left: auto;
  right: 5px;
  position: absolute;
  display: block;
  opacity: 0.5;
  cursor: pointer;
  pointer-events: auto;
  z-index: 1;
}

.close-button:hover {
  opacity: 1;
}

.close-button:active {
  opacity: 0.8;
}

label.form__success {
  text-align: center;
  display: block;
}

.purchase__error-message {
  margin-top: 2rem;
}
</style>

<template>
  <div
    @mouseenter="toggleReopenButton(true)"
    @mouseleave="toggleReopenButton(false)"
  >
    <div class="auction-status auction-status--lot" v-if="isSold">Sold</div>
    <div
      class="auction-status auction-status--lot auction-status--reopen"
      @click="$emit('reopenned')"
      v-if="reopenButtonShown"
    >
      Reopen
    </div>
    <div class="auction-status auction-status--lot" v-if="isLive">Live</div>
  </div>
</template>

<script>
export default {
  name: 'LiveStreamingLotItemStatus',
  props: {
    reopenAvailable: {
      type: Boolean,
      default: false,
    },
    isSold: {
      type: Boolean,
      default: false,
    },
    isLive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      reopenButtonShown: false,
    }
  },
  methods: {
    toggleReopenButton(value) {
      if (!this.$store.state.is_admin || !this.isSold) {
        return
      }

      this.reopenButtonShown = value
    },
  },
}
</script>

<template>
  <modal :name="'auction-modal-' + auction.id" class="fadeIn">
    <div class="modal__body">
      <button
        class="modal__close"
        @click="$modal.hide('auction-modal-' + auction.id)"
      >
        Close
      </button>
      <h2 class="modal__title">{{ $t('auctions.modal_title') }}</h2>
      <div class="container">
        <div class="row">
          <div class="col col-50">
            <div class="modal__row">
              <button class="button">
                <svg
                  v-svg
                  symbol="icon-outlook"
                  size="24 24"
                  class="button__icon"
                ></svg>
                <a class="button__text" :href="getExportCalendarUrl()"
                  >Outlook</a
                >
              </button>
            </div>
          </div>
          <div class="col col-50">
            <div class="modal__row">
              <button class="button">
                <add-to-calendar
                  :title="
                    auction.translation.title.replace(/<(?:.|\\n)*?>/gm, '')
                  "
                  :location="auction.location"
                  :start="moment(auction.started_at).toDate()"
                  :end="getFinishedAt().toDate()"
                  :details="auction.translation.description"
                  inline-template
                >
                  <div>
                    <google-calendar id="google-calendar">
                      <svg
                        v-svg
                        symbol="icon-google"
                        size="24 24"
                        class="button__icon"
                      ></svg>
                      <span class="button__text">Google Agenda</span>
                    </google-calendar>
                  </div>
                </add-to-calendar>
              </button>
            </div>
          </div>
          <div class="col col-50">
            <div class="modal__row">
              <button class="button">
                <svg
                  v-svg
                  symbol="icon-apple"
                  size="24 24"
                  class="button__icon"
                ></svg>
                <a class="button__text" :href="getExportCalendarUrl()">iCal</a>
              </button>
            </div>
          </div>
          <div class="col col-50">
            <div class="modal__row">
              <a class="button" :href="getExportCalendarUrl()">
                {{ $t('auctions.modal_other') }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { get } from 'lodash'

export default {
  name: 'auction-modal',
  props: ['auction'],
  methods: {
    getExportCalendarUrl() {
      return this.$api.getExportCalendarUrl({
        address: this.auction.location,
        from: this.auction.started_at,
        to: this.getFinishedAt().format('YYYY-MM-DD HH:mm:ss'),
        name: this.auction.translation.title.replace(/<(?:.|\\n)*?>/gm, ''),
        description: this.auction.translation.description,
        email: get(this.auction, 'employees.0.email'),
      })
    },
    getFinishedAt() {
      if (this.auction.finished_at) {
        return this.moment(this.auction.finished_at)
      } else {
        return this.moment(this.auction.started_at).add(1, 'hour')
      }
    },
  },
}
</script>

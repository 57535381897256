<template>
  <div class="product__slider slider" v-if="images.length > 0">
    <div class="image-box image-box--main" v-viewer="viewerOptions">
      <img
        :src="getResizedImageUrl(currentImage.link, 'original')"
        :alt="currentImage.name"
      />
    </div>
    <ul class="slider__thumbs">
      <li class="slider__thumbs-item" v-for="(image, index) in images">
        <a class="slider__thumbs-link" @click="select(index)">
          <div class="image-box">
            <img :src="getThumbnail(image.link)" :alt="image.name" />
          </div>
        </a>
      </li>
    </ul>
    <a
      class="slider__control slider__control--prev"
      v-if="currentImageIndex > 0"
      @click="select(currentImageIndex - 1)"
    >
    </a>
    <a
      class="slider__control slider__control--next"
      v-if="currentImageIndex < images.length - 1"
      @click="select(currentImageIndex + 1)"
    >
    </a>
    <a class="slider__zoom" @click="show">
      <svg
        v-svg
        symbol="icon-zoom"
        size="16 16"
        class="search__button-icon"
      ></svg>
    </a>
    <viewer :images="images">
      <img v-for="(src, index) in images" :src="src" :key="index" alt="" />
    </viewer>
  </div>
</template>

<script>
import 'viewerjs/dist/viewer.css'
import { component as Viewer } from 'v-viewer'

export default {
  name: 'image-slider',
  props: ['images'],
  components: {
    Viewer,
  },
  data() {
    return {
      currentImage: {
        link: '',
      },
      currentImageIndex: 0,
      viewerOptions: {
        button: false,
        navbar: false,
        title: false,
        tooltip: false,
        rotatable: false,
        scalable: false,
        fullscreen: false,
      },
    }
  },
  created: function () {
    if (this.images.length > 0) {
      this.select(0)
    }
  },
  methods: {
    select(index) {
      this.currentImage = this.images[index]
      this.currentImageIndex = index
    },
    show() {
      const viewer = this.$el.querySelector('.image-box--main').$viewer
      viewer.show()
    },
  },
  watch: {
    images: function (images) {
      if (images.length > 0) {
        this.select(0)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.slider__thumbs {
  overflow: auto;
  max-height: calc(100% - 61px);
}

.slider img {
  cursor: pointer;
  pointer-events: auto;
}

.slider__zoom {
  top: calc(100% - 25px);
}

.image-box--main {
  position: relative;
  width: 100%;
  padding-top: 100%;
  background-color: #fff;
  overflow: hidden;

  img {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }

  @media (max-width: 1024px) {
  }
}
</style>

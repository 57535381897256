import HttpService from './http.service'
import jQuery from 'jquery'
import { findIndex } from 'lodash'

export default new (class ApiService {
  login(params) {
    return HttpService.post('/login', params, 'guest')
  }

  signup(params) {
    return HttpService.post('/register', params, 'guest')
  }

  getProduct(id) {
    return HttpService.get(
      '/products/' + id,
      {
        with: [
          'lots.auction.categories.translation',
          'lots.auction.translation',
          'lots.auction.employees',
          'bids',
          'condition_media',
        ],
      },
      'guest'
    )
  }

  getLotConditionReportPdf(id) {
    return HttpService.get(
      '/lots/' + id + '/pdf_condition_report',
      {},
      'auth',
      'arraybuffer'
    )
  }

  getServerTime() {
    return HttpService.get('/time')
  }

  searchProducts(filters) {
    return HttpService.get('/products', filters, 'undemanding')
  }

  searchCategories(filters) {
    return HttpService.get('/categories', filters, 'undemanding')
  }

  deleteMedia(id) {
    return HttpService.delete('/media/' + id)
  }

  uploadMedia(filename, blob) {
    return HttpService.upload('/media', new File([blob], filename))
  }

  searchNews(filters) {
    return HttpService.get('/news', filters, 'undemanding')
  }

  getNews(id) {
    return HttpService.get(
      '/news/' + id,
      {
        with: ['allTranslations', 'auction.media', 'auction.free_catalog'],
      },
      'undemanding'
    )
  }

  getCollections(id) {
    return HttpService.get(
      '/collections/' + id,
      {
        with: [
          'allTranslations',
          'news',
          'news.translation',
          'news.media',
          'employees',
          'employees.media',
          'employees.avatar',
        ],
      },
      'undemanding'
    )
  }

  searchArtists(filters) {
    return HttpService.get('/artists', filters, 'undemanding')
  }

  getArtists(id) {
    return HttpService.get(
      '/artists/' + id,
      {
        with: [
          'allTranslations',
          'news',
          'news.translation',
          'news.media',
          'products',
          'products.auctions',
          'products.translation',
          'products.media',
        ],
      },
      'undemanding'
    )
  }

  searchPresses(filters) {
    return HttpService.get('/presses', filters, 'undemanding')
  }

  getPress(id) {
    return HttpService.get(
      '/presses/' + id,
      {
        with: ['media', 'translation.press_release'],
      },
      'undemanding'
    )
  }

  getPage(slug) {
    return HttpService.get(
      '/pages/resolve',
      {
        slug: slug,
      },
      'undemanding'
    )
  }

  searchAuctions(filters) {
    return HttpService.get('/auctions', filters, 'undemanding')
  }

  getAuction(slug) {
    const parameters = {
      with: [
        'lots.product.media',
        'lots.product.translation',
        'lots.product.lots',
        'lots.product.artist',
        'lots.product.bids.user',
        'online_bids',
        'media',
        'employees',
        'free_catalog',
        'paid_catalog',
      ],
    }

    return HttpService.get('/auctions/' + slug, parameters, 'undemanding').then(
      (response) => {
        if (!response.data.data.live_streaming) {
          response.data.data.live_streaming = {}
        }

        return response
      }
    )
  }

  startAuction(id) {
    return HttpService.post('/auctions/' + id + '/start')
  }

  stopAuction(id) {
    return HttpService.post('/auctions/' + id + '/stop')
  }

  updateAuction(id, data) {
    return HttpService.put('/auctions/' + id, data)
  }

  lotSold(auction) {
    const auctionId = auction.id
    const lotNumber = auction.data.live_streaming.current_lot
    const lotIndex = findIndex(auction.lots, { lot_number: lotNumber })

    let nextLotNumber = null
    if (lotIndex !== -1 && lotIndex + 1 !== auction.lots.length) {
      const nextLotIndex = lotIndex + 1

      const nextLot = auction.lots[nextLotIndex]
      nextLotNumber = nextLot.lot_number
    }

    return HttpService.post(
      '/auctions/' + auctionId + '/lots/' + lotNumber + '/sold',
      {
        price: auction.data.live_streaming.current_price,
        next_lot_number: nextLotNumber,
      }
    )
  }

  skipToLot(auctionId, lotNumber) {
    return HttpService.post(
      '/auctions/' + auctionId + '/lots/' + lotNumber + '/skip'
    )
  }

  setPrice(auctionId, currentLotId, price, bid) {
    const data = {
      current_lot_id: currentLotId,
      current_price: price,
    }

    if (bid) {
      data.bid = bid
    }

    return HttpService.put('/auctions/' + auctionId + '/current-price', data)
  }

  setMinimumRateIncrease(auctionId, minimumRateIncrease) {
    return HttpService.put('/auctions/' + auctionId, {
      data: {
        live_streaming: {
          minimum_rate_increase: minimumRateIncrease,
        },
      },
    })
  }

  fairWarning(auctionId) {
    return HttpService.post('/auctions/' + auctionId + '/fair-warning')
  }

  getProfile(protection = 'auth') {
    return HttpService.get('/profile', {}, protection)
  }

  updateUser(data) {
    return HttpService.put('/profile', data)
  }

  getCart() {
    return HttpService.get('/cart')
  }

  removeFromCart(productId) {
    return HttpService.delete('/cart/' + productId)
  }

  contactUs(data) {
    return HttpService.post('/contact-us', data, 'guest')
  }

  getCategory(categoryId) {
    return HttpService.get(
      '/categories/' + categoryId,
      {
        with: ['users.avatar'],
      },
      'guest'
    )
  }

  createEstimation(data) {
    return HttpService.post('/estimations', data)
  }

  searchEstimations(data) {
    return HttpService.get('/estimations', data, 'undemanding')
  }

  forgotPassword(email) {
    return HttpService.post(
      '/auth/forgot-password',
      {
        email: email,
      },
      'guest'
    )
  }

  restorePassword(token, password) {
    return HttpService.post(
      '/auth/restore-password',
      {
        token: token,
        password: password,
      },
      'guest'
    )
  }

  checkRestoreToken(token) {
    return HttpService.post(
      '/auth/token/check',
      {
        token: token,
      },
      'guest'
    )
  }

  createOrder(data) {
    return HttpService.post('/orders', data)
  }

  checkProductInCart(productId) {
    return HttpService.get('/cart/' + productId, 'auth')
  }

  createBid(data) {
    return HttpService.post('/bids', data)
  }

  setBidLimit(data) {
    return HttpService.post('/profile/limit', data)
  }

  getExportCalendarUrl(params) {
    return (
      process.env.VUE_APP_API_ENDPOINT +
      '/api/export/calendar?' +
      jQuery.param(params)
    )
  }

  createCatalogOrder(auctionId) {
    return HttpService.post('/auctions/' + auctionId + '/catalog/order')
  }

  searchBids(filters) {
    return HttpService.get('/bids', filters)
  }

  sendReport(lotId) {
    return HttpService.post('/report', {
      lot_id: lotId,
    })
  }

  subscribe(email) {
    return HttpService.post(
      '/subscribe',
      {
        email: email,
      },
      'guest'
    )
  }

  getOption(key, protection = 'auth') {
    return HttpService.get('/options/' + key, {}, protection).then(
      (response) => response.data.value
    )
  }

  getYoutubeAuthUrl() {
    return HttpService.get('/youtube/auth', {
      redirect_url: location.origin + '/youtube_redirect',
    })
  }

  setYoutubeAuthToken(code) {
    return HttpService.put('/youtube/token', {
      code: code,
    })
  }

  subscribeOnLiveStream(auctionId) {
    return HttpService.post('/auctions/' + auctionId + '/subscribe')
  }

  subscribeOnProductBidding(productId) {
    return HttpService.post('/products/' + productId + '/subscribe')
  }

  unsubscribeOnProductBidding(productId) {
    return HttpService.delete('/products/' + productId + '/unsubscribe')
  }

  getMainPageItems(collection = true) {
    return HttpService.get(
      '/main_page_items',
      { collection: collection },
      'undemanding'
    )
  }

  getWonLots(auctionId) {
    return HttpService.get('/lots', {
      all: 1,
      auction_id: auctionId,
      only_won: 1,
      with: ['product.translation'],
    })
  }

  cancelBid(bidId) {
    return HttpService.delete('/bids/' + bidId)
  }

  reopenLot(lotId) {
    return HttpService.post('/lots/' + lotId + '/reopen')
  }

  getCheckoutSession(orderId) {
    return HttpService.get('/checkout/session/' + orderId)
  }

  createMailjetContact(email) {
    return HttpService.post('/mailjet', email, 'undemanding')
  }

  addMailjetContactToList(email) {
    return HttpService.put('/mailjet', email, 'undemanding')
  }

  askHigherBid(data) {
    return HttpService.post('/askHigherBid', data)
  }
})()

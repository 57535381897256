import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import moment from 'moment/moment'

Vue.use(Vuex)

const initialState = {
  loading: 0,
  title: '',
  fullscreen: false,
  is_admin: 0,
  is_vip: 0,
  token: null,
  last_logged_at: null,
  redirect_back_url: null,
  hide_header: false,
  user: {},
}

export default new Vuex.Store({
  state: initialState,
  mutations: {
    saveUsersFlags(state, user) {
      if (user.role_id === 1) {
        state.is_admin = true
      }

      if (user.profile.vip) {
        state.is_vip = true
      }

      state.user = user
    },
    token(state, token) {
      if (token.indexOf('Bearer') === -1) {
        token = 'Bearer ' + token
      }

      state.token = token
      state.last_logged_at = moment.now().valueOf()
    },
    logout(state) {
      for (const f in state) {
        Vue.set(state, f, initialState[f])
      }
      state.user = {}
      state.token = undefined
    },
    setRedirectBackUrl(state, url) {
      state.redirect_back_url = url
    },
    toggleHeader(state) {
      state.hide_header = !state.hide_header
    },
    showHeader(state) {
      state.hide_header = false
    },
    updateProductSubscriptions(state, product) {
      const index = state.user.product_subscriptions.findIndex(
        (item) => item.id === product.id
      )
      if (index === -1) {
        state.user.product_subscriptions.push(product)
      } else {
        state.user.product_subscriptions.splice(index, 1)
      }
    },
  },
  plugins: [createPersistedState()],
})

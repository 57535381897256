<template>
  <div>
    <div class="image-box">
      <div v-if="isEmpty(value)">
        <input
          type="file"
          :id="uniqueId"
          style="display: none"
          @change="onFileChange"
          :accept="accept"
        />
        <label :class="buttonClass" :for="uniqueId">
          <span>{{ buttonText }}</span>
        </label>
      </div>
      <div v-if="!isEmpty(value)" class="image-container">
        <img
          v-if="fileType === 'img' && value"
          :src="getThumbnail(value.link, 'origin')"
          class="image-preview"
          alt=""
        />
        <div class="image-box__pdf">
          <pdf v-if="fileType === 'pdf' && value" :src="pdfLink"></pdf>
        </div>
        <img
          class="close-button"
          src="/img/delete.png"
          @click="clear()"
          alt=""
        />
      </div>
    </div>
    <label class="has-error" v-if="fileSizeError">{{
      $t('live.image_upload_max_exceeded')
    }}</label>
    <label class="has-error" v-if="fileTypeError">{{
      $t('live.image_upload_wrong_ext')
    }}</label>
  </div>
</template>

<script>
import { get, uniqueId } from 'lodash'
import pdf from 'vue-pdf'

export default {
  name: 'ImageUpload',
  components: { pdf },
  props: ['value', 'buttonClass', 'buttonText', 'accept'],
  data() {
    return {
      uniqueId: uniqueId(),
      fileSizeError: false,
      fileTypeError: false,
    }
  },
  methods: {
    onFileChange(e) {
      const self = this
      this.fileSizeError = false
      this.fileTypeError = false
      const file = e.target.files[0]
      if (file.size > 3145278) {
        this.fileSizeError = true
      } else if (
        !['image/jpeg', 'image/jpg', 'image/png', 'application/pdf'].includes(
          file.type
        )
      ) {
        this.fileTypeError = true
      } else {
        this.$api.uploadMedia(file.name, file).then((response) => {
          self.filename = file.name
          self.$emit('input', response.data)
        })
      }
    },
    clear() {
      this.$emit('input', null)
      this.$emit('clear', this.value.id)
    },
    isEmpty(value) {
      if (value) {
        return Object.keys(value).length === 0
      }
      return !value
    },
  },
  computed: {
    pdfLink() {
      const backendUrl = this.$http.getBackendUrl()

      return backendUrl + '/api/media/download/' + this.value.id
    },
    fileType() {
      const link = get(this.value, ['link'], '')

      return link.includes('.pdf') ? 'pdf' : 'img'
    },
  },
}
</script>

<style scoped>
.close-button {
  width: 30px;
  height: 30px;
  top: 5px;
  right: 5px;
  left: inherit;
  position: absolute;
  opacity: 0.5;
  cursor: pointer;
  z-index: 1;
}

.close-button:hover {
  opacity: 1;
}

.close-button:active {
  opacity: 0.8;
}

.image-preview {
  object-fit: cover;
  pointer-events: none;
}

.image-box .image-box__pdf {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  -o-object-fit: cover;
  object-fit: cover;
  pointer-events: none;
}
</style>

<template>
  <orders-wrapper>
    <article
      class="vente"
      v-for="(product, index) in cart"
      v-if="product.lots.length"
    >
      <div class="row">
        <div class="col col-25">
          <div class="image-box">
            <img
              :src="getThumbnail(product.media[0].link)"
              :alt="product.name"
            />
          </div>
        </div>
        <div class="col col-75">
          <div class="vente__wrap">
            <table class="purchases__info">
              <tr>
                <td>{{ $t('cart.auction') }}</td>
                <td
                  class="description"
                  v-html="product.lots[0].auction.translation.title"
                />
              </tr>
              <tr>
                <td>Date</td>
                <td>{{ getDate(product) }}</td>
              </tr>
              <tr>
                <td>Lot</td>
                <td>
                  {{ product.lots[0].lot_number }}
                  {{ product.translation.name }}
                </td>
              </tr>
              <tr v-if="product.lots[0].auction.categories[0]">
                <td>{{ $t('cart.category') }}</td>
                <td>
                  {{ product.lots[0].auction.categories[0].translation.title }}
                </td>
              </tr>
              <tr>
                <td>{{ $t('cart.price') }}</td>
                <td>{{ product.price }} €</td>
              </tr>
            </table>
            <div class="vente__footer">
              <div class="row">
                <div class="col col-33">
                  <router-link
                    :to="{
                      name: 'app.orders.new',
                      query: { product_id: product.id },
                    }"
                    class="button"
                  >
                    {{ $t('cart.purchase') }}
                  </router-link>
                </div>
                <div class="col col-66">
                  <ul class="vente__info-list">
                    <li class="vente__info-item">
                      <a
                        class="link link--underline"
                        @click="remove(index, product)"
                      >
                        {{ $t('cart.remove') }}
                      </a>
                    </li>
                    <li class="vente__info-item">
                      <a class="link link--underline">
                        {{ $t('cart.bill') }}
                      </a>
                    </li>
                    <li class="vente__info-item">
                      <router-link
                        class="link link--underline"
                        :to="getOrderLink(product)"
                      >
                        {{ $t('cart.view') }}
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
    <label v-if="cart.length === 0" class="empty-cart">
      {{ $t('cart.empty') }}
    </label>
  </orders-wrapper>
</template>

<script>
import OrdersWrapper from './OrdersWrapper'

export default {
  name: 'cart',
  components: { OrdersWrapper },
  metaInfo() {
    return {
      title: 'Cart',
    }
  },
  data() {
    return {
      cart: [],
    }
  },
  created: function () {
    const self = this

    this.$api.getCart().then((response) => {
      self.cart = response.data
    })
  },
  methods: {
    getDate(product) {
      return this.moment(product.lots[0].auction.started_at).format('LLLL')
    },
    remove(index, product) {
      const self = this

      this.$api.removeFromCart(product.id).then(() => {
        self.cart.splice(index, 1)
      })
    },
    getOrderLink(product) {
      return {
        name: 'app.lots.details',
        params: {
          slug: product.lots[0].auction.slug,
          number: product.lots[0].lot_number,
        },
      }
    },
  },
}
</script>

<style scoped>
.empty-cart {
  color: #666;
  font-size: 14px;
  text-align: center;
  padding: 10px 0;
  width: 100%;
  position: absolute;
}

.description {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 500px;
  position: absolute;
}
</style>

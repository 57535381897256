<template>
  <div class="mock-container">
    <label v-if="!error">Saving your auth token</label>
    <label v-if="error">{{ error }}</label>
    <img src="/img/loader.svg" class="loader" alt="" />
  </div>
</template>

<script>
export default {
  name: 'YoutubeRedirectPage',
  data() {
    return {
      error: null,
    }
  },
  created() {
    const self = this

    if (this.$route.query.code) {
      this.$api.setYoutubeAuthToken(this.$route.query.code).then(() => {
        const url = self.$store.state.redirect_back_url

        self.$store.commit('setRedirectBackUrl', null)

        self.$router.push(url)
      })
    } else {
      this.error = "Mandatory parameter 'code' is missing."
    }
  },
}
</script>

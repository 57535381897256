<template>
  <div>
    <p
      :class="textClass"
      v-if="text"
      v-html="text.replace(/(?:\r\n|\r|\n)/g, '<br />')"
      v-line-clamp="truncateTextLines"
    ></p>
    <a
      @click="truncateTextLines = 0"
      :class="showMoreClass"
      v-if="truncateTextLines"
    >
      {{ showMore }}
    </a>
  </div>
</template>

<script>
export default {
  name: 'truncatable',
  props: {
    truncateFromLines: {
      type: Number,
      default: 10,
    },
    text: {
      type: String,
      default: '',
    },
    textClass: {
      type: String,
      default: 'truncatable-text',
    },
    showMore: {
      type: String,
      default: 'More info',
    },
    showMoreClass: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      truncateTextLines: this.truncateFromLines,
    }
  },
  methods: {
    showSeeMore() {
      const el = document.getElementsByClassName(this.textClass)[0]
      if (el) {
        const contentLineHeight = window
          .getComputedStyle(el)
          .getPropertyValue('line-height')
          .replace('px', '')
        const contentMaxHeight =
          contentLineHeight * (this.truncateTextLines - 1)

        return el.offsetHeight > contentMaxHeight
      }
    },
  },
  created: function () {
    this.$nextTick(function () {
      if (!this.showSeeMore()) {
        this.truncateTextLines = 0
      }
    })
  },
}
</script>

<template>
  <section class="blog">
    <header class="blog__header">
      <h1 class="blog__title">{{ $t('press.title') }}</h1>
    </header>
    <div class="blog__list">
      <div class="row">
        <div
          class="col"
          v-for="(press, index) in pressesList"
          :class="{
            'col-50': index < 2,
            'col-33': index >= 2,
          }"
        >
          <press-list-item :press="press" />
        </div>
      </div>
      <infinite-loading
        ref="loader"
        @infinite="loadMore(arguments[0])"
        spinner="waveDots"
        :distance="1000"
        v-if="hasMore"
      >
        <span slot="no-more"></span>
      </infinite-loading>
    </div>
  </section>
</template>

<script>
import PressListItem from './PressListItem'
import InfiniteLoading from 'vue-infinite-loading'
import { clone, isEmpty } from 'lodash'

export default {
  components: {
    PressListItem,
    InfiniteLoading,
  },
  name: 'presses-list',
  metaInfo() {
    return {
      title: 'Presses',
    }
  },
  data() {
    return {
      pressesList: [],
      searchFilters: {
        page: 1,
        per_page: 14,
        with: ['media'],
        order_by: 'publication_date',
        desc: 1,
      },
      hasMore: true,
    }
  },
  methods: {
    loadMore($state) {
      const self = this

      this.$api.searchPresses(clone(this.searchFilters)).then((response) => {
        self.addItems(response.data.data, $state)
      })

      this.searchFilters.page++
    },
    addItems(items, $state) {
      this.pressesList = this.pressesList.concat(items)

      if (isEmpty(items)) {
        $state.complete()
      } else {
        $state.loaded()
      }
    },
  },
}
</script>

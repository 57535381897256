<template>
  <div class="auction">
    <auction-notification
      :auction="auction"
      :user="user"
      :is-loaded="isLoaded"
      @subscribed="loadAuction()"
    />
  </div>
</template>

<script>
import { isEmpty } from 'lodash'
import AuctionNotification from './common/AuctionNotification'

export default {
  components: {
    AuctionNotification,
  },
  name: 'client-auction-bidding',
  metaInfo() {
    return {
      title: this.auction.translation.title.replace(/<[^>]*>?/gm, ''),
    }
  },
  data() {
    return {
      isLoaded: false,
      user: {
        id: null,
        auction_subscriptions: [],
        rib: {
          media: null,
        },
        passport: {
          media: null,
        },
      },
      auction: {
        translation: {
          title: '',
        },
        data: {
          live_streaming: {
            on_air: false,
            youtube_stream_id: null,
            current_lot: null,
            minimum_rate_increase: null,
            current_price: null,
          },
        },
      },
    }
  },
  created() {
    this.loadUser()
    this.loadAuction()
  },
  methods: {
    loadAuction() {
      const self = this

      self.isBidButtonBlocked = true
      return this.$api.getAuction(this.$route.params.slug).then((response) => {
        self.auction = response.data

        if (isEmpty(self.auction)) {
          self.auction = {
            lots: [],
          }
        }
        self.isLoaded = true
      })
    },
    loadUser() {
      const self = this

      return this.$api.getProfile().then((response) => {
        self.user = response.data
        self.user.rib = self.user.rib === null ? { media: null } : self.user.rib
        self.user.passport =
          self.user.passport === null ? { media: null } : self.user.passport
      })
    },
  },
}
</script>

<template>
  <modal
    :name="'condition-report-modal'"
    :width="900"
    height="auto"
    :adaptive="true"
    :scrollable="true"
    class="fadeIn"
  >
    <div class="condition-report">
      <button
        class="modal__close"
        @click="$modal.hide('condition-report-modal')"
      ></button>
      <div class="condition-report__header">
        {{ $t('products.condition_report_modal.title') }}
      </div>
      <div class="container condition-report__body">
        <div class="row">
          <div class="col col-50">
            <image-slider :images="product.condition_media" />
          </div>
          <div class="col col-50">
            <product-main-info
              :auction="auction"
              :product="product"
              :isSmallSize="true"
            ></product-main-info>
            <div class="condition-report__condition">
              {{ product.translation.condition }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-80"></div>
          <div class="col-20">
            <a
              class="button purchase__button"
              :href="`${apiEndpoint}/lots/${product.lots[0].id}/pdf_condition_report`"
            >
              {{ $t('products.condition_report_modal.download') }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import ProductMainInfo from './ProductMainInfo'
import ImageSlider from './../common/ImageSlider'
import store from './../../../services/store.service'
import { isEmpty } from 'lodash'

export default {
  name: 'condition-report-modal',
  components: {
    ProductMainInfo,
    ImageSlider,
  },
  props: {
    auction: {
      required: true,
      type: Object,
    },
    product: {
      required: true,
      type: Object,
    },
  },
  computed: {
    apiEndpoint() {
      return process.env.VUE_APP_API_ENDPOINT
    },
  },
  created: function () {
    if (isEmpty(store.state.token)) {
      this.$router.push({
        name: 'app.sign_in',
      })
    }
  },
  methods: {
    printReport() {
      this.$api
        .getLotConditionReportPdf(this.product.lots[0].id)
        .then((response) => {
          this.downloadFile(response, 'condition_report')
        })
    },
    downloadFile(response, filename) {
      // It is necessary to create a new blob object with mime-type explicitly set
      // otherwise only Chrome works like it should
      const newBlob = new Blob([response.data], { type: 'application/pdf' })

      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob)
        return
      }

      // For other browsers:
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(newBlob)
      const link = document.createElement('a')
      link.href = data
      link.download = filename + '.pdf'
      link.click()
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data)
      }, 100)
    },
  },
}
</script>

<style scoped>
.condition-report {
  padding: 20px;
}

.condition-report__header {
  font-size: 18px;
  font-weight: bold;

  padding-bottom: 20px;

  border-bottom: #8b8b8b 1px solid;
}

.condition-report__body {
  padding-top: 20px;
}

.condition-report__body .row:last-child {
  margin-top: 30px;
}

.condition-report__condition {
  border-top: #8b8b8b 1px solid;

  padding-top: 20px;

  font-size: 16px;
}
</style>

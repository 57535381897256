<template>
  <div>
    <div class="row current-lot-container" v-if="lotIndex !== -1">
      <div class="col col-33">
        <article class="current-lot-image-wrap">
          <router-link
            :to="{
              name: 'app.lots.details',
              params: {
                slug: auction.slug,
                number: auction.lots[lotIndex].lot_number,
              },
            }"
            class="image-box__link"
          >
            <div class="image-box">
              <img
                :src="
                  getThumbnail(auction.lots[lotIndex].product.media[0].link)
                "
                alt=""
              />
            </div>
          </router-link>
        </article>
      </div>
      <div class="col col-66">
        <div class="lots__content">
          <span class="lots__number">{{
            auction.lots[lotIndex].lot_number
          }}</span>
          <div class="lots__descr">
            <h3 class="lots__title">
              {{ auction.lots[lotIndex].product.translation.name }}
            </h3>
            <span class="lots__cost" v-if="estimationNotNull()">
              Estimation : {{ auction.lots[lotIndex].product.min_estimation }}
              <span v-if="auction.lots[lotIndex].product.max_estimation">
                / {{ auction.lots[lotIndex].product.max_estimation }}
              </span>
              €
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="mock-container" v-if="lotIndex === -1">
      {{ $t('live.lot_not_selected') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'current-lot',
  props: ['auction', 'currentLot'],
  methods: {
    estimationNotNull() {
      const lotIndex = this.lotIndex

      return (
        !this.auction.lots[lotIndex].product.buyable_online &&
        (this.auction.lots[lotIndex].product.min_estimation ||
          this.auction.lots[lotIndex].product.max_estimation)
      )
    },
  },
  computed: {
    lotIndex() {
      const lotNumber = this.auction.data.live_streaming.current_lot

      return this.auction.lots.findIndex((lot) => {
        return lot.lot_number === lotNumber
      })
    },
  },
}
</script>

<style lang="scss">
.mock-container {
  height: 300px;
}

.current-lot-image-wrap {
  margin-right: -25px;

  @media (max-width: 767.98px) {
    margin-right: 0;
  }
}
</style>

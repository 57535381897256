<template>
  <section class="account">
    <h1 class="visually-hidden">{{ $t('profile.account') }}</h1>
    <div class="tabs">
      <ul class="tabs__header">
        <li class="tabs__header-item">
          <router-link class="link" :to="{ name: 'app.profile.account' }">
            {{ $t('profile.account') }}
          </router-link>
        </li>
      </ul>
      <div class="tabs__content">
        <router-view :key="$route.fullPath" />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'profile',
}
</script>

<style scoped>
.router-link-active {
  color: black;
  cursor: auto;
}
</style>

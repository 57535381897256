<template>
  <div>
    <header class="ventes__header">
      <div class="ventes__control">
        <div class="row">
          <div class="col col-25">
            <piasa-selector
              :items="categories"
              @input="selectCategory(arguments[0])"
              label-key="translation.title"
              :all-items-label="$t('auctions.default_filter')"
              :placeholder="$t('auctions.categories')"
            >
            </piasa-selector>
          </div>
          <div class="col col-25">
            <piasa-selector
              :items="types"
              @input="selectType(arguments[0])"
              label-key="translation.title"
              :all-items-label="$t('auctions.types_default_filter')"
              :placeholder="$t('auctions.types')"
            >
            </piasa-selector>
          </div>
        </div>
      </div>
    </header>
    <div>
      <div v-for="(auction, index) in items">
        <div class="ventes__monthly" v-if="needToAddMonth(index)">
          {{ moment(auction.started_at).format('MMMM YYYY') }}
        </div>
        <auction-item :auction="auction" />
      </div>
      <infinite-loading
        ref="loader"
        @infinite="loadMore"
        spinner="waveDots"
        :distance="0"
        v-if="hasMore"
      >
        <span slot="no-more"></span>
        <span slot="no-results">
          {{ $t('auctions.not_found') }}
        </span>
      </infinite-loading>
    </div>
  </div>
</template>

<script>
import AuctionItem from './AuctionItem'
import InfiniteLoading from 'vue-infinite-loading'
import PiasaSelector from '../../Inputs/PiasaSelector'
import { isEmpty, get } from 'lodash'
import { bus } from '@/lib/bus'

export default {
  components: {
    PiasaSelector,
    AuctionItem,
    InfiniteLoading,
  },
  props: ['categories'],
  name: 'auction-list',
  data() {
    return {
      items: [],
      hasMore: true,
      types: [],
    }
  },
  mounted() {
    bus.$on('refresh', () => this.selectCategory())
  },
  methods: {
    addItems(items, $state) {
      this.items = this.items.concat(items)

      if (isEmpty(items)) {
        $state.complete()
      } else {
        $state.loaded()
      }

      this.addTypes(this.items)
    },
    addTypes(auctions) {
      const arr = auctions.map((auction) => {
        return {
          title: auction.type,
          translation: {
            title: auction.type,
          },
        }
      })

      this.types = arr.filter(
        (v, i, a) => a.findIndex((t) => t.title === v.title) === i
      )
    },
    clear() {
      this.items = []
      this.hasMore = true

      this.$refs.loader.$emit('$InfiniteLoading:reset')
    },
    selectCategory(category) {
      const selectedCategory = category === undefined ? null : category.id

      this.$emit('search', { category_id: selectedCategory })
    },
    selectType(type) {
      const selectedType = type === undefined ? null : type.translation.title

      this.$emit('search', { type: selectedType })
    },
    needToAddMonth(index) {
      if (isEmpty(this.items)) {
        return false
      }

      const previousAuction = get(this.items, index - 1)

      if (isEmpty(previousAuction)) {
        return true
      }

      const previousAuctionMonth = this.moment(
        previousAuction.started_at
      ).format('M')
      const currentAuctionMonth = this.moment(
        this.items[index].started_at
      ).format('M')

      return previousAuctionMonth !== currentAuctionMonth
    },
    loadMore($state) {
      this.$emit('load-more', $state)
    },
  },
}
</script>

<template>
  <section class="sign-in">
    <h1>{{ $t('subscribe.title') }}</h1>
    <p>{{ $t('subscribe.description') }}</p>
    <form class="form" v-if="!isSuccessful">
      <div class="form__row">
        <input
          class="form__input"
          v-model="email"
          type="email"
          :class="{ 'has-error': $v.email.$error }"
          :placeholder="$t('auth.email')"
        />
      </div>
      <div class="form__row" @click="submit()">
        <a class="button">{{ $t('subscribe.submit') }}</a>
      </div>
    </form>
    <label v-if="isSuccessful" class="form__success">
      {{ $t('subscribe.success') }}
    </label>
  </section>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'

export default {
  name: 'subscribe',
  mixins: [validationMixin],
  metaInfo() {
    return {
      title: 'Newsletter',
    }
  },
  data() {
    return {
      email: '',
      isSuccessful: false,
    }
  },
  validations: {
    email: { required, email },
  },
  methods: {
    submit() {
      this.$v.$touch()

      if (this.$v.$error) {
        return
      }

      const self = this

      this.$api.subscribe(this.email).then(() => {
        self.isSuccessful = true
      })
    },
  },
}
</script>

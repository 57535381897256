<template>
  <div class="alphabetical-index__wrap">
    <div class="row">
      <div class="col col-33" v-for="column in columns">
        <ul class="alphabetical-index" v-for="block in column">
          <li class="letter">{{ block.letter }}</li>
          <li v-for="category in block.categories">
            <router-link
              :to="{
                name: 'app.categories.details',
                params: { id: category.id },
              }"
              class="link"
            >
              {{ category.translation.title }}
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'categories-list',
  data() {
    return {
      columns: [],
    }
  },
  metaInfo() {
    return {
      title: 'Categories',
    }
  },
  created: function () {
    const self = this

    this.$api
      .searchCategories({
        all: 1,
        with: 'translation',
        order_by: 'title',
        desc: 0,
      })
      .then((response) => {
        self.prepareCategories(response.data)
      })
  },
  methods: {
    prepareCategories(categories) {
      this.sortCategories(categories)

      const sortedCategories = []
      let currentCategoriesBlock = {}

      categories.forEach(function (record) {
        const firstLetter = record.translation.title[0]

        if (currentCategoriesBlock.letter !== firstLetter) {
          sortedCategories.push({
            letter: firstLetter,
            categories: [],
          })

          currentCategoriesBlock = sortedCategories[sortedCategories.length - 1]
        }

        currentCategoriesBlock.categories.push(record)
      })

      this.columns.push(sortedCategories.splice(0, sortedCategories.length / 3))
      this.columns.push(sortedCategories.splice(0, sortedCategories.length / 2))
      this.columns.push(sortedCategories)
    },
    sortCategories(categories) {
      categories.sort(function (category1, category2) {
        if (category1.translation.title < category2.translation.title) {
          return -1
        }

        if (category1.translation.title > category2.translation.title) {
          return 1
        }

        return 0
      })
    },
  },
}
</script>

<style scoped>
.letter {
  text-transform: capitalize;
}
</style>

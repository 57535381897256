<template>
  <div class="product__partager">
    <span class="product__partager-text">Partager</span>
    <div>
      <ul class="product__social-list social__list">
        <li class="social__item">
          <ShareNetwork
            network="email"
            :url="getCurrentUrl()"
            :title="title"
            :description="description"
          >
            <i class="fa fa-envelope"></i>
            <span class="visually-hidden">Mail to</span>
          </ShareNetwork>
        </li>
        <li class="social__item">
          <ShareNetwork
            network="facebook"
            :url="getCurrentUrl()"
            :title="title"
            :description="description"
            :quote="title"
          >
            <i class="fa fa-facebook"></i>
            <span class="visually-hidden">Facebook</span>
          </ShareNetwork>
        </li>
        <li class="social__item">
          <ShareNetwork
            network="pinterest"
            :url="getCurrentUrl()"
            :title="title"
          >
            <i class="fa fa-pinterest"></i>
            <span class="visually-hidden">Pinterest</span>
          </ShareNetwork>
        </li>
        <li class="social__item">
          <ShareNetwork network="twitter" :url="getCurrentUrl()" :title="title">
            <i class="fa fa-twitter"></i>
            <span class="visually-hidden">Twitter</span>
          </ShareNetwork>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'portager',
  props: ['title', 'description'],
  methods: {
    getCurrentUrl() {
      return location.href
    },
  },
}
</script>

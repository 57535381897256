<template>
  <profile-item-block
    @save="save()"
    :title="$t('profile.identifiers')"
    @cancel="$emit('cancel')"
    ref="profileBox"
  >
    <div slot="preview">
      <li class="account__item">{{ user.login }}</li>
      <li class="account__item">********</li>
    </div>
    <li slot="edit" class="account__edit">
      <div class="account__item">
        <input
          class="form__input"
          v-model="user.login"
          :placeholder="$t('auth.login')"
          @input="$v.user.login.$touch()"
          :class="{ 'has-error': $v.user.login.$error }"
        />
      </div>
      <div class="account__item">
        <input
          class="form__input"
          type="password"
          v-model="user.password"
          :placeholder="$t('auth.password')"
          @input="$v.user.password.$touch()"
          :class="{ 'has-error': $v.user.password.$error }"
        />
      </div>
      <div class="account__item">
        <input
          class="form__input"
          type="password"
          v-model="user.confirm"
          :placeholder="$t('auth.confirm_password')"
          @input="$v.user.confirm.$touch()"
          :class="{ 'has-error': $v.user.confirm.$error }"
        />
      </div>
      <label v-if="error" class="has-error">{{ error }}</label>
    </li>
  </profile-item-block>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, email, sameAs } from 'vuelidate/lib/validators'
import ProfileItemBlock from './ProfileItemBlock'
import { profileMixin } from '@/mixins/profile.mixin'

export default {
  components: { ProfileItemBlock },
  name: 'identifiers',
  props: ['user'],
  mixins: [validationMixin, profileMixin],
  validations: {
    user: {
      login: { required, email },
      password: { required },
      confirm: { sameAsPassword: sameAs('password') },
    },
  },
  data() {
    return {
      error: '',
    }
  },
}
</script>

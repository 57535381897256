<template>
  <article class="vente">
    <div class="row">
      <div class="col col-25">
        <div class="image-box">
          <img
            :src="getThumbnail(product.media[0].link)"
            :alt="product.translation.title"
          />
        </div>
      </div>
      <div class="col col-75">
        <div class="vente__wrap">
          <table class="purchases__info">
            <tr>
              <td>Date</td>
              <td>{{ moment(product.auction_date).format('LLLL') }}</td>
            </tr>
            <tr>
              <td>Lot</td>
              <td>
                {{ product.lots[0].lot_number }} {{ product.translation.name }}
              </td>
            </tr>
            <tr>
              <td>{{ $t('products.category') }}</td>
              <td>{{ getCategory() }}</td>
            </tr>
            <tr class="result" v-if="isInPast()">
              <td>{{ $t('products.result') }}</td>
              <td>{{ product.price }} €</td>
            </tr>
            <tr
              class="result"
              v-if="!isInPast() && product.buyable_online && product.price"
            >
              <td>{{ $t('products.price') }}</td>
              <td>{{ product.price }} €</td>
            </tr>
            <tr
              class="estimation"
              v-if="
                !product.buyable_online &&
                (product.min_estimation || product.max_estimation)
              "
            >
              <td>Estimation</td>
              <td>
                {{ product.min_estimation
                }}<span v-if="product.max_estimation">
                  / {{ product.max_estimation }}</span
                >
                €
              </td>
            </tr>
          </table>
          <div class="vente__footer">
            <div class="row">
              <div class="col">
                <ul class="vente__info-list">
                  <li class="vente__info-item">
                    <router-link
                      :to="getRouterLink()"
                      class="link link--underline"
                    >
                      {{ $t('cart.view') }}
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import { isNull, get } from 'lodash'

export default {
  name: 'product-item',
  props: ['product'],
  methods: {
    getRouterLink() {
      const to = {
        name: 'app.products.details',
        params: {},
      }

      if (isNull(this.product.slug)) {
        to.params.slug = this.product.id
      } else {
        to.params.slug = this.product.slug
      }

      return to
    },
    getCategory() {
      return get(
        this.product,
        'lots.0.auction.categories.0.translation.title',
        'Not Specified'
      )
    },
    isInPast() {
      return (
        this.moment(this.product.auction_date).isBefore() && this.product.price
      )
    },
  },
}
</script>

<style scoped>
.result {
  color: #a41d23;
}

.estimation {
  color: #000000;
}
</style>

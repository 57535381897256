<template>
  <div>
    <auction-list
      ref="auctionList"
      @load-more="loadMore(arguments[0])"
      @search="search(arguments[0])"
      :categories="categories"
    >
    </auction-list>
  </div>
</template>

<script>
import SearchMixin from './mixins/search.mixin'
import AuctionList from './common/AuctionList'

export default {
  name: 'past-auctions-list',
  mixins: [SearchMixin],
  components: {
    AuctionList,
  },
  metaInfo() {
    return {
      title: 'Past auctions - ' + this.$route.params.year,
    }
  },
  data() {
    return {
      categories: [],
      viewStyle: 'list',
      searchFilters: {
        per_page: 20,
        order_by: 'started_at',
        desc: 1,
        finished: true,
        year: this.$route.params.year,
        page: 1,
        with: [
          'media',
          'translation',
          'employees',
          'free_catalog',
          'paid_catalog',
        ],
        with_lots_count: 1,
      },
    }
  },
  created: function () {
    const self = this
    const filters = {
      all: 1,
      auctions_filter: 'past',
      year: this.$route.params.year,
      order_by: 'title',
      desc: 0,
    }

    this.$api.searchCategories(filters).then((response) => {
      self.categories = response.data
    })
  },
}
</script>

<template>
  <div class="search">
    <input
      type="text"
      class="input search__input"
      v-model="query"
      :placeholder="$t('navigation.search')"
      :class="{ 'search__input--opened': isOpened }"
      @change="search()"
    />
    <button
      class="search__button"
      :class="{ 'search__button--close': isOpened }"
      @click="isOpened = !isOpened"
    >
      <svg
        v-svg
        symbol="icon-search"
        size="15 15"
        class="search__button-icon"
      ></svg>
      <span class="search__close-icon"></span>
      <span class="visually-hidden">Search</span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'search-bar',
  data() {
    return {
      isOpened: false,
      query: '',
    }
  },
  methods: {
    search() {
      this.$router.push({
        name: 'app.products.search',
        query: {
          query: this.query,
        },
      })
    },
  },
}
</script>
